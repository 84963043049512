<template>
  <div class="navigation-block">
    <div class="navigation-block-content">
      <div class="nav-filters">
        <button
          v-ripple
          type="button"
          @click="restoreAllFilter"
          class="link-icon"
        >
          <img src="@/assets/images/reset.svg" alt="" />{{
            $t("navigation.navigation__reset")
          }}
        </button>
        <div
          class="filials"
          @click="dropdown"
          :class="{ active: dropdownActive }"
        >
          <span v-if="filterData.current_branch == 'all'" class="link-icon"
            ><img src="@/assets/images/filial.svg" alt="" />{{
              $t("navigation.navigation__filials")
            }}
            ⬇</span
          >
          <span v-else class="link-icon"
            ><img src="@/assets/images/filial.svg" alt="" />{{
              getBranchName
            }}</span
          >
          <div class="dropdown" :class="{ active: dropdownActive }">
            <ul class="dropdown-name">
              <li
                v-for="branch in branch_list"
                :key="branch"
                :branch_id="branch.id"
                :class="{ active: filterData.current_branch == branch.id }"
              >
                {{ branch.name }}
              </li>
              <li
                branch_id="all"
                :class="{ active: filterData.current_branch == 'all' }"
              >
                {{ $t("navigation.all_filials") }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ul class="languages-list">
        <li
          class="link-icon"
          :class="{ active: filterData.current_language == 'ru' }"
          @click="languageBtn('ru')"
          v-ripple
        >
          <img src="@/assets/images/lang.svg" alt="" />
          RUS
        </li>
        <li
          class="link-icon"
          :class="{ active: filterData.current_language == 'uz' }"
          @click="languageBtn('uz')"
          v-ripple
        >
          <img src="@/assets/images/lang.svg" alt="" />
          UZB
        </li>
        <li
          class="link-icon"
          :class="{ active: filterData.current_language == 'all' }"
          @click="languageBtn('all')"
          v-ripple
        >
          <img src="@/assets/images/lang.svg" alt="" />
          {{ $t("navigation.languages") }}
        </li>
        <!-- <button v-ripple class="link-icon" @click="showOrHideNearestDate" :class="{ active: nearestDateView }">
          <img src="@/assets/images/calendar.svg" alt="" />Ближайшие запуски
        </button> -->
        <button
          v-ripple
          class="link-icon"
          :class="{ new: getVersionApp() == 2.7 }"
          @click="openModal"
        >
          <img src="@/assets/images/event.svg" alt="" />{{
            $t("navigation.closest_events")
          }}
        </button>
        <Teleport to="#dialog">
          <Transition name="slide-fade">
            <Dialog
              v-if="todeyModal"
              :full="true"
              :width="2000"
              @close="todeyModal = false"
            >
              <template v-slot:title>
                <div class="actions-title">
                  {{ $t("navigation.closes_events_for", { date: title }) }}

                  <ul class="actions-title_link">
                    <li
                      :class="{ active: active == date.action + 1 }"
                      v-for="date in dateActions"
                      :key="date"
                      v-ripple
                      @click="
                        generateTodeyStartCourse(
                          xDate(date.action),
                          date.action + 1
                        )
                      "
                    >
                      {{ date.name }}
                    </li>
                  </ul>
                </div>
              </template>
              <template v-slot:body>
                <div class="wrapper">
                  <CourseColumn
                    v-for="course in active_start"
                    :key="course.id"
                    :course="course"
                    @updateCard="updateCard"
                  />
                </div>
              </template>
            </Dialog>
          </Transition>
        </Teleport>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import functions from "@/functions";
import CourseColumn from "@/components/launches/CourseColumn";
export default {
  data() {
    return {
      nearStart: false,
      dropdownActive: false,
      active_start: [],
      title: "",
      dateActions: [
        {
          name: "Вчера",
          action: -1,
        },
        {
          name: "Сегодня",
          action: 0,
        },
        {
          name: "Завтра",
          action: 1,
        },
        {
          name: "После завтра",
          action: 2,
        },
      ],
      todeyModal: false,
    };
  },
  methods: {
    ...mapActions({
      setBranch: "setBranch",
      setLanguage: "setLanguage",
      setCourse: "setCourse",
      restoreAllFilter: "restoreAllFilter",
      setViewNearestDate: "setViewNearestDate",
    }),

    languageFilter(array, language) {
      const result = array.filter(
        (data) =>
          data.language == language &&
          (data.branch == this.currentBranch || "all" == this.currentBranch)
      );
      return result;
    },
    openModal() {
      this.todeyModal = true;
      this.generateTodeyStartCourse(this.xDate(0), 1);
    },
    closeGenerateTodeyStartCourse() {
      this.todeyModal = false;
      this.active_start = [];
    },
    xDate(xDay = 0) {
      let today = new Date();
      today.setDate(today.getDate() + xDay);
      return today;
    },
    generateTodeyStartCourse(dateTime, active) {
      this.active_start = [];
      const stringify = JSON.stringify(this.launcheFilter);
      const parse = JSON.parse(stringify);
      this.active_start = parse.map((a) => {
        a.start_course = [];
        return a;
      });
      const dateTodey = dateTime.toLocaleDateString();
      const timeTodey = dateTime.getTime();
      this.title = dateTodey;
      this.launcheFilter.forEach((course) => {
        if (course.start_course?.length > 0) {
          course.start_course.forEach((start) => {
            const dateStart = new Date(start.date);
            if (dateStart.toLocaleDateString() == dateTodey) {
              start.active = true;
              this.active_start.map((old_course) => {
                if (old_course.id == course.id) {
                  start.schedule.forEach((schedule) => {
                    const [hours, minuts, seconds] =
                      schedule.start_lesson.split(":");
                    const [year, month, day] = start.date.split("-");
                    const start_lesson = new Date(
                      year,
                      +month - 1,
                      day,
                      hours,
                      minuts,
                      seconds
                    );
                    if (
                      timeTodey > start_lesson.getTime() &&
                      timeTodey < start_lesson.getTime() + 3600000
                    )
                      schedule.active = true;
                  });
                  old_course.start_course.push(start);
                }

                return old_course;
              });
            } else {
              start.open_lesson.forEach((open_lesson) => {
                if (
                  new Date(open_lesson.date).toLocaleDateString() == dateTodey
                ) {
                  open_lesson.active = true;
                  this.active_start.map((old_course) => {
                    if (old_course.id == course.id) {
                      open_lesson.open_lesson_time.forEach(
                        (open_lesson_time) => {
                          const [hours, minuts, seconds] =
                            open_lesson_time.time.split(":");
                          const [year, month, day] =
                            open_lesson.date.split("-");
                          const start_lesson = new Date(
                            year,
                            +month - 1,
                            day,
                            hours,
                            minuts,
                            seconds
                          );
                          if (
                            timeTodey > start_lesson.getTime() &&
                            timeTodey < start_lesson.getTime() + 3600000
                          )
                            open_lesson_time.active = true;
                        }
                      );
                      old_course.start_course.push(start);
                    }

                    return old_course;
                  });
                }
              });
            }
          });
        }
      });
      this.active_start = this.active_start.filter(
        (course) => course.start_course.length > 0
      );
      this.active = active;
    },

    showOrHideNearestDate() {
      this.setViewNearestDate();
      this.$store.dispatch("addSnack", {
        title: "Ближайшие запуски",
        time: 8000,
        content: `Вы ${
          this.nearestDateView ? "включили" : "выключили"
        }  отображение ближайших запусков`,
        actions: [
          {
            name: this.nearestDateView ? "ВЫКЛЮЧИТЬ" : "ВКЛЮЧИТЬ",
            onTap: this.setViewNearestDate,
          },
        ],
      });
    },
    dropdown(event) {
      const branchId = event.target.getAttribute("branch_id");
      if (branchId && branchId != this.filterData.current_branch) {
        this.setBranch(branchId);
        this.dropdownActive = false;
      } else {
        this.dropdownActive = !this.dropdownActive;
      }
    },
    languageBtn(language) {
      this.setLanguage(language);
    },
  },
  mounted() {
    const data = this;
    this.dateActions = [
      {
        name: data.$i18n.t("navigation.yesterday"),
        action: -1,
      },
      {
        name: data.$t("navigation.today"),
        action: 0,
      },
      {
        name: data.$t("navigation.tomorrow"),
        action: 1,
      },
      {
        name: data.$t("navigation.after_tomorrow"),
        action: 2,
      },
    ];
    document.addEventListener("click", (e) => {
      if (this.dropdownActive) {
        const parent = e.target.closest(".filials");
        if (!parent) {
          this.dropdownActive = false;
        }
      }
    });
  },
  computed: {
    ...mapState({
      branch_list: (state) => state.branches.branches_list,
      filterData: (state) => state.launches.filterData,
      nearestDateView: (state) => state.launches.nearestDateView,
      launche: function (state) {
        const settings = state.settings.settings;
        const viewEmptyCourse = functions.getSettingKey(
          settings,
          "viewEmptyCourse"
        );
        const multiSelectCourse = functions.getSettingKey(
          settings,
          "multiSelectCourse"
        );
        this.courseLimit = multiSelectCourse ? 5 : 1;
        const launche = JSON.stringify(state.launches.launches);
        const courses = JSON.parse(launche);
        for (const key in courses) {
          const s = courses[key].start_course.filter((e) => !e.is_internship);
          courses[key].start_course = s;
        }
        if (viewEmptyCourse == null || viewEmptyCourse == true) {
          return courses.filter((e) => e.id != 19);
        } else if (viewEmptyCourse == false) {
          return courses.filter((e) => e.start_course.length > 0);
        }
      },
    }),
    launcheFilter() {
      let launche = this.launche;

      if (this.filterData.current_course.length == 0) {
        return launche;
      }

      launche = launche.filter((element) => {
        return this.filterData.current_course.indexOf(element.id) != -1;
      });
      return launche;
    },
    getBranchName() {
      let branchName = "Филиалы";
      this.branch_list.forEach((element) => {
        if (element.id == this.filterData.current_branch) {
          branchName = element.name;
        }
      });
      return branchName;
    },
  },
  components: {
    CourseColumn,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";

.actions-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_link {
    display: flex;

    li {
      padding: 10px;
      font-size: 16px;
      color: #cecece;
      transition: 200ms;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        background: white;
        bottom: 0;
        width: 0;
        height: 0;
        border-radius: 3px 3px 0 0;
        transition: 200ms;
      }

      &.active {
        &::before {
          width: 100%;
          height: 3px;
        }
      }
    }
  }
}

.navigation-block {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: #292929;

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .nav-filters {
      width: 25%;
      display: flex;

      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }

      .filials {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        @include adaptivHeightlg(50, 40);
        @include adaptiv-fontlg(15, 12);
        @include adaptiv-lineHeightlg(32, 20);
        background: transparent;
        text-transform: uppercase;
        text-decoration: none;
        border-radius: 10px;
        cursor: pointer;

        @media (max-width: 768px) {
          @include adaptiv-fontmd(15, 12);
          @include adaptiv-lineHeightmd(40, 30);
        }

        &.active {
          background: rgba($color: #ffffff, $alpha: 0.1);
        }

        &:hover {
          transition: background-color 1s;
          border: none;
        }

        .dropdown {
          position: absolute;
          top: 100%;
          background: #303030;
          border: 1px solid #fff;
          width: 100%;
          color: #fff;
          opacity: 0;
          z-index: 101;
          border-radius: 10px;
          overflow: hidden;
          transition: 200ms;
          transform: translateY(10px);
          visibility: hidden;
          cursor: pointer;

          &.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0px);
          }

          .dropdown-name {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: none;
            list-style: none;

            :hover {
              background: #fff;
              color: #303030;
            }

            li {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;
            }
          }
        }
      }
    }

    .languages {
      width: 100%;
      display: flex;
      cursor: pointer;

      &-list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;

        @media (max-width: 768px) {
          justify-content: center;
        }

        &-RUS,
        &-UZB,
        .near-lessons {
          @include adaptivWidthLg(80, 60);
          width: 100%;
          @include adaptivHeightlg(50, 40);
          @include adaptiv-fontlg(15, 12);
          @include adaptiv-lineHeightlg(32, 20);
          font-family: "Inter";
          font-weight: 400;
          color: #fff;
          background: transparent;
          text-transform: uppercase;
          transition: 1s;
          border: 1px solid #f2f2f2;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          @include adaptivMarginlg(0, 5, 0, 0, 5, 2, 0, 0);
          border-radius: 10px;

          @media (max-width: 768px) {
            @include adaptivWidthmd(80, 55);
          }

          &:hover {
            background: #8a8a8a;
            color: #fff;
            transition: background-color 0.3s;
            border: 1px solid #f2f2f2;
          }

          &.active {
            background: #fff;
            color: #303030;
          }
        }

        .near-lessons {
          @include adaptivWidthLg(220, 110);
        }
      }
    }

    .reset {
      @include adaptivWidthLg(150, 80);
      width: 100%;
      @include adaptivHeightlg(50, 40);
      @include adaptiv-fontlg(15, 12);
      @include adaptiv-lineHeightlg(32, 20);
      font-family: "Inter";
      font-weight: 400;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      transition: 1s;
      border: 1px solid #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      @include adaptivMarginlg(0, 20, 0, 0, 0, 10, 0, 0);
      border-radius: 10px;
      cursor: pointer;

      @media (max-width: 768px) {
        @include adaptivWidthmd(100, 80);
        @include adaptivMarginmd(10, 10, 0, 0, 10, 10, 0, 0);
      }

      &:hover {
        background: #fff;
        color: #303030;
        transition: background-color 1s;
        border: 1px solid #f2f2f2;
      }

      &.active {
        background: #fff;
        color: #303030;
      }
    }
  }

  .nav {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .link {
      @include adaptivWidthLg(150, 80);
      width: 100%;
      @include adaptivHeightlg(50, 40);
      @include adaptiv-fontlg(15, 12);
      @include adaptiv-lineHeightlg(32, 20);
      font-family: "Inter";
      font-weight: 400;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      transition: 1s;
      border: 1px solid #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      @include adaptivMarginlg(0, 40, 20, 0, 0, 20, 10, 0);
      border-radius: 10px;

      @media (max-width: 768px) {
        @include adaptivWidthmd(100, 80);
        @include adaptivMarginmd(0, 15, 20, 0, 0, 10, 10, 0);
      }

      &:hover {
        background: #fff;
        color: #303030;
        transition: background-color 1s;
        border: 1px solid #f2f2f2;
      }
    }
  }
}
</style>
