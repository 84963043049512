const handleRipple = (element, binding, ev) => {
  const rippleElement = document.createElement("span");
  let currentDiameter = 1;
  let currentOpacity = 0.45;
  let animationHandler = setInterval(animateRippleSpread, 10);
  applyRippleStyle();

  function applyRippleStyle() {
    const elementCoordinates = element.getBoundingClientRect();
    const offsetY = ev.clientY - elementCoordinates.y;
    const offsetX = ev.clientX - elementCoordinates.x;

    rippleElement.style.position = "absolute";
    rippleElement.style.height = "5px";
    rippleElement.style.width = "5px";
    rippleElement.style.borderRadius = "100%";
    rippleElement.style.backgroundColor =
      binding.arg || "rgb(242 242 242 / 60%)";
    rippleElement.style.left = `${offsetX}px`;
    rippleElement.style.top = `${offsetY}px`;
    element.appendChild(rippleElement);
  }

  function animateRippleSpread() {
    const maximalDiameter = +binding.radius || 50;
    if (currentDiameter <= maximalDiameter) {
      currentDiameter++;
      currentOpacity -= 0.65 / maximalDiameter;
      rippleElement.style.transform = `scale(${currentDiameter})`;
      rippleElement.style.opacity = `${currentOpacity}`;
    } else {
      rippleElement.remove();
      clearInterval(animationHandler);
    }
  }
};

// Hook the directive to the DOM element
const vRipple = {
  mounted: (el, binding) => {
    let down = false;
    if (getComputedStyle(el).position == "static")
      el.style.position = "relative";
    el.style.cursor = "pointer";

    if (!el.classList.contains("teachers__list-item")) {
      el.style.overflow = "hidden";
    }
    el.addEventListener("mousedown", (ev) => {
      if (!down) handleRipple(el, binding, ev);
      down = true;
    });
    window.addEventListener("mouseup", () => {
      down = false;
    });
  },
};

export default vRipple;
