import Launches from "@/api/launches";
import PusherApi from "@/api/pusher";
const launches = {
  state: {
    launches: [],
    filterData: {
      current_branch: 'all',
      current_language: 'all',
      current_course: [],
    },
    updated_course_id: null,
    nearestDateView: false
  },
  getters: {
    coursesList(state) {
      return state.launches.map((course) => {
        return Object.fromEntries([
          ["id", course.id],
          ["name", course.name],
          ["short_name", course.short_name],
          ["lesson_count", course.lesson_count],
          ["color", course.color],
        ]);
      });
    },
    getUpdatedCourseId(state) {
      return state.updated_course_id
    }
  },
  mutations: {
    setLaunches(state, data) {
      state.launches = data;
    },
    setCourseLaunches(state, data) {
      state.launches.forEach((element) => {
        if (element.id == data.id) {
          element.start_course = data.start_course
          return
        }
      });

    },
    setUpdatedCourseId(state, data) {
      state.updated_course_id = data
    },
    setBranch(state, data) {
      state.branch = data;
    },

    setFilterBranch(state, data) {
      state.filterData.current_branch = data
    },
    setFilterLanguage(state, data) {
      state.filterData.current_language = data
    },
    setFilterCourse(state, data) {
      state.filterData.current_course = data
    },
    setNearestDateView(state, data) {
      state.nearestDateView = data
    }
  },
  actions: {
    async getLaunches(context) {
      const launchResponse = await Launches.getAllLaunches();
      if (launchResponse.status == 200) {
        context.commit("setLaunches", launchResponse.data.result);
        PusherApi.subscribe((data) => {
          setTimeout(() => {
            if (context.state.updated_course_id != data.course_id) {
              context.dispatch("getCourseLaunches", data)
            }
          }, Math.random() * 10000 + 2);

        })
      }
    },
    async getCourseLaunches(context, data) {
      const launchResponse = await Launches.getCourseLaunches(data.course_id);
      if (launchResponse.status == 200) {
        context.commit("setCourseLaunches", launchResponse.data.result);
      }
    },
    async addLaunch(context, data) {
      const response = await Launches.addLaunch(data);
      if (response.status == 200) {
        context.commit("setCourseLaunches", response.data.result);
        context.commit("setUpdatedCourseId", response.data.result.id);
      }
      return response;
    },
    async removeLaunch(context, data) {
      const response = await Launches.removeLaunch(data);
      if (response.status == 200) {
        context.commit("setCourseLaunches", response.data.result);
        context.commit("setUpdatedCourseId", response.data.result.id);
      }
      return response;
    },
    async updateLaunch(context, data) {
      const response = await Launches.updateLaunch(data);
      if (response.status == 200) {
        context.commit("setCourseLaunches", response.data.result);
        context.commit("setUpdatedCourseId", response.data.result.id);
      }
      return response;
    },
    async moveToArchive(context, data) {
      const response = await Launches.moveToArchive(data);
      if (response.status == 200) {
        context.commit("setCourseLaunches", response.data.result);
        context.commit("setUpdatedCourseId", response.data.result.id);
      }
      return response;
    },

    async syncBot() {
      const response = await Launches.syncBot();
      return response;
    },

    setLanguage(context, data) {
      context.commit('setFilterLanguage', data)
    },
    setBranch(context, data) {
      context.commit('setFilterBranch', data)
    },
    setCourse(context, data) {
      context.commit('setFilterCourse', data)
    },
    restoreAllFilter(context) {
      context.commit('setFilterLanguage', 'all')
      context.commit('setFilterBranch', 'all')
      context.commit('setFilterCourse', [])

    },
    setViewNearestDate(context) {
      context.commit('setNearestDateView', !context.state.nearestDateView)
    }
  },
  modules: {},
};

export default launches;