import axios from "axios";
import Api from ".";

class Launches extends Api {
  async getAllLaunches() {
    let response;
    
    try {
      response = await this.client.get('launches/start_courses_all/');
    } catch (error) {
      response = error.response;
    }
    return response;
  }
  async getArchiveLaunches(params) {
    let response;
    
    try {
      response = await this.client.get('launches/get_archive/', {params:params});
    } catch (error) {
      response = error.response;
    }
    return response;
  }
  async getCourseLaunches(course_id) {
    let response;
    
    try {
      response = await this.client.get('launches/get_course_launches/'+course_id);
    } catch (error) {
      response = error.response;
    }
    return response;
  }
  async getBranches() {
    let response;
    
    try {
      response = await this.client.get('launches/branches/');
    } catch (error) {
      response = error.response;
    }
    return response;
  }
  async addLaunch(data){
    let response
    
    try{
      response = this.client.post('launches/add/', data)
    } catch (error) {
      response = error.response
    }
    return response
  }
  async removeLaunch(data){
    let response
    
    try{
      response = await this.client.delete('launches/remove/'+data)
    } catch (error){
      response = error.response
    }
    
    return response
  }
  async updateLaunch(data){
    let response
    
    try{
      response = await this.client.patch('launches/update/', data)
    } catch (error){
      response = error.response
    }
    
    return response
  }
  async moveToArchive(data){
    let response
    
    try{
      response = await this.client.patch('launches/move_to_archive/'+ data.id)
    } catch (error){
      response = error.response
    }
    
    return response
  }
  async syncBot(){
    let response;
    
    try {
      response = await axios.get('https://webinar.proweb.uz/api/update_open_lesson');
    } catch (error) {
      response = error.response;
    }
    try {
      response = await axios.get('https://informer.proweb.uz/api/update_open_lesson');
    } catch (error) {
      response = error.response;
    }
    try {
      response = await axios.get('https://kid-bot.proweb.uz/api/v1/bot/update_open_lesson');
    } catch (error) {
      response = error.response;
    }
    return response;
  }
}

export default new Launches();
