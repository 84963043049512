<template>
  <div class="lead-error__item" :class="{ fixed: item.all_fixed }">
    <div class="lead-error__item-content">
      <p v-if="item.lead.lead_id"><b>Лид ID:</b> {{ item.lead.lead_id }}</p>
      <p v-if="item.lead.responsible"><b>Ответственный:</b> {{ item.lead.responsible }}</p>
      <p v-if="item.lead.name"><b>Ф.И лида:</b> {{ item.lead.name }}</p>
      <p v-if="item.lead.learning_format"><b>Формат обучения:</b> {{ item.lead.learning_format }}</p>
      <p v-if="item.lead.branch"><b>{{ $t('launch_modal.filial') }}:</b> {{ item.lead.branch }}</p>
      <p v-if="item.lead.teacher"><b>{{ $t('launch_modal.teacher') }}:</b> {{ item.lead.teacher }}</p>
      <p>
        <b v-if="item.lead.courses.length == 1">Курс:</b>
        <b v-if="item.lead.courses.length > 1">Курсы:</b>
        <span v-if="item.lead.courses.length == 1">{{ item.lead.courses[0].name }}</span>
      <ul v-if="item.lead.courses.length > 1">
        <li v-for="(item, index) in item.lead.courses" :key="index">
          {{ index + 1 }}.
          {{ item.name }}
        </li>
      </ul>
      </p>
      <p>
        <b v-if="item.lead.contacts.length == 1">{{ $t('teachers_page.modal__phone_number') }}:</b>
        <b v-if="item.lead.contacts.length > 1">Номера телефонов:</b>
        <span v-if="item.lead.contacts.length == 1">{{ item.lead.contacts[0].phone }}</span>
      <ul v-if="item.lead.contacts.length > 1">
        <li v-for="(item, index) in item.lead.contacts" :key="index">
          {{ index + 1 }}.
          {{ item.phone }}
        </li>
      </ul>
      </p>
    </div>
    <div class="lead-error__item-message">
      <template v-for="(item_error) in item.error_messages" :key="item_error">
        <div class="lead-error__item-content" v-if="!item_error.fixed" :class="{ access: item_error.fixed }">
          <div class="lead-error__item-content-avatar">
            <img :src="require(item_error.fixed ? '@/assets/images/done_2.svg' : '@/assets/images/close.svg')" alt="">
            <div class="lead-error__item-content-error_message">{{ item_error.error_message }}</div>
          </div>
          <div class="lead-error__item-content-fix_message">
            <h4>{{ $t('lead-error.solution') }}</h4>
            {{ item_error.fix_message }}
          </div>
        </div>
      </template>
      <div v-ripple @click="show = true" class="lead-error__item-content">
        <p><b>{{ $t('lead-error.solutions_count') }}:</b> {{ count }}</p>

      </div>
      <!-- <button v-ripple:grey class="snackbars__item-btn"> -->
      <a class="snackbars__link" v-ripple:grey :href="`https://prowebschool.amocrm.ru/leads/detail/${item.lead.lead_id}`" target="_blank">
        {{ $t('lead-error.open_crm') }}
      </a>
      <!-- </button> -->
    </div>
  </div>
  <Teleport to="#dialog">
    <Transition name="slide-fade">
      <Dialog v-if="show" @close="show = false">
        <template v-slot:title>Исправленные проблемы</template>
        <template v-slot:body>
          <template v-for="(item_error) in item.error_messages" :key="item_error">
            <div class="lead-error__item-content" v-if="item_error.fixed" :class="{ access: item_error.fixed }">
              <div class="lead-error__item-content-avatar">
                <img :src="require(item_error.fixed ? '@/assets/images/done_2.svg' : '@/assets/images/close.svg')" alt="">
                <div class="lead-error__item-content-error_message">{{ item_error.error_message }}</div>
              </div>
              <div class="lead-error__item-content-fix_message">
                <h4>Решение</h4>
                {{ item_error.fix_message }}
              </div>
            </div>
          </template>
        </template>
      </Dialog>
    </Transition>
  </Teleport>
</template>

<script>
export default {
  mounted() {
    let i = 0;
    this.item.error_messages.forEach((key) => {
      if (key.fixed) {
        i++;
      }
    });
    this.count = i;
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false,
      count: 0,
      // item: {
      //   lead: {
      //     lead_id: "2786617",
      //     responsible: "darl1ne",
      //     name: "asd asd",
      //     learning_format: "offline",
      //     branch: "Ойбек",
      //     teacher: "Bob",
      //     start_date: "2023-05-13T14:30:00+05:00",
      //     courses: [
      //       {
      //         name: "Python",
      //       },
      //     ],
      //     contacts: [
      //       {
      //         phone: "+998932151297",
      //       },
      //     ],
      //   },
      //   error_messages: [
      //     {
      //       v_type: "course",
      //       error_message:
      //         "На данный момент выбранно более одного курса (2), исправьте это",
      //       fix_message:
      //         "Перейдите в amoCRM найдите лида и выберите 1 курс, не больше, не меньше",
      //       fixed: false,
      //     },
      //     {
      //       v_type: "branch",
      //       error_message:
      //         "На данный момент не указан Филиал студента, исправьте это",
      //       fix_message:
      //         "Перейдите в amoCRM, найдите лида и выберите филиал в котором назначен запуск",
      //       fixed: true,
      //     },
      //     {
      //       v_type: "teacher",
      //       error_message:
      //         "На данный момент не указан Преподаватель студента, исправьте это",
      //       fix_message:
      //         "Перейдите в amoCRM, найдите лида и напишите имя преподавателя",
      //       fixed: false,
      //     },
      //     {
      //       v_type: "date_start",
      //       error_message:
      //         "На данный момент не указанна Дата Запуска Курса, исправьте это",
      //       fix_message:
      //         "Перейдите в amoCRM, найдите лида и выберите дату и время старта обучения",
      //       fixed: true,
      //     },
      //     {
      //       v_type: "learn_format",
      //       error_message:
      //         "На данный момент не указан Формат Обучения, исправьте это",
      //       fix_message:
      //         "Перейдите в amoCRM, найдите лида и выберите в каком формате будет обучаться лид на курсе",
      //       fixed: true,
      //     },
      //     {
      //       v_type: "phones",
      //       error_message:
      //         "На данный момент не указанно ни одного корректного номера, исправьте это",
      //       fix_message:
      //         "Перейдите в amoCRM, найдите лида и впишите тот номер телефона который будет содержать полный номер телефон и является номером телефона студента для получения смс информирования. Все остальные номера не должны содержать символа “+” и могут содержать приписки в любом виде например “991234567 мама”",
      //       fixed: false,
      //     },
      //     {
      //       v_type: "name",
      //       error_message:
      //         "На данный момент не указанно имя контакта студента, исправьте это",
      //       fix_message:
      //         "Перейдите в amoCRM, найдите лида и введите его Имя и Фамилию",
      //       fixed: true,
      //     },
      //   ],
      //   all_fixed: false,
      // },
    };
  },
};
</script>

<style lang="scss">
.lead-error__item {
  padding: 10px;
  border-radius: 10px;
  background: rgba(128, 128, 128, 0.24);
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;

  &-message {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &.fixed {
    background: rgba(0, 204, 0, 0.24);
  }

  &-content {
    padding: 10px;
    background: #292929;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 15px;

    &-fix_message {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    p {
      display: flex;
      gap: 10px;
    }

    $parent: &;

    &.access {
      #{$parent}-avatar {
        background: rgba(0, 128, 0, 0.329);
      }
    }

    &-avatar {
      display: flex;
      gap: 10px;
      padding: 10px;
      background: rgba(128, 0, 0, 0.329);
      border-radius: 10px;
    }
  }
}

.settings__window {
  .lead-error__item-content {
    background: #232e3c;
    border-radius: 0;
    margin-bottom: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.315);
  }
}

.snackbars__link {
  text-decoration: none;
  text-align: center;
  background: transparent;
  border: none;
  outline: none;
  border-radius: 100px;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #bcceff;
  transition: 200ms;

  &:hover {
    background: rgba(188, 209, 255, 0.08);
  }
}
</style>