<template>
  <div class="login-block">
    <div class="login-block-card">
      <h3 class="login-block-card-title">PROWEB</h3>
    </div>
    <div class="login-block_content">
      <div class="login-block_content-txt">
        <h3>Hello!</h3>
        <p>{{ $t("login.login_title") }}</p>
        <p class="password-wrong" v-if="pass">
          {{ $t("login.wrong_data_error") }}
        </p>
        <p class="password-wrong" v-if="empty">
          {{ $t("login.empty_error") }}
        </p>
      </div>
      <form @submit.prevent="submitForm" :class="{ wrong: pass || empty }">
        <label for="username"></label>
        <input
          class="login-input"
          id="username"
          :placeholder="$t('login.login')"
          type="text"
          v-model="form.username"
          style="color: black"
        /><br />
        <label for="password"></label>
        <div class="add-password">
          <input
            class="login-input"
            id="password"
            :type="passwordInputType"
            autocomplete="on"
            v-model="form.password"
            :placeholder="$t('login.password')"
            style="color: black"
          />
          <button
            class="show-password"
            @click="changePasswordInputType"
            type="button"
          >
            <font-awesome-icon :icon="iconName" />
          </button>
        </div>
        <input class="btn" type="submit" :value="$t('login.log-in')" />
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      passwordInputType: "password",
      pass: false,
      empty: false,
      iconName: "eye-slash",
    };
  },
  methods: {
    ...mapActions({
      login: "login",
    }),
    submitForm() {
      this.login({
        username: this.form.username,
        password: this.form.password,
      }).then((response) => {
        if (response.status == 200) {
          this.pass = false;
          this.empty = false;
          this.$router.push("/");
        } else if (response.status == 401) {
          this.pass = true;
          this.empty = false;
        } else {
          this.empty = true;
          this.pass = false;
        }
      });
    },
    changePasswordInputType() {
      if (this.passwordInputType == "password") {
        this.passwordInputType = "text";
        this.iconName = "eye";
      } else {
        this.passwordInputType = "password";
        this.iconName = "eye-slash";
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.login-block {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 55% 45%;
  @include adaptivGaplg(190, 80);
  background: #fff;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url("../assets/images/logo.jpg") no-repeat;
    background-size: cover;
  }
  &-card {
    width: 100%;
    height: 100%;
    position: relative;
    background: url("../assets/images/logo.jpg") no-repeat;
    background-size: cover;
    @media (max-width: 768px) {
      width: 100%;
      background: none;
      height: 15%;
    }
    &-title {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      color: #fff;
      @include adaptiv-fontlg(100, 50);
      font-family: "Roboto";
      transform: translate(-50%, -50%);
      @media (max-width: 768px) {
        left: 0;
        top: 0;
        transform: translate(10%, -35%);
        @include adaptiv-fontmd(160, 66);
        color: #fff;
      }
    }
  }
  &_content {
    @include adaptivWidthLg(400, 300);
    @include adaptivHeightlg(400, 300);
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include adaptivHeightmd(500, 300);
      @include adaptivMarginmd(20, 20, 20, 20, 20, 20, 10, 10);
      @include adaptivPaddingmd(30, 30, 30, 30, 20, 20, 10, 10);
      z-index: 2;
    }
    &-txt {
      color: #303030;
      @include adaptiv-fontlg(18, 14);
      font-family: "Inter";
      position: relative;
      @media (max-width: 768px) {
        @include adaptiv-fontmd(30, 13);
        color: #303030;
        @include adaptivMarginmd(0, 20, 0, 40, 0, 20, 0, 25);
      }
      .password-wrong {
        position: absolute;
        color: red;
        @include adaptiv-fontlg(15, 8);
        font-family: "Inter";
        margin-top: 10px;
        @media (max-width: 768px) {
          @include adaptiv-fontmd(20, 10);
          margin-top: 5px;
        }
      }
      h3 {
        font-weight: 700;
        margin-bottom: 10px;
      }
      p {
        font-weight: 300;
      }
    }
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include adaptiv-fontlg(22, 16);
      @include adaptiv-lineHeightlg(22, 16);
      font-family: "Inter";
      font-weight: 500;
      @include adaptivPaddinglg(0, 0, 40, 20, 0, 0, 10, 10);
      color: #c1c1c1;

      &.wrong {
        .login-input {
          border: 1px solid red;
        }
        animation: passwordWrong 0.1s alternate ease-in-out;
      }
      .add-password {
        position: relative;
        .show-password {
          border: none;
          background: none;
          width: 30px;
          height: 30px;
          margin-left: auto;
          position: absolute;
          right: 2%;
          bottom: 30%;
          @include adaptiv-fontlg(18, 14);
          font-family: "Inter";
          font-weight: 300;
          color: #747474;
          @media (max-width: 768px) {
            top: -15px;
            right: -2%;
            @include adaptivMarginmd(20, 20, 40, 0, 15, 10, 20, 0);
            @include adaptiv-fontmd(20, 15);
          }
        }
      }
      .login-input {
        width: 100%;
        height: 60px;
        @include adaptiv-fontlg(18, 14);
        font-family: "Inter";
        font-weight: 300;
        @include adaptivPaddinglg(20, 20, 20, 20, 10, 10, 10, 10);
        background: #f5f5f5;
        border: none;
        border-radius: 10px;
        margin: 0;
        @media (max-width: 768px) {
          @include adaptivHeightmd(80, 40);
          @include adaptiv-fontmd(25, 12);
          @include adaptivPaddingmd(20, 20, 20, 20, 10, 10, 10, 10);
        }
      }
      .btn {
        width: 100%;
        height: 60px;
        @include adaptiv-fontlg(18, 10);
        font-family: "Inter";
        font-weight: 400;
        color: #fff;
        background: #292929;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: 1px solid #303030;
        margin: 25px 0;
        @media (max-width: 768px) {
          @include adaptivHeightmd(80, 40);
          @include adaptiv-fontmd(18, 14);
          @include adaptivPaddingmd(20, 20, 20, 20, 10, 10, 10, 10);
        }
        &:hover {
          background: #fff;
          color: #303030;
          transition: background-color 0.5s;
        }
      }
    }
  }
  @keyframes passwordWrong {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(5px);
    }
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(5px);
    }
  }
}
</style>
