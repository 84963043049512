import {Api, ErrorRequest} from '../../api/fetch' 
const lead_errors = {
  state: {
    start_courses: null,
    params:null
  },
  getters: {
  },
  mutations: {
    setStartCourses(state, data) {
      state.start_courses = data;
    },
    setParams(state, data){
      state.params = data
    }

  },
  actions: {
    async getLeadErrors() {
      const api = new Api()
      const response = await api.domain('https://my.proweb.uz/supervisor/api/v1/').get('invalid-leads/', false)
      // const response = await api.domain("http://192.168.2.13:8000/supervisor/api/v1/").get("invalid-leads/", false)

      return response.results
    }
  },
  modules: {},
};

export default lead_errors;
