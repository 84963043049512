<template>
  <LaunchesNavigation />
  <div class="navigation" style="gap: 10px; padding-right: 10px" v-if="user.admin">
    <button v-ripple class="link-icon white" type="button" @click="openModal">
      <img src="@/assets/images/add_card.svg" alt="" />
      {{ $t("navigation.add_card") }}
    </button>
    <button v-ripple class="link-icon white" type="button" @click="synchronousBot">
      <img src="@/assets/images/sync.svg" alt="" />
      {{ $t("navigation.synchronize") }}
    </button>
  </div>
  <div class="courses" ref="course_nav">
    <button class="courses-btn" v-for="course in launche" :key="course.id" v-ripple @click="filterCourse(course.id)" :style="{
      background: colorGenerate(course.color),
    }" :class="{ active: filterData.current_course.indexOf(course.id) != -1 }">
      {{ course.name }}
    </button>
    <button class="courses-btn" v-ripple v-if="internship.length > 0" @click="openWork">
      <img src="@/assets/images/work.svg" alt="" />{{
        $t("launch_modal.intership")
      }}
    </button>
  </div>
  <!-- {{ newStartCard }} -->
  <div class="notify" v-if="!settings.newStartCard.view && !newStartCard">
    <div class="notify__body">
      <h3>{{ $t("navigation.notification__title") }}</h3>
      <p>
        {{ $t("navigation.notification__text") }}
      </p>
      <span>{{ $t("navigation.notification__alert") }}</span>
    </div>
    <div class="notify__traling">
      <button v-ripple @click="clickClose">
        {{ $t("navigation.notification__cancel") }}
      </button>
      <button v-ripple @click="activateNewStartCard">
        {{ $t("navigation.notification__accept") }}
      </button>
    </div>
  </div>
  <div class="wrapper">
    <CourseColumn v-for="course in launcheFilter" :key="course.id" :course="course" @updateCard="updateCard" :currentLanguage="filterData.current_language" :currentBranch="filterData.current_branch" />
  </div>
  <Teleport to="#dialog">
    <Transition name="slide-fade">
      <Dialog v-if="showModal" @close="closeWork" :width="1600">
        <template v-slot:title>
          <div class="titlebtn">
            {{ $t("launch_modal.intership") }}
            <button class="link-icon" v-ripple @click="showSetting = true">
              <img src="@/assets/images/settings.svg" alt="" />{{
                $t("navigation.settings")
              }}
            </button>
          </div>
          <Teleport to="#dialog">
            <Transition name="slide-fade">
              <Dialog v-if="showSetting" @close="close">
                <template v-slot:title>{{
                  $t("navigation.settings")
                }}</template>
                <template v-slot:body>
                  <Settings />
                </template>
              </Dialog>
            </Transition>
          </Teleport>
        </template>
        <template v-slot:body>
          <div class="wrapper">
            <CourseColumn v-for="course1 in internship" :key="course1.id" :course="course1" @updateCard="updateCard" :currentLanguage="filterData.current_language" :currentBranch="filterData.current_branch" />
          </div>
        </template>
      </Dialog>
    </Transition>
  </Teleport>
  <Teleport to="#dialog">
    <Transition name="slide-fade">
      <Dialog v-if="modalOpened" @close="closeModal" :width="1200">
        <template v-slot:title>
          {{
            updateModal ? "Редактировать запуск" : "Добавить запуск"
          }}</template>
        <template v-slot:body>
          <ModalForm v-if="modalOpened" @close="closeModal" :updateModal="updateModal" :updateCardData="updateCardData" />
        </template>
      </Dialog>
    </Transition>
  </Teleport>
</template>
<script>
import CourseColumn from "@/components/launches/CourseColumn";
import ModalForm from "@/components/launches/ModalForm";
import LaunchesNavigation from "@/components/launches/LaunchesNavigation";
import { mapActions, mapState } from "vuex";
import functions from "@/functions";
import Settings from "@/components/Settings.vue";
export default {
  components: {
    CourseColumn,
    ModalForm,
    LaunchesNavigation,
    Settings,
  },
  data() {
    return {
      modalOpened: false,
      updateModal: false,
      updateCardData: {},
      courseLimit: 1,
      showModal: false,
      showSetting: false,
      newStartCard: false,
    };
  },
  computed: {
    ...mapState({
      launche: function (state) {
        const settings = state.settings.settings;
        const viewEmptyCourse = functions.getSettingKey(
          settings,
          "viewEmptyCourse"
        );
        const multiSelectCourse = functions.getSettingKey(
          settings,
          "multiSelectCourse"
        );
        this.courseLimit = multiSelectCourse ? 5 : 1;
        const launche = JSON.stringify(state.launches.launches);
        const courses = JSON.parse(launche);
        for (const key in courses) {
          const s = courses[key].start_course.filter((e) => !e.is_internship);
          courses[key].start_course = s;
        }
        if (viewEmptyCourse == null || viewEmptyCourse == true) {
          return courses.filter((e) => e.id != 19);
        } else if (viewEmptyCourse == false) {
          return courses.filter((e) => e.start_course.length > 0);
        }
      },
      internship: function (state) {
        const settings = state.settings.settings;

        const multiSelectCourse = functions.getSettingKey(
          settings,
          "multiSelectCourse"
        );
        this.courseLimit = multiSelectCourse ? 5 : 1;
        const launche = JSON.stringify(state.launches.launches);
        const courses = JSON.parse(launche);
        for (const key in courses) {
          const s = courses[key].start_course.filter((e) => e.is_internship);
          courses[key].start_course = s;
        }

        return courses.filter((e) => e.start_course.length > 0);
      },
      settings: (state) => state.settings.settings,
      user: (state) => state.auth.user,
      filterData: (state) => state.launches.filterData,
    }),
    launcheFilter() {
      let launche = this.launche;

      if (this.filterData.current_course.length == 0) {
        return launche;
      }

      launche = launche.filter((element) => {
        return this.filterData.current_course.indexOf(element.id) != -1;
      });
      return launche;
    },
  },
  methods: {
    ...mapActions({
      getLaunches: "getLaunches",
      syncBot: "syncBot",
      setCourse: "setCourse",
      getTeachers: "getTeachers",
      getBranches: "getBranches",
    }),
    colorGenerate(color) {
      return `${color}82`;
    },
    checkClose() {
      return localStorage.newStartCard;
    },
    clickClose() {
      localStorage.newStartCard = true;
      this.newStartCard = true;
    },
    activateNewStartCard() {
      this.$store.dispatch(`updatesettings`, {
        key: "newStartCard",
        value: true,
      });
      localStorage.newStartCard = true;
      this.newStartCard = true;
      this.$store.dispatch("addSnack", {
        title: this.$t("navigation.settings"),
        content: this.$t("Course_card.upated_card_design"),
        time: 10000,
        icon: "settings",
      });
    },
    openWork() {
      this.showModal = true;
      document.querySelector("body").style.overflow = "hidden";
    },
    closeWork() {
      this.showModal = false;
      document.querySelector("body").style.overflow = "auto";
    },
    closeModal() {
      this.modalOpened = false;
      this.updateCardData = {};
      this.updateModal = false;
    },
    close() {
      this.showSetting = false;
    },
    openModal() {
      this.modalOpened = true;
    },
    updateCard(data) {
      this.updateCardData = data;
      this.updateModal = true;
      this.openModal();
    },
    synchronousBot() {
      this.syncBot().then((el) => {
        if (el.status == 200) {
          const alert_success = this.$t("snackbar.alert_success");
          const alert_text = this.$t("snackbar.alert_text");
          alert(alert_success + " " + alert_text);
        }
      });
    },
    filterCourse(course_id) {
      const courseList = this.filterData.current_course;
      const index = courseList.indexOf(course_id);

      if (this.courseLimit <= courseList.length) {
        if (index != -1) {
          courseList.splice(index);
        } else {
          courseList.shift();
          courseList.push(course_id);
        }
      } else {
        if (index != -1) {
          courseList.splice(index);
        } else {
          courseList.push(course_id);
        }
      }

      this.setCourse(courseList);
    },
    scrollCourse(course_id) {
      const course_cart = document.querySelector(`#${course_id}`);
      const course_nav = this.$refs.course_nav;
      const scrolDistance = course_cart.offsetTop - course_nav.clientHeight;
      let distance = window.scrollY;
      const scroll = () => {
        if (scrolDistance > distance + 0.5 || scrolDistance < distance - 0.5) {
          distance -= (distance - scrolDistance) / 15;
          window.scrollTo(0, distance);
          this.timer = setTimeout(() => {
            scroll();
          }, 5);
        } else {
          window.scrollTo(0, scrolDistance);
          stop();
        }
      };
      const stop = () => {
        clearTimeout(this.timer);
        window.removeEventListener("wheel", stop);
      };
      stop();
      window.addEventListener("wheel", stop);
      scroll();
    },
  },
  mounted() {
    this.getBranches();
    this.getTeachers();
    this.getLaunches();
    if (!localStorage.newStartCard) {
      this.newStartCard = localStorage.newStartCard;
    } else {
      this.newStartCard = true;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/grid/grid";

.notify {
  padding: 8px 16px;
  background: rgb(47, 119, 202);
  margin: 0 auto;
  max-width: 500px;
  border-radius: 8px;
  color: white;

  &__traling {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      background: transparent;
      border: none;
      outline: none;
      color: white;
      padding: 8px 10px;
      border-radius: 100px;
      transition: 200ms;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 8px;

    h3 {
      font-weight: 600;
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }

    span {
      padding: 8px 16px;
      background: rgba(0, 0, 0, 0.418);
      border-radius: 8px;
      font-size: 14px;
      width: 100%;
    }
  }
}

.titlebtn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.modal__intership {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #292929;

  h3 {
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }

  .wrapper {
    height: 100vh;
    overflow: auto;
  }
}

.navigation {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #292929;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
}

.courses {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #292929;
  @include adaptivPaddinglg(20, 20, 10, 20, 10, 10, 25, 10);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 100;
  gap: 2px;

  @media (max-width: 768px) {
    position: static;
  }

  &-btn {
    @include adaptivWidthLg(230, 180);
    width: 100%;
    @include adaptiv-fontlg(15, 10);
    font-family: "Inter";
    font-weight: 500;
    color: #fff;
    margin: 1px;
    border: 1px solid #303030;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @include adaptivPaddinglg(8, 8, 8, 8, 2, 2, 5, 5);
    border-radius: 10px;

    @media (max-width: 768px) {
      @include adaptivWidthmd(150, 100);
      @include adaptivPaddingmd(0, 0, 15, 15, 0, 0, 10, 10);
      @include adaptiv-fontmd(10, 7);
    }

    &:hover {
      border: 1px solid #fff;
      transition: background-color 0.5s;
    }

    &.active {
      border: 1px solid #fff;
      background: #303030 !important;
    }
  }
}

.add-btn {
  @include adaptivWidthLg(265, 230);
  width: 100%;
  @include adaptivHeightlg(50, 40);
  transition: 1s;
  @include adaptiv-fontlg(15, 12);
  @include adaptiv-lineHeightlg(32, 20);
  font-family: "Inter";
  font-weight: 500;
  margin-left: 20px;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  color: #303030;
  @include adaptivMarginlg(0, 20, 0, 0, 0, 10, 0, 0);

  @media (max-width: 768px) {
    @include adaptivWidthmd(250, 150);
    @include adaptiv-fontmd(10, 8);
    color: #303030;
    margin: 0 2px;
  }

  &:hover {
    background: #303030;
    color: #fff;
    transition: background-color 1s;
    border: 1px solid #f2f2f2;
  }
}

.wrapper {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: #292929;
  @include adaptivPaddinglg(0, 0, 20, 70, 0, 0, 10, 35);

  @media (max-width: 768px) {
    flex-direction: column;
    display: flex;
    align-items: center;
  }
}
</style>
