const snackbars = {
  state: {
    snackbars: [],
  },
  getters: {
    getSnacks(state) {
      return state.snackbars;
    },
  },
  mutations: {
    addSnack(state, data) {
      state.snackbars.push(data);
      const audio = new Audio(require("@/assets/audio/mess.mp3"));
      if (data.audio) audio.play();
    },
    removeSnack(state, data) {
      const item = state.snackbars.find((a) => a.id == data);
      const audio = new Audio(require("@/assets/audio/clear.mp3"));
      if (item?.audio) {
        audio.play();
      }
      state.snackbars = state.snackbars.filter((a) => a.id != data);
    },
    removeAllSnack(state) {
      state.snackbars = [];
    },
  },
  actions: {
    addSnack({ commit }, content) {
      const id = Math.random() * (99999 - 1) + 1;
      const time = content.time ?? 5000;
      commit("addSnack", { ...content, id });
      setTimeout(() => {
        commit("removeSnack", id);
      }, time);
    },
    removeSnack({ commit }, id) {
      commit("removeSnack", id);
    },
    removeAllSnack({ commit }) {
      commit("removeAllSnack");
    },
  },
};

export default snackbars;
