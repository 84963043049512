<template>
  <div class="lead">

    <ul class="lead__tab">

      <template v-for="item, idx in tabContent" :key="item">

        <li v-ripple:#000 class="lead__item" @click="setActive(idx)" :class="{ active: idx === active }">{{ `${item}: ${idx === 0 ? unsolvedContent.length : solvedContent.length}` }}</li>
      </template>
    </ul>
    <ul class="lead__resp" v-if="active === 0">
      <li class="lead__resp-item restart" title="Сбросить" @click="setActiveResposible('')"><img src="@/assets/images/reset.svg" alt="" /></li>
      <template v-for="(item, key) in responsible" :key="key">
        <li class="lead__resp-item" v-if="item > 0" :class="{ active: key == responsible_user }" @click="setActiveResposible(key)">
          {{ key }} <span>{{ item }}</span></li>
      </template>
    </ul>
    <div class="lead-grid" v-if="active === 0">
      <LeadItem v-for="item in unsolvedContent" :item="item" :key="item.lead.lead_id" />
    </div>
    <div class="lead-grid" v-if="active === 1">
      <LeadItem v-for="item in solvedContent" :item="item" :key="item.lead.lead_id" />
    </div>
  </div>
</template>

<script>
import LeadItem from "@/components/LeadError/LeadItem.vue";
export default {
  data() {
    return {
      leadData: [],
      tabContent: ['Не решенные', 'Решенные'],
      solvedContent: [],
      unsolvedContent: [],
      responsible: {},
      responsible_user: '',
      active: 0
    }
  },
  components: {
    LeadItem,
  },
  methods: {
    setActive(val) {
      this.active = val
    },
    setActiveResposible(responsible) {

      this.responsible_user = responsible
    }
  },
  watch: {
    responsible_user() {
      if (this.responsible_user.length > 0) {
        this.tabContent = ['Не решенные']
        this.unsolvedContent = this.leadData.filter(a => this.responsible_user == a.lead.responsible && !a.all_fixed)
      } else {
        this.unsolvedContent = this.leadData.filter(item => !item.all_fixed)
        this.tabContent = ['Не решенные', 'Решенные']
      }
    }
  },
  async mounted() {
    const response = await this.$store.dispatch("getLeadErrors")
    this.leadData = response
    this.solvedContent = this.leadData.filter(item => item.all_fixed)
    this.unsolvedContent = this.leadData.filter(item => !item.all_fixed)
    this.unsolvedContent.forEach(lead => {
      if (this.responsible[lead.lead.responsible]) {
        this.responsible[lead.lead.responsible] = this.responsible[lead.lead.responsible] + 1
      } else {
        this.responsible[lead.lead.responsible] = 1
      }

    })
    // console.log(response);
  }
};
</script>

<style lang="scss">
.lead {
  padding: 10px;
  width: 100%;
  overflow: hidden;

  &__tab {
    background: #fff;
    width: 100%;
    display: flex;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    padding: 5px;
    align-items: center;
    gap: 10px;
  }

  &__resp {
    display: inline-flex;
    gap: 10px;
    white-space: nowrap;
    overflow: auto;
    width: 100%;
    margin-bottom: 15px;

    &-item {
      padding: 10px;
      color: white;
      display: inline-flex;
      cursor: pointer;
      user-select: none;
      border-radius: 50px;
      align-items: center;
      gap: 10px;
      transition: 200ms;

      &:hover {
        background: rgba(255, 255, 255, 0.103);
      }

      span {
        display: block;
        min-width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        color: #292929;
        border-radius: 50%;
        transition: 200ms;
      }

      &.active {
        background: white;
        color: #292929;

        span {
          background: #292929;
          color: white;
        }
      }
    }
  }

  &__item {
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    width: 100%;
    font-size: 20px;
    transition: 200ms;
    color: #292929;
    cursor: pointer;
    position: relative;



    &.active {
      color: #fff;
      position: relative;
      background: #292929;

      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 4px;
        width: 100px;
        background: #fff;
        border-radius: 15px 15px 0 0;
      }
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill,
        minmax(max(240px, (100% - 3 * 12px)/3), 1fr));
    gap: 10px;
  }

}
</style>