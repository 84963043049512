<template>
  <div class="teacher__header">
    <div
      class="teacher__header-ava"
      :style="{ width: `${radius + 2}px`, height: `${radius + 2}px` }"
      v-ripple
      @click="
        showFixed = !showFixed;
        width = 400;
      "
    >
      <img
        class="teacher__header-img"
        v-if="teacher.img"
        :src="teacher.img"
        alt=""
        :style="{
          cursor: visibility ? 'pointer' : '',
          width: `${radius}px`,
          height: `${radius}px`,
        }"
      />
      <img
        class="teacher__header-img"
        v-else
        src="@/assets/images/no_ava.svg"
        alt=""
        :style="{
          cursor: visibility ? 'pointer' : '',
          width: `${radius}px`,
          height: `${radius}px`,
        }"
      />
    </div>
    <div
      class="teacher__header-avahover"
      :style="{ 'max-width': width + 'px' }"
      ref="images"
      :class="{ active: showFixed && visibility }"
      v-if="visibility"
    >
      <div
        class="show-whell"
        v-if="showFixed && visibility"
        @wheel.prevent="whell"
      >
        <div class="show-whell__mouse">
          <div class="show-whell__mouse-whell"></div>
        </div>
      </div>
      <button
        v-ripple
        @click="
          showFixed = false;
          width = 350;
        "
        class="teacher__header-avahover-close"
        v-if="showFixed && visibility"
      >
        <img src="@/assets/images/close.svg" alt="" />
      </button>
      <img v-if="teacher.img" :src="teacher.img" alt="" />
      <img v-else src="@/assets/images/no_ava.svg" alt="" />
    </div>
    <div class="teacher__header-info">
      <h3 class="teacher__header-name">
        {{ teacher.last_name }} {{ teacher.first_name }}
        {{ teacher.patronymic }}
      </h3>
      <p class="teacher__header-born">
        {{ !course ? functions.calculate_age(teacher.born) : course }} |
        {{ $t("teachers_page.modal__language") }}: {{ teacher.language }}
      </p>
    </div>
  </div>
</template>

<script>
import functions from "@/functions";
export default {
  props: {
    teacher: Object,
    visibility: {
      type: Boolean,
      default: false,
    },
    course: {
      type: String,
      default: "",
    },
    radius: {
      type: Number,
      default: 68,
    },
  },
  data() {
    return {
      functions,
      showFixed: false,
      width: 350,
    };
  },
  methods: {
    whell(e) {
      if (e.deltaY < 0) {
        if (this.width < 550) {
          this.width = this.width + 50;
        }
      } else {
        if (this.width > 200) {
          this.width = this.width - 50;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.show-whell {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms;
  &:hover {
    opacity: 0;
  }
  &__mouse {
    width: 35px;
    height: 60px;
    border: 3px solid white;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    position: relative;
    &-whell {
      width: 6px;
      height: 12px;
      background: white;
      border-radius: 20px;
      animation: whell 2s infinite ease-in-out;
      position: absolute;
    }
  }
}
@keyframes whell {
  0% {
    top: 5px;
  }
  50% {
    top: 20px;
  }
  100% {
    top: 5px;
  }
}
.teacher {
  &__header {
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    color: white;
    text-transform: uppercase;
    position: relative;

    &:hover &-ava {
      animation: linaerG 15s linear infinite;
    }
    &-info {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    &-name {
      font-size: 16px;
      font-weight: normal;
    }
    &-born {
      font-size: 14px;
    }
    &-ava {
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
        0deg,
        indigo,
        rgb(255, 41, 226),
        red,
        blue,
        orange,
        purple,
        indigo
      );
      background-size: 300% 300%;
      border-radius: 50%;
      &:hover ~ &hover,
      &hover.active {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }
    }
    &-avahover {
      top: 95px;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      left: 10px;
      max-width: 350px;
      width: 100%;
      padding: 5px;
      border-radius: 16px;
      transition: 200ms;
      transform: translateY(20px);
      background: #667e9783;
      backdrop-filter: blur(10px);
      z-index: 1;
      &-close {
        background: rgba($color: #000000, $alpha: 0.4);
        border: none;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 5px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          display: block;
        }
        cursor: pointer;
        &:hover {
          background: rgba($color: #ffffff, $alpha: 0.4);
        }
      }
      & > img {
        width: 100%;
        border-radius: 16px;
        display: block;
      }
    }
    &-img {
      width: 68px;
      height: 68px;
      object-fit: cover;
      object-position: right;
      border-radius: 50%;
      border: 2px solid transparent;
      display: block;
    }
  }
}
@keyframes linaerG {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 300% 300%;
  }
  100% {
    background-position: 10% 0%;
  }
}
</style>
