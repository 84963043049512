import Launches from "@/api/launches";
const launches = {
  state: {
    start_courses: null,
    params:null
  },
  getters: {
  },
  mutations: {
    setStartCourses(state, data) {
      state.start_courses = data;
    },
    setParams(state, data){
      state.params = data
    }
    
  },
  actions: {
    async getArchiveLaunches(context, data) {
      context.commit("setParams", data)
      const archiveLaunchResponse = await Launches.getArchiveLaunches(data.params);
      if (archiveLaunchResponse.status == 200) {
        context.commit("setStartCourses", archiveLaunchResponse.data.result);
      }
    }
  },
  modules: {},
};

export default launches;
