<template>
  <div class="navigation-block">
    <div class="nav" id="nav">
      <router-link v-ripple class="link-icon" to="/lead-error" v-if="this.$store.state.auth.user.user_id !== 5"><img src="@/assets/images/alert.svg" alt="" />Лиды с
        ошибками</router-link>
      <button v-ripple class="link-icon" @click="open">
        <img src="@/assets/images/settings.svg" alt="" />{{
          $t("navigation.settings")
        }}
      </button>
      <Teleport to="#dialog">
        <Transition name="slide-fade">
          <Dialog v-if="show" @close="close">
            <template v-slot:title>{{ $t("navigation.settings") }}</template>
            <template v-slot:body>
              <Settings />
            </template>
          </Dialog>
        </Transition>
      </Teleport>
      <router-link v-ripple class="link-icon" to="/"><img src="@/assets/images/start.svg" alt="" />{{
        $t("navigation.launches")
      }}</router-link>
      <router-link v-ripple class="link-icon" to="/archive"><img src="@/assets/images/archiv.svg" alt="" />{{
        $t("navigation.launches_archive")
      }}</router-link>
      <router-link v-ripple class="link-icon" to="/teachers"><img src="@/assets/images/teacher.svg" alt="" />{{
        $t("navigation.teachers")
      }}
        <Transition name="slide-fade">
          <span v-if="courses_teacher.length">{{
            courses_teacher.length
          }}</span>
        </Transition>
      </router-link>
      <router-link v-ripple class="link-icon" to="/master-classes"><img src="@/assets/images/mk.svg" alt="" />{{
        $t("navigation.master_classes")
      }}</router-link>
      <!-- <router-link class="link-icon" to="/ads"
        ><img src="@/assets/images/ads.svg" v-if="user.admin" alt="" />Банеры</router-link
      > -->
      <router-link v-ripple class="link-icon" to="/logout"><img src="@/assets/images/logout.svg" alt="" />{{
        $t("navigation.log_out")
      }}</router-link>
    </div>
  </div>
</template>

<script>
import Settings from "@/components/Settings.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: { Settings },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      courses_teacher: function (state) {
        const teachers = JSON.parse(
          JSON.stringify(state.teachers.teachers_list)
        );

        return teachers.filter((a) => a.active);
      },
    }),
  },
  mounted() {
    this.getTeachers();
    this.getPaid();
    setInterval(() => this.getPaid(), 1000 * 60 * 30);
    this.getBooking();
    setInterval(() => this.getBooking(), 1000 * 60 * 30);
  },
  methods: {
    ...mapActions({
      getTeachers: "getTeachers",
      getPaid: "getPaid",
      getBooking: "getBooking",
    }),
    close() {
      this.show = false;
    },

    open() {
      document.querySelector("body").style.overflow = "hidden";
      this.show = true;
    },
  },
};
</script>

<style lang="scss">
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
}

.slide-card-enter-active,
.slide-card-leave-active {
  transition: all 300ms ease-out;
}

.slide-fade-enter-from .settings__window {
  transform: translateY(80px) scale(0.9);
}

.slide-fade-leave-to .settings__window {
  transform: translateY(-80px) scale(0.9);
}

.slide-fade-teacher-enter-active,
.slide-fade-teacher-leave-active {
  transition: all 0.5s ease-out;
}

@import "@/assets/scss/grid/grid";

.link-icon {
  font-family: "Inter";
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: transparent;
  border: none;
  gap: 10px;
  cursor: pointer;
  border-radius: 100px;
  padding: 8px 12px;
  transition: 200ms;
  user-select: none;

  span {
    background: #fff;
    padding: 5px;
    font-size: 14px;
    color: #292929;
    font-weight: bold;
    border-radius: 100px;
    transition: 500ms;
  }

  &.active,
  &.router-link-active {
    background: white;
    color: #000;

    img {
      filter: invert(1);
    }

    &:hover {
      background: rgba($color: #ffffff, $alpha: 0.7);
    }

    span {
      background: #292929;
      color: #fff;
    }
  }

  &:hover {
    background: rgba($color: #ffffff, $alpha: 0.1);
  }

  &.white {
    background: white;
    color: #000;

    &:hover {
      background: rgba($color: #ffffff, $alpha: 0.7);
    }
  }

  &.red {
    background: #fbe1e1;
    color: red;

    &:hover {
      background: rgba($color: #fbe1e1, $alpha: 0.8);
    }
  }

  &.blue {
    background: #e1e1fb;
    color: blue;

    &:hover {
      background: rgba($color: #e1e1fb, $alpha: 0.8);
    }
  }

  &.green {
    background: #e1eee1;
    color: green;

    &:hover {
      background: rgba($color: #e1eee1, $alpha: 0.8);
    }
  }

  &.black {
    margin-top: 10px;
    width: 100%;
    text-transform: uppercase;
    background: rgba($color: black, $alpha: 0.1);
    color: black;

    &:hover {
      background: rgba($color: black, $alpha: 0.2);
    }
  }
}

.navigation-block {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: #292929;
  padding: 10px 10px 0;

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .nav-filters {
      width: 25%;
      display: flex;

      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }
    }

    .languages {
      width: 100%;
      display: flex;
      cursor: pointer;

      &-list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 768px) {
          justify-content: center;
        }

        &-RUS,
        &-UZB,
        .near-lessons {
          @include adaptivWidthLg(80, 60);
          width: 100%;
          @include adaptivHeightlg(50, 40);
          @include adaptiv-fontlg(15, 12);
          @include adaptiv-lineHeightlg(32, 20);
          font-family: "Inter";
          font-weight: 400;
          color: #fff;
          background: transparent;
          text-transform: uppercase;
          transition: 1s;
          border: 1px solid #f2f2f2;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          @include adaptivMarginlg(0, 5, 0, 0, 5, 2, 0, 0);
          border-radius: 10px;

          @media (max-width: 768px) {
            @include adaptivWidthmd(80, 55);
          }

          &:hover {
            background: #8a8a8a;
            color: #fff;
            transition: background-color 0.3s;
            border: 1px solid #f2f2f2;
          }

          &.active {
            background: #fff;
            color: #303030;
          }
        }

        .near-lessons {
          @include adaptivWidthLg(220, 110);
        }
      }
    }

    .reset {
      @include adaptivWidthLg(150, 80);
      width: 100%;
      @include adaptivHeightlg(50, 40);
      @include adaptiv-fontlg(15, 12);
      @include adaptiv-lineHeightlg(32, 20);
      font-family: "Inter";
      font-weight: 400;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      transition: 1s;
      border: 1px solid #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      @include adaptivMarginlg(0, 20, 0, 0, 0, 10, 0, 0);
      border-radius: 10px;
      cursor: pointer;

      @media (max-width: 768px) {
        @include adaptivWidthmd(100, 80);
        @include adaptivMarginmd(10, 10, 0, 0, 10, 10, 0, 0);
      }

      &:hover {
        background: #fff;
        color: #303030;
        transition: background-color 1s;
        border: 1px solid #f2f2f2;
      }

      &.active {
        background: #fff;
        color: #303030;
      }
    }
  }

  .nav {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .link {
      @include adaptivWidthLg(150, 80);
      width: 100%;
      @include adaptivHeightlg(50, 40);
      @include adaptiv-fontlg(15, 12);
      @include adaptiv-lineHeightlg(32, 20);
      font-family: "Inter";
      font-weight: 400;
      color: #fff;
      cursor: pointer;
      background: transparent;
      text-transform: uppercase;
      transition: 1s;
      border: 1px solid #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      @include adaptivMarginlg(0, 40, 20, 0, 0, 20, 10, 0);
      border-radius: 10px;

      @media (max-width: 768px) {
        @include adaptivWidthmd(100, 80);
        @include adaptivMarginmd(0, 15, 20, 0, 0, 10, 10, 0);
      }

      &:hover {
        background: #fff;
        color: #303030;
        transition: background-color 1s;
        border: 1px solid #f2f2f2;
      }
    }
  }
}
</style>
