<template>
  <div class="mc__start">
    <div class="mc__start-item">
      <span class="mc__start-item-themeName">
        {{ master.theme }}
      </span>
      <span class="mc__start-item-seats">
        {{ master.capacity }} {{ $t("launch_modal.seat_count") }}
      </span>
      <span class="branch">
        <span class="branch-place"
          >{{ $t("master-class_page.auditory") }}:</span
        >
        {{ master.place }}
      </span>
      <div class="mc__start-item-speaker">
        {{ $t("master-class_page.modal__speaker") }}:
        <span class="mc__start-item-speaker-name">{{ master.speaker }}</span>
      </div>

      <div class="mc__start-item-date">
        {{ dateFormat(master.date) }}
      </div>
      <div class="mc__start-item-date">
        {{ timeFormat(master.time) }}
      </div>
      <div class="btn-list-config" v-if="user.admin && !acceptRemove">
        <button class="link-icon red" type="button" @click="acceptRemoveCard">
          <img src="@/assets/images/delet.svg" alt="" />
          {{ $t("launch_modal.delete") }}
        </button>
        <button
          class="link-icon blue"
          type="button"
          @click="updateCard(master)"
        >
          <img src="@/assets/images/edit.svg" alt="" />
          {{ $t("Course_card.edit") }}
        </button>
      </div>
      <Dialog v-if="acceptRemove" @close="cancelRemoveCard">
        <template v-slot:title>{{
          $t("master-class_page.delete_course-modal")
        }}</template>
        <template v-slot:body>
          <p class="delete">{{ $t("Course_card.delete__modal_confirm") }}</p>
          <div class="dialog-btns">
            <button
              class="link-icon red"
              type="button"
              @click="removeCard(master.id)"
            >
              <img src="@/assets/images/done.svg" alt="" />
              {{ $t("Course_card.delete__modal_accept") }}
            </button>
            <button
              class="link-icon green"
              type="button"
              @click="cancelRemoveCard"
            >
              <img src="@/assets/images/no.svg" alt="" />
              {{ $t("Course_card.delete__modal_decline") }}
            </button>
          </div>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Dialog from "@/components/Dialog.vue";
export default {
  components: {
    Dialog,
  },
  props: {
    master: Object,
  },
  data() {
    return {
      acceptRemove: false,
      month: [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ],
      weekDayShort: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вск"],
      weekDayLong: [
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота",
        "Воскресенье",
      ],
    };
  },
  methods: {
    ...mapActions({
      removeMasterClass: "removeMasterClass",
    }),
    acceptRemoveCard() {
      this.acceptRemove = true;
    },
    cancelRemoveCard() {
      this.acceptRemove = false;
    },
    removeCard(id) {
      this.removeMasterClass(id);
    },
    updateCard(data) {
      this.$emit("updateCard", data);
    },
    dateFormat(date) {
      const newDate = new Date(date);

      const daysText =
        this.weekDayLong[newDate.getDay() - 1 >= 0 ? newDate.getDay() - 1 : 6];

      const text = `${newDate.getDate()} ${
        this.month[newDate.getMonth()]
      } ${daysText}`;

      return text;
    },
    timeFormat(time) {
      return time.slice(0, 5);
    },
  },
  mounted() {
    this.weekDayLong = [
      this.$t("master-class_page.monday"),
      this.$t("master-class_page.tuesday"),
      this.$t("master-class_page.wednesday"),
      this.$t("master-class_page.thursday"),
      this.$t("master-class_page.friday"),
      this.$t("master-class_page.saturday"),
      this.$t("master-class_page.sunday"),
    ];
    this.weekDayShort = [
      this.$t("Course_card.monday_short"),
      this.$t("Course_card.tuesday_short"),
      this.$t("Course_card.wednesday_short"),
      this.$t("Course_card.thursday_short"),
      this.$t("Course_card.friday_short"),
      this.$t("Course_card.saturday_short"),
      this.$t("Course_card.sunday_short"),
    ];
    this.month = [
      this.$tc("Course_card.january", 2),
      this.$tc("Course_card.february", 2),
      this.$tc("Course_card.march", 2),
      this.$tc("Course_card.april", 2),
      this.$tc("Course_card.may", 2),
      this.$tc("Course_card.june", 2),
      this.$tc("Course_card.july", 2),
      this.$tc("Course_card.august", 2),
      this.$tc("Course_card.september", 2),
      this.$tc("Course_card.october", 2),
      this.$tc("Course_card.november", 2),
      this.$tc("Course_card.december", 2),
    ];
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
};
</script>
<style lang="scss" scoped>
.btn-list-config {
  margin-top: 16px;
}
</style>
<style lang="scss">
@import "@/assets/scss/grid/grid";
.mc__start {
  @include adaptivWidthLg(320, 160);
  width: 100%;
  // background: #fafafa;
  border: 1px solid #f2f2f2;
  text-align: center;
  @include adaptivMarginlg(5, 5, 0, 10, 2, 2, 0, 5);
  @include adaptivPaddinglg(5, 5, 10, 20, 5, 5, 5, 10);
  @media (max-width: 768px) {
    @include adaptivWidthmd(320, 140);
    max-width: 99%;
    @include adaptivPaddingmd(5, 5, 15, 20, 5, 5, 7, 10);
    @include adaptivMarginmd(5, 5, 5, 9, 2, 2, 5, 2);
  }
  &-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    &-speaker {
      @include adaptiv-fontlg(15, 8);
      font-family: "Inter";
      font-weight: 600;
      color: #303030;
      text-align: center;
      margin-bottom: 5px;
      @media (max-width: 768px) {
        @include adaptiv-fontmd(15, 8);
      }
      &-name {
        text-transform: uppercase;
      }
      span {
        margin: 0 2px;
      }
    }
    &-themeName {
      @include adaptiv-fontlg(15, 10);
      font-family: "Inter";
      font-weight: 600;
      color: green;
      text-transform: uppercase;
      text-align: center;
      margin: 5px 0 10px 0;
      @media (max-width: 768px) {
        @include adaptiv-fontmd(15, 8);
      }
    }
    &-seats {
      @include adaptiv-fontlg(15, 8);
      font-family: "Inter";
      font-weight: 600;
      color: #303030;
      text-align: center;
      margin-top: 5px;
      @media (max-width: 768px) {
        @include adaptiv-fontmd(15, 8);
      }
    }
    .branch {
      @include adaptiv-fontlg(15, 8);
      font-family: "Inter";
      font-weight: 600;
      color: #303030;
      text-transform: uppercase;
      text-align: center;
      margin: 5px 0;
      @media (max-width: 768px) {
        @include adaptiv-fontmd(14, 8);
      }
      &-place {
        text-transform: none;
      }
    }
    &-date {
      color: #cd0000;
      @include adaptiv-fontlg(18, 14);
      font-family: "Inter";
      font-weight: 600;
      text-transform: uppercase;
      margin-top: 15px;
      @media (max-width: 768px) {
        @include adaptiv-fontmd(22, 15);
      }
    }
  }
  .start-btn {
    @include adaptivWidthLg(120, 60);
    width: 100%;
    @include adaptivHeightlg(30, 20);
    @include adaptiv-fontlg(14, 8);
    @include adaptivMarginlg(5, 5, 25, 5, 2, 2, 10, 2);
    @media (max-width: 768px) {
      @include adaptivWidthmd(170, 120);
      @include adaptivHeightmd(50, 30);
      @include adaptiv-fontmd(22, 13);
      color: #303030;
    }
    &:hover {
      background: #303030;
      color: #fff;
      transition: 0.5s;
      border-radius: 4px;
    }
  }
}
.delete {
  text-align: center;
  padding: 16px;
}
</style>
