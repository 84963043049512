import Api from ".";

class MasterClasses extends Api {
  async getAllMasterClasses() {
    let response;
    
    try {
      response = await this.client.get('master-classes/all/');
    } catch (error) {
      response = error.response;
    }
    return response;
  }
  async addMasterClass(data){
    let response
    
    try{
      response = this.client.post('master-classes/add/', data)
    } catch (error) {
      response = error.response
    }
    return response
  }
  async removeMasterClass(data){
    let response
    
    try{
      response = await this.client.delete('master-classes/remove/', {params:{ID:data}})
    } catch (error){
      response = error.response
    }
    
    return response
  }
  async updateMasterClass(data){
    let response
    
    try{
      response = await this.client.patch('master-classes/update/', data)
    } catch (error){
      response = error.response
    }
    
    return response
  }
}

export default new MasterClasses();
