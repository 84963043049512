import Pusher from 'pusher-js';

class PusherApi{
    constructor(){
        this.pusher_client = new Pusher('39b5bda71bf735dbc3ce', {
            cluster: 'mt1'
        });
    }
    subscribe(callBack){
        this.channel = this.pusher_client.subscribe('launches')
        this.channel.bind('created', callBack)
        this.channel.bind('updated', callBack)
        this.channel.bind('deleted', callBack)
        this.channel.bind('archived', callBack)
    }

    unsubscribe(){
        this.pusher_client.unsubscribe('launches')
    }

}

export default new PusherApi()