import Banner from "@/api/banner";
const banners = {
  state: {
    banners_list: [],
  },
  getters: {
  },
  mutations: {
    setBanners(state, data) {
      state.banners_list = data;
    },

  },
  actions: {
    async getBanners(context) {
      const response = await Banner.getTeachersList();
      if (response.status == 200) {
        context.commit("setBanners", response.data);
      }
    },
  },
  modules: {},
};

export default banners;
