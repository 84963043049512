import MasterClasses from "@/api/master_classes";
const master_classes = {
  state: {
    master_classes: [],
  },
  mutations: {
    setMasterClasses(state, data) {
      state.master_classes = data;
    }
  },
  actions: {
    async getMasterClasses(context) {
      const response = await MasterClasses.getAllMasterClasses();
      if (response.status == 200) {


        context.commit("setMasterClasses", response.data);
      }
    },
    async addMasterClass(context, data) {
      const response = await MasterClasses.addMasterClass(data);
      if (response.status == 200) {
        context.commit("setMasterClasses", response.data);
      }
      return response;
    },
    async removeMasterClass(context, data) {
      const response = await MasterClasses.removeMasterClass(data);
      if (response.status == 200) {
        context.commit("setMasterClasses", response.data);
      }
      return response;
    },
    async updateMasterClass(context, data) {
      const response = await MasterClasses.updateMasterClass(data);
      if (response.status == 200) {
        context.commit("setMasterClasses", response.data);
      }
      return response;
    },
  },
  modules: {},
};

export default master_classes;
