<template>
  <div class="update">
    <p>{{ $t("navigation.whats_new") }}:</p>
    <ul class="list_info">
      <li v-ripple>
        Кол-во оплативщих:<br />
        На дате запуска показывает кол-во оплативших
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  unmounted() {
    location.reload();
  },
};
</script>

<style lang="scss">
.update {
  padding-bottom: 16px;
  p {
    padding: 16px;
  }
}
</style>
