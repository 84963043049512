<template>
  <ul
    class="calendar__list"
    :style="{ background: color + '66' }"
    :class="{ mini: mini }"
  >
    <li
      v-ripple
      class="calendar__item"
      v-for="(day, index) in weekDayShort"
      :key="index"
      :style="{ color: colorText }"
      :class="actvieClass[index]"
      @click="$emit('change', index)"
    >
      {{ day }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    day: Array,
    color: {
      type: String,
      default: "#53acff",
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actvieClass: [],
      weekDayShort: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вск"],
      colorText: "white",
    };
  },
  watch: {
    day: {
      deep: true,
      handler() {
        this.setActiveDayCalendar();
      },
    },
    color() {
      this.colorText = this.getContrastYIQ(this.color);
      this.color7 = this.color + "b3";
    },
  },
  created() {
    this.setActiveDayCalendar(), (this.color7 = this.color + "b3");
    this.colorText = this.getContrastYIQ(this.color);
  },
  mounted() {
    this.weekDayShort = [
      this.$t("Course_card.monday_short"),
      this.$t("Course_card.tuesday_short"),
      this.$t("Course_card.wednesday_short"),
      this.$t("Course_card.thursday_short"),
      this.$t("Course_card.friday_short"),
      this.$t("Course_card.saturday_short"),
      this.$t("Course_card.sunday_short"),
    ];
  },
  methods: {
    getContrastYIQ(hexcolor) {
      const r = parseInt(hexcolor.substring(1, 3), 16);
      const g = parseInt(hexcolor.substring(3, 5), 16);
      const b = parseInt(hexcolor.substring(5, 7), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "black" : "white";
    },
    setActiveDayCalendar() {
      const dayJson = JSON.parse(JSON.stringify(this.day));
      const day = dayJson.sort((a, b) => a.day - b.day);
      const daysArray = [];
      for (let i = 0; i < 7; i++) {
        const searchDay = day.find((a) => a.day === i);
        const classDay = [];
        if (searchDay != undefined) {
          classDay.push("active");
          const classNext = this.findNextDay(i);
          if (classNext != false) {
            classDay.push(classNext);
          }
        } else {
          classDay.push("");
        }
        daysArray.push(classDay.join(" "));
      }
      this.actvieClass = daysArray;
    },
    findNextDay(day) {
      const dayJson = JSON.parse(JSON.stringify(this.day));
      const days = dayJson.sort((a, b) => a.day - b.day);
      const nextDay = days.find((a) => a.day === day + 1);
      const prevDay = days.find((a) => a.day === day - 1);

      if (nextDay != undefined && prevDay != undefined) {
        return "center";
      } else if (nextDay == undefined && prevDay != undefined) {
        return "end";
      } else if (nextDay != undefined && prevDay == undefined) {
        return "start";
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.day {
  &.calendar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    margin-bottom: 10px;
  }

  h2 {
    color: white;
  }
}

.calendar {
  $parent: &;

  &__list {
    display: flex;

    &.mini {
      #{$parent}__item {
        min-width: 30px;
        height: 30px;
        padding: 5px;
        margin: 0;
      }
    }
  }

  &__item {
    color: v-bind(color);
    width: max-content;
    padding: 10px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    transition: 500ms linear, margin-right 300ms, border-radius 300ms;
    margin-right: 10px;

    &.active {
      color: white;
      background: v-bind(color);

      &.start {
        border-radius: 50px 0 0 50px;
        margin-right: 0px;
      }

      &.center {
        border-radius: 0;
        margin-right: 0px;
      }

      &.end {
        border-radius: 0 50px 50px 0;
      }

      &:hover {
        background: v-bind(color7);
      }
    }

    &:hover {
      background: rgba($color: white, $alpha: 0.1);
    }
  }
}
</style>
