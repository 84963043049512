import axios from "axios";
// import jwt_decode from "jwt-decode";
class Api {
  constructor() {
    // создаем базовыый экзепляр axios
    this.client = axios.create({
      // baseURL: "/api/",
      // baseURL: "http://192.168.2.22:8000/api/",
      // baseURL: "http://127.0.0.1:8000/api/",
      baseURL: "https://api.proweb.uz/api/",
    });

    this.authUrl = {
      auth: "auth/token/",
      refresh: "auth/token/refresh/",
    };
    this.accessTokenLifetime = 300

    // Устанавливаем перехватчик чтобы добавлять TOKEN в Header
    this.client.interceptors.request.use(
      async (config) => {
        if (
          config.url != this.authUrl.auth &&
          config.url != this.authUrl.refresh
        ) {
          const accessToken = await this.getAccessToken();
          if (accessToken) {
            config.headers.common["Authorization"] = `Bearer ${accessToken}`;
          }
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  // Записываем токен в local storage
  setRefreshTokenStorage(token) {
    window.localStorage.setItem("token", JSON.stringify(token));
  }
  setAccessTokenStorage(token) {
    const current_time = new Date().getTime()
    // const decodeToken = jwt_decode(token)
    const token_data = {
      token: token,
      receivingTime: current_time
    };
    window.sessionStorage.setItem("token", JSON.stringify(token_data));
  }
  // достаем токен из local storage
  getRefreshTokenStorage() {
    const token = window.localStorage.getItem("token");

    if (!token) {
      return false;
    }
    return JSON.parse(token);
  }
  getAccessTokenStorage() {
    const token = window.sessionStorage.getItem("token");

    if (!token) {
      return false;
    }
    return JSON.parse(token);
  }
  setToken(tokens) {
    this.setRefreshTokenStorage(tokens.refresh);
    this.setAccessTokenStorage(tokens.access);
  }
  // удаляем токен из local storage
  removeRefreshTokenStorage() {
    window.localStorage.removeItem("token");
    window.sessionStorage.removeItem("token");
  }
  // Обновляем токен если local storage пустой возврашаем False
  async refreshAccesToken() {
    let response;
    const refreshToken = this.getRefreshTokenStorage();

    if (!refreshToken) {
      return false;
    }

    try {
      response = await this.client.post(this.authUrl.refresh, {
        refresh: refreshToken,
      });
    } catch (error) {
      response = error.response;
    }

    if (response.status == 200) {
      this.setToken(response.data);
      return response;
    } else {
      return response;
    }
  }

  async getAccessToken() {
    const token_data = this.getAccessTokenStorage();
    if (!token_data) {
      return false;
    }
    let accessToken = token_data.token;

    const current_time = new Date().getTime();
    
    if (this.accessTokenLifetime - 2 < (current_time - token_data.receivingTime) / 1000) {
      const newToken = await this.refreshAccesToken();
      if (!newToken) {
        return false;
      }
      accessToken = newToken.data.access;
    }

    return accessToken;
  }
}

export default Api;
