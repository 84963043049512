<template>
  <div class="ads__list-config">
    <div class="ads__list-language">
      <span :class="{ active: lang == 'ru' }" @click="lang = 'ru'">RU</span>
      <span :class="{ active: lang == 'uz' }" @click="lang = 'uz'">UZ</span>
    </div>
    <div class="ads__list-itemelement">
      <span v-if="banner.is_active">
        <span class="active">Активен</span>
        <span class="banner_content-num">
          {{ txt.ru }} {{ time.day }} {{ day.ru }} {{ time.hour }}:{{
            time.minute
          }}:{{ time.second }}
        </span>
      </span>
      <span v-else><span class="not-active">Не активен</span></span>
      <div class="ads__list-item" @click="openModal = true">
        <img
          class="ads__list-banner"
          :src="'https://api.proweb.uz' + banner.image"
          alt=""
        />
        <div class="ads__list-body">
          <div class="ads__list-content">
            <div class="ads__list-lang" :class="{ dark: banner.is_darkmode }">
              <h2>{{ banner[`title_${lang}`] }}</h2>
              <p>{{ banner[`content_${lang}`] }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Teleport to="#dialog">
    <Transition name="slide-fade">
      <Dialog v-if="openModal" :width="600" @close="openModal = false">
        <template v-slot:title> Редактировать баннер </template>
        <template v-slot:body>
          <AdsModal :edit="true" :item="banner" />
        </template>
      </Dialog>
    </Transition>
  </Teleport>
</template>

<script>
import AdsModal from "@/components/AdsModal";
export default {
  components: {
    AdsModal,
  },
  props: {
    banner: Object,
  },
  data() {
    return {
      lang: "ru",
      txt: { ru: "до конца", uz: `oxirigacha ` },
      time: this.timerRemainder(),
      openModal: false,
    };
  },
  computed: {
    day() {
      const day = this.timerRemainder().day;

      if (
        day.slice(-1) > 1 &&
        day.slice(-1) < 5 &&
        (day.slice(-2) < 11 || day.slice(-2) > 20)
      ) {
        return { ru: "дня", uz: `kun` };
      } else if (
        day.slice(-1) == 1 &&
        (day.slice(-2) < 11 || day.slice(-2) > 20)
      ) {
        return { ru: "день", uz: `kun` };
      } else {
        return { ru: "дней", uz: `kun` };
      }
    },
  },
  methods: {
    timerRemainder() {
      const currentTime = new Date().getTime();
      // const endTime = new Date("2021-11-29 12:00:00")
      const endTime = this.banner.end_date * 1000;
      let diff = endTime - currentTime;
      let day = Math.floor(diff / (24 * 60 * 60 * 1000)).toString();
      let hour = Math.floor(
        (diff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
      ).toString();
      let minute = Math.floor(
        ((diff % (24 * 60 * 60 * 1000)) % (60 * 60 * 1000)) / (60 * 1000)
      ).toString();
      let second = Math.floor(
        (((diff % (24 * 60 * 60 * 1000)) % (60 * 60 * 1000)) % (60 * 1000)) /
          1000
      ).toString();
      day = day > 0 ? day.toString() : "0";
      hour = hour > 0 ? hour.toString() : "0";

      minute =
        minute > 0
          ? minute < 10
            ? "0" + minute.toString()
            : minute.toString()
          : "00";
      second =
        second > 0
          ? second < 10
            ? "0" + second.toString()
            : second.toString()
          : "00";
      hour =
        hour > 0 ? (hour < 10 ? "0" + hour.toString() : hour.toString()) : "00";
      return { day, hour, minute, second };
    },
    timerUpdate() {
      setTimeout(() => {
        this.time = this.timerRemainder();
        if (this.term()) {
          this.timerUpdate();
        }
      }, 1000);
    },
    term() {
      const currentTime = new Date().getTime();
      return (
        currentTime > this.banner.start_date * 1000 &&
        currentTime < this.banner.end_date * 1000
      );
    },
  },
  mounted() {
    if (this.term()) {
      this.timerUpdate();
    }
  },
};
</script>

<style lang="scss">
@keyframes active {
  0% {
    background: green;
  }
  50% {
    background: transparent;
  }
  100% {
    background: green;
  }
}

.ads__list {
  &-config {
    display: flex;
    gap: 20px;
    align-items: flex-end;
  }
  &-language {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white;
    span {
      padding: 5px;
      cursor: pointer;
      border-radius: 6px;
      transition: 200ms;
      user-select: none;
      &:hover {
        background: rgba($color: white, $alpha: 0.1);
      }
      &.active {
        background: white;
        color: #000;
      }
    }
  }
  &-item {
    transition: 200ms;
    &:hover {
      transform: scale(0.95);
      opacity: 0.8;
    }
    &element {
      color: white;
      span {
        cursor: default;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .active {
          &::before {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background: green;
            margin-right: 10px;
            border-radius: 50%;
            animation: active 1s linear infinite;
          }
        }
        .not-active {
          &::before {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background: red;
            margin-right: 10px;
            border-radius: 50%;
          }
        }
      }
    }
    border-radius: 10px;
    overflow: hidden;
    background: white;
    cursor: pointer;
    position: relative;
    max-width: 535px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-body {
    color: #000000;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    width: 100%;
    padding: 20px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &-banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  &-lang {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #151515;
    width: 78%;
    &.dark {
      color: #ffffff;
    }
    h2 {
      text-transform: uppercase;
      line-height: 30px;
      font-size: 26px;
      font-family: "Inter", sans-serif;
      font-weight: 700;
      text-align: right;
    }
  }
}
</style>