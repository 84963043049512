<template>
  <TransitionGroup name="list" tag="div" class="snackbars content">
    <SnackbarItem
      v-for="item in getSnacks"
      mode="out-in"
      :key="item"
      :data="item"
    />
    <button
      v-ripple:white
      class="snackbars__clear"
      v-if="getSnacks.length"
      @click="claer"
    >
      <img src="@/assets/images/clear.svg" alt="" />{{
        $t("snackbar.delete_all")
      }}
    </button>
  </TransitionGroup>
</template>

<script>
import { mapGetters } from "vuex";
import SnackbarItem from "@/components/Snackbar/SnackbarItem";
export default {
  components: {
    SnackbarItem,
  },
  computed: {
    ...mapGetters({
      getSnacks: "getSnacks",
    }),
  },
  methods: {
    claer() {
      this.$store.dispatch("removeAllSnack");
    },
  },
};
</script>

<style lang="scss">
.snackbars {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  align-items: flex-end;
  &__clear {
    position: absolute;
    left: -150px;
    background: rgba(47, 48, 53, 0.7);
    backdrop-filter: blur(20px);
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    border: none;
    outline: none;
    border-radius: 100px;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #bcceff;
    transition: 200ms;
    display: flex;
    gap: 10px;
    align-items: center;
    user-select: none;
    &:hover {
      background: rgba(47, 48, 53, 0.6);
    }
  }
  &.content {
    padding: 16px;
  }
  &__item {
    display: flex;
    gap: 10px;
    flex-direction: column;
    min-width: 320px;
    max-width: 400px;
    width: max-content;
    padding: 14px 16px;
    cursor: pointer;
    user-select: none;
    background: rgba(47, 48, 53, 0.6);
    backdrop-filter: blur(20px);
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    color: #eff1f7;
    font-size: 14px;
    &-extra {
      position: relative;
      width: 60%;
      margin-top: 10px;
    }
    &-img {
      width: 100%;
      height: 50%;
      display: block;
      border-radius: 16px;
    }
    &-text {
      position: absolute;
      border-radius: 15px;
      background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8));
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      font-size: 16px;
      padding: 10px;
      display: flex;
      align-items: flex-end;
      &.no_img {
        position: relative;
        height: auto;
        background: #46474c;
        border-radius: 4px;
      }
    }
    &-title {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
    &-actions {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }
    &-btn {
      background: transparent;
      border: none;
      outline: none;
      border-radius: 100px;
      padding: 10px 12px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #bcceff;
      transition: 200ms;
      &:hover {
        background: rgba(188, 209, 255, 0.08);
      }
    }
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease-in-out;
}
.list-move {
  transition: all 0.4s ease-in-out;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(50%) rotateY(45deg);
}
.list-leave-active {
  position: absolute;
}

.snackbars__clear.list-enter-active,
.snackbars__clear.list-leave-active {
  transition: all 0.2s ease-in-out;
}

.snackbars__clear.list-leave-to {
  transform: scale(0.9);
}

.snackbars__clear.list-enter-from {
  transform: scale(0.9) translateY(50px);
}
</style>
