import functions from '@/functions.js'
import { Api } from '../../api/fetch'
import translate from '../../translate.json'

let notyKeys = {
    notyCopy: {
        name: translate.settings_modal.notifications__copy_title,
        desc: translate.settings_modal.notifications__copy__desc,
        view: true,
        img: 'copy'
    },
    notySettings: {
        name: translate.settings_modal.notifications__settings__title,
        desc: translate.settings_modal.notifications__copy__desc,
        view: true
    },
}

const state = {
    noty: {}
}
const mutations = {
    loadnoty(state, data) {
        state.noty = data
    }
}
const actions = {
    async loadnoty({ commit }, reset = false) {
        if(reset) {
            localStorage.removeItem('noty')
            commit('loadnoty', {})
        }
        const api = new Api()
        let activeLang = localStorage.activeLang
        const db = await api.select(activeLang)    
        const noty = localStorage.noty ?? '{}'
        const notyObject = JSON.parse(noty)

        if(db){
            notyKeys = {
                notyCopy: {
                    name: db.result.settings_modal.notifications__copy_title,
                    desc: db.result.settings_modal.notifications__copy__desc,
                    view: true,
                    img: 'copy'
                },
                notySettings: {
                    name: db.result.settings_modal.notifications__settings__title,
                    desc: db.result.settings_modal.notifications__copy__desc,
                    view: true
                },
            }
        }

        for(const key in notyKeys) {
            const isExists = functions.getSettingKey(notyObject, key, false)
            if (isExists != null) {
                notyObject[key] = {
                    name: notyKeys[key].name,
                    desc: notyKeys[key].desc,
                    view: notyObject[key].view,
                } 
            }else {
                notyObject[key] = notyKeys[key]
            }
        }
        functions.setStorage(notyObject, 'noty')
        

        commit('loadnoty', notyObject)
    },
    updatenoty({ commit, state }, data) {
        const noty = state.noty
        noty[data.key].view = !!data.value
        functions.setStorage(noty, 'noty')
        commit('loadnoty', noty)
    }
}
const getters = {
    getnoty(state) {
        return state.noty
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}