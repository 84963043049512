import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      noty: (state) => state.noty.noty,
    }),
  },
  methods: {
    getNextDay(lastDay) {
      let thisDay = this.day_less.indexOf(lastDay);
      if (thisDay < this.day_less.length - 1) {
        thisDay++;
        return this.day_less[thisDay];
      } else if (thisDay == this.day_less.length - 1) {
        return this.day_less[0];
      } else return null;
    },
    getVersionApp() {
      return localStorage.versionApp
    },
    calculateDay(current, next) {
      if (current < next) {
        return next - current;
      } else {
        return 7 - current + next;
      }
    },
    generateDate(date) {
      const lastElDay = new Date(date).getDay();
      const nextDay = this.getNextDay(lastElDay);
      const calculateDay = this.calculateDay(lastElDay, nextDay);
      const numData = new Date(date);
      const getDate = numData.getDate();
      const getMonth = numData.getMonth();
      const getFullYear = numData.getFullYear();
      return new Date(getFullYear, getMonth, getDate + calculateDay);
    },
    getNewDate(canceledLessons, newDate) {
      let find = false;
      for (const key in canceledLessons) {
        const numData = new Date(canceledLessons[key].day);
        const getDate = numData.getDate();
        const getMonth = numData.getMonth();
        const getFullYear = numData.getFullYear();
        const current = new Date(getFullYear, getMonth, getDate);
        find = newDate.toString() === current.toString();
        if (newDate.toString() === current.toString()) {
          this.active_holidays.push(canceledLessons[key]);
          break;
        }
      }
      if (find) {
        const newdate = this.generateDate(newDate);
        return this.getNewDate(canceledLessons, newdate);
      } else {
        return newDate;
      }
    },
    async delay(time) {
      return await new Promise((resolve) => {
        setTimeout(resolve, time);
      });
    },
    getActiveDay(date) {
      return new Date(date).getDay() - 1 < 0 ? 6 : new Date(date).getDay() - 1;
    },
    getDayLess(date) {
      return date.map((a) => this.weekDayShort[a.day]).join("-");
    },
    scrollX(e) {
      e.preventDefault();
      this.$refs["scroll_container"].scrollLeft += e.deltaY;
    },
    declination(number, arr) {
      const titles = arr ? arr : [" год", " года", " лет"];
      const cases = [2, 0, 1, 1, 1, 2];
      return (
        number +
        titles[
          number % 100 > 4 && number % 100 < 20
            ? 2
            : cases[
                number % 10 < 5
                  ? number % 10 > 1 && number % 10 < 2
                    ? 2
                    : number % 10
                  : 5
              ]
        ]
      );
    },
    numberSetting(double_lesson, index, is_internship, lost) {
      const data = {};
      const count_block = is_internship ? 12 : 8;
      if (double_lesson && !is_internship) {
        data.block =
          Math.ceil(((index + 1) * 2) / count_block) == 0
            ? 1
            : Math.ceil(((index + 1) * 2) / count_block);
        const less_number =
          ((index + 1) * 2) % count_block == 0
            ? count_block
            : ((index + 1) * 2) % count_block;
        data.less = `${less_number - 1}-${less_number}`;
        data.class =
          index > 0 && index % (count_block / 2) == count_block / 2 - 1;
      } else {
        data.block =
          Math.ceil((index + 1) / count_block) == 0
            ? 1
            : Math.ceil((index + 1) / count_block);
        data.less =
          (index + 1) % count_block == 0
            ? count_block
            : (index + 1) % count_block;
        data.class = index > 0 && index % count_block == count_block - 1;
      }
      if (lost) {
        const date_less = new Date(lost).getTime() + 86400 * 1000;
        data.lost = date_less < Date.now();
      }
      return data;
    },
    async showLessonsGenerate(start, load = true) {
      let holidays_view = [
        {
          day: "05-09",
          title: "День памяти и почести",
        },
        {
          day: "03-08",
          title: "Международный женский день",
        },
        {
          day: "03-21",
          title: "Навруз",
        },
        {
          day: "10-01",
          title: "День учителя",
        },
        {
          day: "12-31",
          title: "Новый год",
        },
        {
          day: "01-01",
          title: "Новый год",
        },
        {
          day: "01-02",
          title: "Новый год",
        },
        {
          day: "12-08",
          title: "День конституции",
        },
        {
          day: "09-01",
          title: "День независимости",
        },
      ];
      this.lesson_data_generate = [];
      this.active_holidays = [];
      const randomDelay = Math.random() * (5000 - 1000) + 1000;
      if (load) {
        await this.delay(randomDelay);
      }
      const days = JSON.stringify(start.day);
      this.day_less = JSON.parse(days).map((a) => {
        let day = Number(a.day);
        if (day == 6) {
          return 0;
        } else {
          return ++day;
        }
      });
      const holidays = [];
      holidays_view.forEach((holiday) => {
        const holiday_json = JSON.stringify(holiday);
        const holiday_data = JSON.parse(holiday_json);
        const year = new Date().getFullYear();
        holiday_data.day = `${year}-${holiday_data.day}`;
        holidays.push(holiday_data);
        holiday.day = `${year + 1}-${holiday.day}`;
        holidays.push(holiday);
      });
      holidays_view = holidays;
      const lesson = new Date(start.date).toString();
      this.lesson_data_generate.push(lesson);
      const lessonCountCourse = start.is_internship ? 12 : this.lessonCount;
      const lessonCount = start.double_lesson && !start.is_internship
        ? lessonCountCourse / 2
        : lessonCountCourse;
      for (let index = 0; index < lessonCount - 1; index++) {
        const oldDate =
          this.lesson_data_generate[this.lesson_data_generate.length - 1];
        let newDate = this.generateDate(oldDate);
        newDate = this.getNewDate(holidays_view, newDate);
        this.lesson_data_generate.push(newDate);
      }
      const oldDate =
        this.lesson_data_generate[this.lesson_data_generate.length - 1];
      this.lesson_data_string = this.startDateFormat(
        oldDate.toString(),
        [],
        "",
        false,
        true
      ).toUpperCase();
      this.countLess = lessonCountCourse;
      this.block = start.is_internship ? 12 : 8;
    },
    startDateFormat(date, day, time = "", format = false, year = false) {
      const newDate = new Date(date);
      if (!format) {
        const daysText = day
          .map((item) => {
            return this.weekDayShort[item.day];
          })
          .join(" - ");

        const text = `${newDate.getDate()} ${this.month[newDate.getMonth()]}${
          year ? " " + newDate.getFullYear() : ""
        } ${daysText}`;
        return text;
      } else {
        const daysText = day
          .map((item) => {
            return this.weekDayShort[item.day];
          })
          .join("-");

        const text = `${this.strDate(newDate.getDate())}.${this.strDate(
          newDate.getMonth() + 1
        )}.${this.strDate(newDate.getFullYear())} ${time} ${daysText}`;

        return text;
      }
    },
    copyEll(text, extra) {
      if (window.isSecureContext) {
        navigator.clipboard.writeText(text).then(
          () => {
            if (this.noty.notyCopy.view) {
              const copy = {
                title: "Копирование",
                content: extra?.content ?? "Копирование информации завершено",
                icon: "copy",
              };
              if (extra) {
                copy.extra = extra;
              }
              this.$store.dispatch("addSnack", copy);
            }
          },
          () => {
            if (this.noty.notyCopy.view) {
              this.$store.dispatch("addSnack", {
                title: "Копирование",
                content: "Ошибка копирования информации",
              });
            }
          }
        );
      } else {
        if (this.noty.notyCopy.view) {
          this.$store.dispatch("addSnack", {
            title: "Копирование",
            content: "Копирование запрещено браузером",
          });
        }
      }
    },
  },
};
