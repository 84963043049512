<template>
  <div class="teachers">
    <!-- {{ courses_teacher }} -->
    <ul class="teachers__list" ref="scroll_container" @mousewheel="scrollX">
      <li
        class="teachers__list-item"
        v-for="(key, index) in courses_teacher"
        :key="index"
        :class="{ active: activeCourse == index }"
      >
        <div class="content" v-ripple @click="activeCourse = index">
          {{ index }} <span>{{ key.count }}</span>
        </div>
      </li>
    </ul>
    <div class="teachers__teachers">
      <div class="teachers__teachers-course">
        <TransitionGroup name="slide-fade-teacher" mode="in-out">
          <div
            class="teachers__teachers-list"
            v-for="(key, index) in courses_teacher"
            :key="index"
            v-show="activeCourse == index"
          >
            <div v-for="(language, lang) in key" :key="lang">
              <div
                class="teachers__container"
                v-if="lang != 'count' && language.length"
              >
                <div class="teachers__header">
                  {{
                    lang == "ru"
                      ? $t("teachers_page.in_ru_language")
                      : $t("teachers_page.in_uz_language")
                  }}
                  <span>{{ language.length }}</span>
                  {{ $t("teachers_page.teacher_count") }}
                </div>
                <div v-for="(teacher, i) in language" :key="i">
                  <ListTileTeacher
                    :teacher="teacher"
                    v-ripple
                    @click="teacherActive = teacher.id"
                  />
                </div>
              </div>
            </div>
          </div>
        </TransitionGroup>
      </div>
    </div>
  </div>
  <Teleport to="#dialog">
    <Transition name="slide-fade">
      <Dialog
        v-if="teacherActive != -1"
        :width="600"
        @close="teacherActive = -1"
      >
        <template v-slot:title>{{ $t("teachers_page.modal__title") }}</template>
        <template v-slot:body>
          <TeacherModal :teacher="teacherActive" />
        </template>
      </Dialog>
    </Transition>
  </Teleport>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ListTileTeacher from "@/components/ListTileTeacher.vue";
import TeacherModal from "@/components/TeacherModal.vue";
export default {
  data() {
    return {
      activeCourse: "",
      teacherActive: -1,
    };
  },
  components: { ListTileTeacher, TeacherModal },
  methods: {
    ...mapActions({
      getLaunches: "getLaunches",
      getTeachers: "getTeachers",
      getBranches: "getBranches",
    }),
    scrollX(e) {
      e.preventDefault();
      this.$refs["scroll_container"].scrollLeft += e.deltaY;
    },
  },
  mounted() {
    this.getBranches();
    this.getLaunches();
  },

  computed: {
    ...mapState({
      courses_teacher: function (state) {
        const teachers = JSON.parse(
          JSON.stringify(state.teachers.teachers_list)
        );
        const courses = JSON.parse(JSON.stringify(state.launches.launches));

        const courses_teachers = {};
        courses.forEach((element) => {
          teachers.forEach((teach) => {
            if (!courses_teachers[element.name]) {
              courses_teachers[element.name] = { ru: [], uz: [], count: 0 };
            }
            if (teach.course == element.id) {
              if (teach.active) {
                courses_teachers[element.name][teach.language].push(teach);
                courses_teachers[element.name].count++;
              }
            }
          });
        });
        for (const key in courses_teachers) {
          if (courses_teachers[key].count == 0) {
            delete courses_teachers[key];
          }
        }
        this.activeCourse = Object.keys(courses_teachers)[0];
        return courses_teachers;
      },
    }),
  },
};
</script>

<style lang="scss">
.teachers {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  overflow: hidden;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .teacher__header {
      background: #575757;
      color: white;
    }

    .teachers__teachers-list:hover {
      background: #ffffff;
    }
  }

  &__header {
    color: #292929;
    text-transform: uppercase;
    background: white;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    span {
      width: 30px;
      height: 30px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background: #292929;
      border-radius: 50%;
      cursor: default;
      user-select: none;
    }
  }

  &__list {
    display: inline-flex;
    position: sticky;
    gap: 10px;
    top: 0;
    cursor: pointer;
    white-space: nowrap;
    overflow: auto;
    padding: 5px 15px;

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: rgba($color: #ffffff, $alpha: 0.1);
      }
    }

    &::-webkit-scrollbar {
      width: 2px;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba($color: #ffffff, $alpha: 0);
      border-radius: 10px;

      &:hover {
        background: rgba($color: #ffffff, $alpha: 0.2);
      }
    }

    &-item {
      display: inline-flex;
      color: rgb(180, 180, 180);
      transition: all 200ms;
      align-items: center;
      border-radius: 4px;
      gap: 10px;
      position: relative;
      justify-content: center;
      user-select: none;

      &::before {
        content: "";
        height: 0px;
        background: transparent;
        position: absolute;
        display: block;
        bottom: 0;
        width: 0%;
        border-radius: 4px 4px 0 0;
        transition: 200ms;
      }

      &.active {
        color: white;

        &::before {
          background: #fff;
          height: 4px;
          width: 80%;
        }
      }

      &:hover {
        background: rgba($color: #ffffff, $alpha: 0.1);
      }

      .content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        position: relative;
        padding: 10px 15px;
      }

      span {
        background: #fff;
        padding: 5px;
        font-size: 12px;
        color: #292929;
        font-weight: bold;
        border-radius: 100px;
        transition: 500ms;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__teachers-list {
    opacity: 1;
    display: grid;
    max-width: 1200px;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 10px;

    .teacher__header {
      cursor: pointer;
      transition: 200ms;
      border-radius: 10px;

      &:hover {
        background: rgba($color: #ffffff, $alpha: 0.1);
      }
    }
  }
}
</style>
