import Api from ".";
import jwt_decode from "jwt-decode";
class Auth extends Api {
  //авторизация
  async login(userData) {
    let response;
    try {
      response = await this.client.post(this.authUrl.auth, userData);
    } catch (error) {
      response = error.response;
    }

    if (response.status == 200) {
      this.setToken(response.data)
      return {
        status: response.status,
        userData: jwt_decode(response.data.access),
      };
    } else {
      return { status: response.status, userData: {} };
    }
  }
  //Проверяем авторизован ли
  async isAuthorized() {
    const response = await this.refreshAccesToken();
    if (!response) {
      return false;
    }
    if (response.status == 200) {
      return {
        status: response.status,
        userData: jwt_decode(response.data.access),
      };
    } else {
      return { status: response.status, userData: {} };
    }
  }
  // выход из аккаунта
  logout() {
      this.removeRefreshTokenStorage()
  }
}

export default new Auth();
