<template>
  <div class="teacher" ref="teacher">
    <transition-group name="fade" tag="div" class="notifications">
      <div
        class="notifications__item"
        v-for="key in notyfications"
        :key="key.id"
      >
        <p>{{ key.text }}</p>
      </div>
    </transition-group>
    <ListTileTeacher :teacher="teacherInfo" :visibility="true" />
    <div class="teacher__body">
      <ul class="list_info">
        <li>
          <img src="@/assets/images/phone.svg" alt="" />
          <div>
            <div>{{ $t("teachers_page.modal__phone_number") }}</div>
            <div>{{ teacherInfo.contact }}</div>
          </div>
          <button v-if="teacherInfo.contact" v-ripple @click="copyPh">
            <img src="@/assets/images/copy.svg" alt="" />
          </button>
        </li>
        <li v-if="teacherInfo.username">
          <img src="@/assets/images/telega.svg" alt="" />
          <div>
            <div>{{ $t("teachers_page.telegram") }} username</div>
            <div v-if="teacherInfo.username">@{{ teacherInfo.username }}</div>
            <div v-else>{{ $t("teachers_page.unknown") }}</div>
          </div>
          <button v-if="teacherInfo.username" v-ripple @click="copyTg">
            <img src="@/assets/images/copy.svg" alt="" />
          </button>
        </li>
        <li v-if="teacherInfo.username">
          <img src="@/assets/images/telega.svg" alt="" />
          <div>
            <div>{{ $t("teachers_page.modal__email") }}</div>
            <div v-if="teacherInfo.email">{{ teacherInfo.email }}</div>
            <div v-else>{{ $t("teachers_page.unknown") }}</div>
          </div>
          <button v-if="teacherInfo.username" v-ripple @click="copyEmail">
            <img src="@/assets/images/copy.svg" alt="" />
          </button>
        </li>
        <li>
          <img src="@/assets/images/work.svg" alt="" />
          <div>
            <div>{{ $t("teachers_page.modal__direction") }}</div>
            <div>{{ courses_list }}</div>
          </div>
          <button v-ripple @click="copyDir">
            <img src="@/assets/images/copy.svg" alt="" />
          </button>
        </li>
        <li>
          <img src="@/assets/images/calendar.svg" alt="" />
          <div>
            <div>{{ $t("teachers_page.modal__experience") }}</div>
            <div v-if="teacherInfo.working_time_in_proweb">
              {{
                functions.calculate_age(teacherInfo.working_time_in_proweb, [
                  " " + $t("teachers_page.modal__one_year"),
                  " " + $t("teachers_page.modal__year"),
                  " " + $t("teachers_page.modal__years"),
                ])
              }}
            </div>
            <div v-else>{{ $t("teachers_page.unknown") }}</div>
          </div>
          <button
            v-if="teacherInfo.working_time_in_proweb"
            v-ripple
            @click="copyWorkProweb"
          >
            <img src="@/assets/images/copy.svg" alt="" />
          </button>
        </li>
        <li>
          <img src="@/assets/images/groups.svg" alt="" />
          <div>
            <div>{{ $t("teachers_page.modal__released") }}</div>
            <div v-if="teacherInfo.released_groups">
              {{ teacherInfo.released_groups }}
              {{ $t("teachers_page.student") }}
            </div>
            <div v-else>{{ $t("teachers_page.unknown") }}</div>
          </div>
          <button
            v-if="teacherInfo.released_groups"
            v-ripple
            @click="copyStudent"
          >
            <img src="@/assets/images/copy.svg" alt="" />
          </button>
        </li>

        <!-- <li
          v-if="
            teacherInfo.link_to_tutorial_on_youtube &&
            teacherInfo.link_to_tutorial_on_youtube.length > 0
          "
        >
          <img src="@/assets/images/youtube.svg" alt="" />
          <div>
            <div>Видео открытого урока</div>
            <a
              v-if="teacherInfo.link_to_tutorial_on_youtube"
              :href="teacherInfo.link_to_tutorial_on_youtube"
              v-ripple
              @click="openLink(teacherInfo.link_to_tutorial_on_youtube)"
              >YouTube</a
            >
            <div v-else>Нет ссылки</div>
          </div>
          <button
            v-if="teacherInfo.link_to_tutorial_on_youtube"
            v-ripple
            @click="copyYouTube"
          >
            <img src="@/assets/images/copy.svg" alt="" />
          </button>
        </li> -->

        <li v-if="teacherInfo.teacher_links.length">
          <img src="@/assets/images/youtube.svg" alt="" />
          <div>
            <div>{{ $t("teachers_page.modal__open_lesson_video") }}</div>
            <div class="link-wrapper" v-if="teacherInfo.teacher_links">
              <div v-for="(item, idx) in teacherInfo.teacher_links" :key="item">
                <a
                  target="_blank"
                  :href="teacherInfo.teacher_links[idx].link"
                  v-ripple
                  @click="openLink(teacherInfo.teacher_links[idx].link)"
                  >{{
                    localCourses.find(
                      (val) => val.id === teacherInfo.teacher_links[idx].course
                    ).name
                  }}
                  <!-- {{
                    courses.find(
                      (val) => val.id === teacherInfo.teacher_links[idx].course
                    ).name
                  }} -->
                </a>
              </div>
            </div>
            <div v-else>{{ $t("teachers_page.modal__video_not_found") }}</div>
          </div>
          <button
            v-if="teacherInfo.link_to_tutorial_on_youtube"
            v-ripple
            @click="copyYouTube"
          >
            <img src="@/assets/images/copy.svg" alt="" />
          </button>
        </li>
        <li>
          <img src="@/assets/images/teacher.svg" alt="" />
          <div>
            <div>{{ $t("teachers_page.modal__place_of_education") }}</div>
            <div v-if="teacherInfo.learningplaces.length">
              <div
                v-for="(key, index) in teacherInfo.learningplaces"
                :key="index"
              >
                {{ index + 1 }}. {{ key.name }}
              </div>
            </div>
            <div v-else>
              {{ $t("teachers_page.uneducated") }}
            </div>
          </div>
          <button
            v-if="teacherInfo.learningplaces.length"
            v-ripple
            @click="copyLearn"
          >
            <img src="@/assets/images/copy.svg" alt="" />
          </button>
        </li>
        <li>
          <img src="@/assets/images/bage.svg" alt="" />
          <div>
            <div>{{ $t("teachers_page.modal__place_of_work") }}</div>
            <div v-if="teacherInfo.workplaces.length">
              <div v-for="(key, index) in teacherInfo.workplaces" :key="index">
                {{ index + 1 }}. {{ key.name }}
              </div>
            </div>
            <div v-else>{{ $t("teachers_page.unemployed") }}</div>
          </div>
          <button
            v-if="teacherInfo.workplaces.length"
            v-ripple
            @click="copyWork"
          >
            <img src="@/assets/images/copy.svg" alt="" />
          </button>
        </li>
        <li>
          <img src="@/assets/images/work.svg" alt="" />
          <div>
            <div>{{ $t("teachers_page.modal__projects") }}</div>
            <div
              v-if="teacherInfo.projects.length"
              style="display: flex; flex-direction: column"
            >
              <a
                href=""
                v-for="(key, index) in teacherInfo.projects"
                :key="index"
                v-ripple
                @click="openLink(key.name)"
              >
                {{ index + 1 }}. {{ key.name }}
              </a>
            </div>
            <div v-else>{{ $t("teachers_page.no_projects") }}</div>
          </div>
          <button
            v-if="teacherInfo.projects.length"
            v-ripple
            @click="copyProject"
          >
            <img src="@/assets/images/copy.svg" alt="" />
          </button>
        </li>
        <div class="footer-el">
          <button class="create link-icon blue" v-ripple @click="allInfoCopy">
            <img src="@/assets/images/copy_ax.svg" alt="" />
            {{ $t("teachers_page.modal__copy_all_info") }}
          </button>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ListTileTeacher from "@/components/ListTileTeacher.vue";
import functions from "@/functions";
export default {
  components: {
    ListTileTeacher,
  },
  props: {
    teacher: Number,
  },
  data() {
    return {
      courses: this.$store.state.courses.courses_list,
      localCourses: JSON.parse(localStorage.courses),
      teacherData: {},
      functions,
      notyfications: [],
      id: 0,
    };
  },

  methods: {
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/jpg");
      return dataURL;
    },
    getAverageRGB(imgEl) {
      var blockSize = 5, // only visit every 5 pixels
        defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
        canvas = document.createElement("canvas"),
        context = canvas.getContext && canvas.getContext("2d"),
        data,
        width,
        height,
        i = -4,
        length,
        rgb = { r: 0, g: 0, b: 0 },
        count = 0;

      if (!context) {
        return defaultRGB;
      }

      height = canvas.height =
        imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
      width = canvas.width =
        imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

      context.drawImage(imgEl, 0, 0);

      try {
        data = context.getImageData(0, 0, width, height);
      } catch (e) {
        /* security error, img on diff domain */
        return defaultRGB;
      }

      length = data.data.length;

      while ((i += blockSize * 4) < length) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
      }

      // ~~ used to floor values
      rgb.r = ~~(rgb.r / count);
      rgb.g = ~~(rgb.g / count);
      rgb.b = ~~(rgb.b / count);

      return rgb;
    },
    openLink(url) {
      window.open(url, "_blank").focus();
    },
    copyFormat(text, extraData) {
      const teacher = this.teacherInfo;
      const extra = { ...extraData };
      teacher.img ? teacher.img : undefined;
      if (teacher.img) {
        extra.img = teacher.img;
      }
      extra.text = `${teacher.last_name} ${teacher.first_name} ${teacher.patronymic}`;
      extra.content = `${this.$t(
        "teachers_page.teacher_copy_success"
      )} <br><br> ${this.$t("teachers_page.modal__title")}:`;
      this.copyEll(text, extra);
    },
    copyTg() {
      const teacher = this.teacherInfo;
      let text = `${this.$t("teachers_page.modal__title")}: ${
        teacher.first_name
      } ${teacher.patronymic}\n`;
      if (teacher.username) {
        text += `Telegram USERNAME: @${teacher.username}`;
      }
      this.copyFormat(text);
    },
    copyYouTube() {
      const teacher = this.teacherInfo;
      let text = `${this.$t("teachers_page.modal__title")}: ${
        teacher.first_name
      } ${teacher.patronymic}\n`;
      if (teacher.link_to_tutorial_on_youtube) {
        text += `${this.$t("teachers_page.modal__open_lesson_video")}: ${
          teacher.link_to_tutorial_on_youtube
        }`;
      }
      this.copyFormat(text);
    },
    copyStudent() {
      const teacher = this.teacherInfo;
      let text = `${this.$t("teachers_page.modal__title")}: ${
        teacher.first_name
      } ${teacher.patronymic}\n`;
      if (teacher.released_groups) {
        text += `${this.$t("teachers_page.modal__title")} ${this.$t(
          "teachers_page.modal__released"
        )}: ${(teacher.released_groups, this.$t("teachers_page.student"))}`;
      }
      this.copyFormat(text);
    },
    copyEmail() {
      const teacher = this.teacherInfo;
      let text = ``;
      if (teacher.username) {
        text += `${teacher.email}`;
      }

      this.copyFormat(text);
    },
    copyPh() {
      const teacher = this.teacherInfo;
      let text = `${this.$t("teachers_page.modal__title")}: ${
        teacher.first_name
      } ${teacher.patronymic}\n`;
      if (teacher.contact) {
        text += `${this.$t("teachers_page.modal__phone_number")}: ${
          teacher.contact
        }`;
      }
      this.copyFormat(text);
    },
    copyDir() {
      const teacher = this.teacherInfo;
      let text = `${this.$t("teachers_page.modal__title")}: ${
        teacher.first_name
      } ${teacher.patronymic}\n`;
      text += `${this.$t("teachers_page.main_direction")}: ${
        this.courses_list
      }`;
      this.copyFormat(text);
    },
    copyWorkProweb() {
      const teacher = this.teacherInfo;
      let text = `${this.$t("teachers_page.modal__title")}: ${
        teacher.first_name
      } ${teacher.patronymic}\n`;
      if (teacher.working_time_in_proweb) {
        text += `${this.$t(
          "teachers_page.modal__experience"
        )}: ${this.functions.calculate_age(
          this.teacherInfo.working_time_in_proweb
        )}\n`;
      }
      this.copyFormat(text);
    },
    copyProject() {
      const teacher = this.teacherInfo;
      let text = `${this.$t("teachers_page.modal__title")}: ${
        teacher.first_name
      } ${teacher.patronymic}\n`;
      if (teacher.projects.length) {
        text += `${this.$t("teachers_page.modal__projects")}:`;
        for (let i = 0; i < teacher.projects.length; i++) {
          text += `\n${i + 1} ${teacher.projects[i].name}`;
        }
      }
      this.copyFormat(text);
    },
    copyLearn() {
      const teacher = this.teacherInfo;
      let text = `${this.$t("teachers_page.modal__title")}: ${
        teacher.first_name
      } ${teacher.patronymic}\n`;
      if (teacher.learningplaces.length) {
        text += `${this.$t("teachers_page.modal__place_of_education")}:`;
        for (let i = 0; i < teacher.learningplaces.length; i++) {
          text += `\n${i + 1} ${teacher.learningplaces[i].name}`;
        }
      }
      this.copyFormat(text);
    },
    copyWork() {
      const teacher = this.teacherInfo;
      let text = `${this.$t("teachers_page.modal__title")}: ${
        teacher.first_name
      } ${teacher.patronymic}\n`;
      if (teacher.workplaces.length) {
        text += `${this.$t("teachers_page.modal__place_of_work")}:`;
        for (let i = 0; i < teacher.workplaces.length; i++) {
          text += `\n${i + 1} ${teacher.workplaces[i].name}`;
        }
      }
      this.copyFormat(text);
    },
    allInfoCopy() {
      const teacher = this.teacherInfo;
      let text = `${this.$t("teachers_page.modal__title")}: ${
        teacher.first_name
      } ${teacher.patronymic}\n`;

      text += `${this.$t("teachers_page.main_direction")}}: ${
        this.courses_list
      }`;
      if (teacher.working_time_in_proweb) {
        text += `\n${this.$t(
          "teachers_page.modal__experience"
        )}: ${this.functions.calculate_age(
          this.teacherInfo.working_time_in_proweb
        )}\n`;
      }
      if (teacher.released_groups) {
        text += `\n${this.$t("teachers_page.modal__title")} ${this.$t(
          "teachers_page.modal__released"
        )}: ${this.functions.declination(this.teacherInfo.released_groups, [
          " студента",
          " студента",
          " студента",
        ])}\n`;
      }
      if (teacher.link_to_tutorial_on_youtube) {
        text += `\n${this.$t("teachers_page.modal__open_lesson_video")}: ${
          teacher.link_to_tutorial_on_youtube
        }\n`;
      }
      if (teacher.learningplaces.length) {
        text += `\n${this.$t("teachers_page.modal__place_of_education")}:`;
        for (let i = 0; i < teacher.learningplaces.length; i++) {
          text += `\n${i + 1} ${teacher.learningplaces[i].name}`;
        }
      }
      if (teacher.workplaces.length) {
        text += `\n\n${this.$t("teachers_page.modal__place_of_work")}:`;
        for (let i = 0; i < teacher.workplaces.length; i++) {
          text += `\n${i + 1} ${teacher.workplaces[i].name}`;
        }
      }
      if (teacher.projects.length) {
        text += `\n\n${this.$t("teachers_page.modal__projects")}:`;
        for (let i = 0; i < teacher.projects.length; i++) {
          text += `\n${i + 1} ${teacher.projects[i].name}`;
        }
      }
      this.copyFormat(text);
    },
  },
  computed: {
    ...mapState({
      teacherInfo: function (state) {
        const teachers = JSON.parse(
          JSON.stringify(state.teachers.teachers_list)
        );
        let teacher;
        for (const key in teachers) {
          if (teachers[key].id == this.teacher) {
            teacher = teachers[key];
            break;
          }
        }
        this.teacherData = teacher;
        this.course_idx = teacher;
        return teacher;
      },
      courses_list: function (state) {
        const teachers = JSON.parse(JSON.stringify(state.launches.launches));
        let teacher;
        for (const key in teachers) {
          if (teachers[key].id == this.teacherData.course) {
            teacher = teachers[key];
            break;
          }
        }
        this.color = teacher.color;
        return teacher.name;
      },
    }),
  },
  async mounted() {
    if (this.$store.state.courses.courses_list.length == 0) {
      await this.$store.dispatch("getCourses");
    }
  },
};
</script>

<style lang="scss" scoped>
.footer-el {
  position: sticky;
  bottom: 0px;
  padding: 10px;
  background: #17212b;
  display: flex;
  justify-content: stretch;
  flex-direction: column;
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}

.notifications {
  position: fixed;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__item {
    padding: 16px 20px;
    background: white;
    border-radius: 6px;
    color: #17212b;
    cursor: pointer;
  }
}

.list_info {
  li {
    margin-bottom: 0;
    display: flex;
    align-items: center;

    a {
      color: rgb(131, 164, 255);
      text-decoration: none;
      user-select: auto;
    }

    div {
      flex-grow: 1;
    }

    button {
      background: transparent;
      border: none;
      outline: none;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 200ms;
      border-radius: 50%;

      img {
        margin: 0 !important;
      }

      &:hover {
        background: rgba($color: #fff, $alpha: 0.1);
      }
    }
  }
}
</style>
