import { createStore } from "vuex";
import auth from "./modules/auth";
import launches from "./modules/launches";
import teachers from "./modules/teachers";
import paid from "./modules/paid";
import booking from "./modules/booking";
import snackbars from "./modules/snackbars";
import branches from "./modules/branches";
import banner from "./modules/banner";
import courses from "./modules/courses";
import launches_archive from "./modules/launches_archive";
import master_classes from "./modules/master_classes";
import settings from "./modules/settings";
import noty from "./modules/noty";
import languages from "./modules/languages";
import lead_errors from "./modules/lead_errors";
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    launches,
    launches_archive,
    teachers,
    snackbars,
    courses,
    branches,
    languages,
    master_classes,
    settings,
    noty,
    banner,
    paid,
    booking,
    lead_errors
  },
});
