import Booking from "@/api/booking";
const booking = {
  state: {
    booking_list: [],
  },
  getters: {

  },
  mutations: {
    setBooking(state, data) {
      state.booking_list = data;
    },
  },
  actions: {
    async getBooking(context) {
      const response = await Booking.getBookingList();
      context.commit("setBooking", response);
    },
  },
};

export default booking;
