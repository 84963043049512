<template>
  <div class="course" ref="course">
    <div class="course-bg" :style="{
      background: course.color + '47',
      height: '100%',
    }">
      <h3 class="course__title" :style="{
        background: course.color + 'e0',
        top: top + 'px',
      }">
        <button v-ripple v-if="teachers.length" @click="showModalTeachers = true">
          <img src="@/assets/images/teacher.svg" alt="" />
        </button>
        {{ course.name }}
        <label v-if="settings.newStartCard.view" :title="viewBooking ? $t('Course_card.bookingOpenLessVidited') : $t('Course_card.bookingOpenLessPred')" class="settings__window-view course-column-view-booking">
          <input class="settings__window-view_input" type="checkbox" v-model="viewBooking" />
          <span class="settings__window-view_span"></span>
        </label>
      </h3>
      <div class="course__wrapper">
        <div class="course__col" v-if="language('ru') &&
          !(
            languageFilter(course.start_course, 'ru').length == 0 &&
            settings.langCourse.view
          )
          ">
          <h4 class="course__col-title">РУС</h4>
          <div class="course__col-cards">
            <StartCard v-for="  start   in   languageFilter(course.start_course, 'ru')  " :key="start.id" :viewBooking="viewBooking" :start="start" :courseShortName="course.short_name" :courseName="course.name" :lessonCount="course.lesson_count" lang="РУС" :color="course.color" @updateCard="updateCourseCard(start)" />
          </div>
        </div>

        <div class="course__col" v-if="language('uz') &&
          !(
            languageFilter(course.start_course, 'uz').length == 0 &&
            settings.langCourse.view
          )
          ">
          <h4 class="course__col-title">УЗБ</h4>
          <div class="course__col-cards">
            <StartCard v-for="  start   in   languageFilter(course.start_course, 'uz')  " :key="start.id" :viewBooking="viewBooking" :start="start" :courseShortName="course.short_name" :courseName="course.name" :lessonCount="course.lesson_count" lang="УЗБ" :color="course.color" @updateCard="updateCourseCard(start)" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Teleport to="#dialog">
    <Transition name="slide-fade">
      <Dialog v-if="showModalTeachers" :width="500" @close="showModalTeachers = false">
        <template v-slot:title>{{ $t("navigation.teachers") }}</template>
        <template v-slot:body>
          <TeachersCourse :course="course" />
        </template>
      </Dialog>
    </Transition>
  </Teleport>
</template>

<script>
import StartCard from "@/components/launches/StartCard";
import { mapState } from "vuex";
import TeachersCourse from "@/components/TeachersCourse.vue";
export default {
  components: {
    StartCard,
    TeachersCourse,
  },
  data() {
    return {
      showModalTeachers: false,
      top: 0,
      viewBooking: false,
    };
  },
  props: {
    course: Object,
    currentLanguage: {
      type: String,
      default: "all",
    },
    currentBranch: {
      type: String,
      default: "all",
    },
  },
  methods: {
    languageFilter(array, language) {
      const result = array.filter(
        (data) =>
          data.language == language &&
          (data.branch == this.currentBranch || "all" == this.currentBranch)
      );
      return result;
    },
    updateCourseCard(data) {
      data.course = this.course.id;
      this.$emit("updateCard", data);
    },
    language(lang) {
      return this.currentLanguage == lang || this.currentLanguage == "all";
    },
  },
  mounted() {
    if (!this.$refs.course.closest(".settings")) {
      this.top = document.querySelector(".courses").offsetHeight;

      new ResizeObserver(() => {
        this.top = document.querySelector(".courses").offsetHeight;
      }).observe(document.querySelector(".courses"));
    }
  },
  computed: {
    ...mapState({
      filterData: (state) => state.launches.filterData,
      settings: (state) => state.settings.settings,
      teachers: function (state) {
        const teachers = JSON.parse(
          JSON.stringify(state.teachers.teachers_list)
        );
        const teacher = [];
        for (const key in teachers) {
          if (teachers[key].course == this.course.id && teachers[key].active) {
            teacher.push(teachers[key]);
          }
        }
        return teacher;
      },
    }),
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";

.course {
  flex-grow: 1;
  width: 32%;
  background: #fff;
  @include adaptivMarginlg(10, 10, 20, 0, 5, 5, 10, 10);
  position: relative;
  border-radius: 16px;
  overflow: clip;
  transition: 200ms;

  &.active {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  &__title {
    text-align: center;
    font-size: 25px;
    font-family: "Inter";
    font-weight: 600;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    position: sticky;
    z-index: 10;
    padding: 15px;

    button {
      background: transparent;
      border: none;
      outline: none;
      position: absolute;
      left: 20px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      transition: 200ms;

      &:hover {
        background: rgba($color: #000000, $alpha: 0.2);
      }
    }

    @media (max-width: 768px) {
      @include adaptiv-fontmd(22, 12);
      @include adaptivHeightmd(60, 30);
    }
  }

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__col {
    flex: 1;

    &-cards {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;

      @media (max-width: 768px) {}
    }

    &-title {
      text-align: center;
      margin-bottom: 5px;
      @include adaptiv-fontlg(20, 10);
      font-family: "Inter";
      font-weight: 400;
      color: #303030;
      @include adaptivMarginlg(0, 0, 5, 5, 0, 0, 3, 3);

      @media (max-width: 768px) {
        @include adaptiv-fontmd(20, 10);
      }
    }
  }
}

.settings__window {
  .course {
    &__title {
      position: relative;
      top: 0;
    }
  }
}

.course-column-view-booking {
  position: absolute;
  right: 20px;
}
</style>
