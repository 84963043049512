function getKeyStorage(key) {
    const settings = JSON.parse(localStorage.settings ?? {});
    return settings[key] ?? null;
}
function setStorage(settings, storage) {
    const data = {}
    for (const key in settings) {
        data[key] = settings[key]
    }
    const settingsWrite = JSON.stringify(data)
    localStorage[storage] = settingsWrite
}
function getSettingKey(settings, key, bool = true) {
    if (settings[key] != undefined) {
        return bool ? settings[key].view : settings[key]
    } else {
        return null
    }
}
function getSettingData(settings, key) {
    return settings[key];
}
function getSettingInfo(settings, key) {
    const isExists = getSettingKey(settings, key)
    if (isExists == null) return null;

    const { name, desc } = getSettingData(settings, key)

    return { name, desc }
}

function delet() {
    delete localStorage.settings
}

function calculate_age(dob, arr) {
    var diff_ms = Date.now() - new Date(dob).getTime();
    var age_dt = new Date(diff_ms);
    return declination(Math.ceil(age_dt.getUTCFullYear() - 1970), arr);
}
function declination(number, arr) {
    const titles = arr ? arr : [" год", " года", " лет"];
    const cases = [2, 0, 1, 1, 1, 2];
    return (
        number +
        titles[
        number % 100 > 4 && number % 100 < 20
            ? 2
            : cases[number % 10 < 5 ? number % 10 : 5]
        ]
    );
}
function setNumForDate(num) {
    return num < 10 ? `0${num}` : num
}

export default {
    getKeyStorage: getKeyStorage,
    setStorage: setStorage,
    getSettingKey: getSettingKey,
    getSettingInfo: getSettingInfo,
    delet: delet,
    calculate_age: calculate_age,
    declination: declination,
    setNumForDate: setNumForDate
}