<template>
  <Teleport to="#dialog">
    <Transition name="slide-fade">
      <Dialog v-if="acceptRemove" @close="cancelCard">
        <template v-slot:title>{{
          $t("Course_card.delete__modal_title")
        }}</template>
        <template v-slot:body>
          <p class="delete">{{ $t("Course_card.delete__modal_confirm") }}</p>
          <div class="dialog-btns">
            <button v-ripple class="link-icon red" type="button" @click="removeCard(start.id)">
              <img src="@/assets/images/done.svg" alt="" />
              {{ $t("Course_card.delete__modal_accept") }}
            </button>
            <button v-ripple class="link-icon green" type="button" @click="cancelCard">
              <img src="@/assets/images/no.svg" alt="" />
              {{ $t("Course_card.delete__modal_decline") }}
            </button>
          </div>
        </template>
      </Dialog>
    </Transition>
  </Teleport>
  <Teleport to="#dialog">
    <Transition name="slide-fade">
      <Dialog v-if="acceptArchive" @close="cancelCard">
        <template v-slot:title>{{
          $t("Course_card.archive__modal_title")
        }}</template>
        <template v-slot:body>
          <p class="delete">{{ $t("Course_card.archive_modal__confirm") }}</p>
          <div class="dialog-btns">
            <button v-ripple class="link-icon red" type="button" @click="moveArchive(start.id)">
              <img src="@/assets/images/done.svg" alt="" />
              {{ $t("Course_card.delete__modal_accept") }}
            </button>
            <button v-ripple class="link-icon green" type="button" @click="cancelCard">
              <img src="@/assets/images/no.svg" alt="" />
              {{ $t("Course_card.delete__modal_decline") }}
            </button>
          </div>
        </template>
      </Dialog>
    </Transition>
  </Teleport>
  <Teleport to="#dialog">
    <Transition name="slide-fade">
      <Dialog v-if="teacherActive" :width="600" @close="closeTeacher">
        <template v-slot:title>{{ $t("launch_modal.teacher") }}</template>
        <template v-slot:body>
          <TeacherModal :teacher="start.teacher_id" />
        </template>
      </Dialog>
    </Transition>
  </Teleport>
  <Teleport to="#dialog">
    <Transition name="slide-fade">
      <Dialog v-if="showInfo && lesson_data_generate.length > 0" :width="1100" @close="showInfo = false">
        <template v-slot:title>{{
          $t("Course_card.info_about_launch")
        }}</template>
        <template v-slot:body style="overflow: hidden">
          <div class="content-info" style="text-align: center">
            <p>
              {{ $t("launch_modal.expected_release_date") }}
            </p>
            <p>
              {{ $t("Course_card.modal__course_count") }}:
              {{ countLess }}
            </p>
            <p>
              {{ $t("Course_card.modal__duration") }}: {{ countLess / block }}
              {{ $tc("Course_card.modal__block", calculateBlock) }}
            </p>
            <p>{{ $t("Course_card.modal__lesson_day") }}</p>
            <DayWeak :day="start.day" :color="color" />
          </div>
          <div class="content-info">
            <p>{{ $t("Course_card.modal__approximate_lesson_date") }}</p>
          </div>
          <div class="date__list" ref="scroll_container" @mousewheel="scrollX">
            <div class="date__list-item" v-for="(item_date, index) in lesson_data_generate" :key="index" :class="{
              old: numberSetting(
                start.double_lesson,
                index,
                start.is_internship
              ).class,
              lost: numberSetting(
                start.double_lesson,
                index,
                start.is_internship,
                item_date.toString()
              ).lost,
            }">
              <span>{{
                numberSetting(start.double_lesson, index, start.is_internship)
                  .block
              }}
                {{ $t("Course_card.modal__block", 0) }}</span>
              <span>{{
                numberSetting(start.double_lesson, index, start.is_internship)
                  .less
              }}
                {{ $t("Course_card.modal__lesson", 0) }}</span>
              <span>{{
                startDateFormat(
                  item_date.toString(),
                  [{ day: getActiveDay(item_date) }],
                  "",
                  false,
                  true
                ).toUpperCase()
              }}</span>
              <span class="lost" v-if="numberSetting(
                    start.double_lesson,
                    index,
                    start.is_internship,
                    item_date.toString()
                  ).lost
                  ">{{ $t("launch_modal.finished_lesson") }}</span>
            </div>
          </div>
          <div class="active__holidays" v-if="active_holidays.length">
            <h2>
              {{ $t("launch_modal.canceled_lessons") }}:
              {{ active_holidays.length }}
            </h2>
            <div class="active__holidays-content">
              <div class="active__holidays-item" v-for="item_holiday in active_holidays" :key="item_holiday">
                <span>{{
                  startDateFormat(
                    item_holiday.day,
                    [{ day: getActiveDay(item_holiday.day) }],
                    "",
                    false,
                    true
                  ).toUpperCase()
                }}</span><span>{{ item_holiday.title }}</span>
              </div>
            </div>
            <div class="disclaimer">
              <img src="@/assets/images/info.svg" alt="" />
              *{{ $t("launch_modal.holiday_alert") }}
            </div>
          </div>
        </template>
      </Dialog>
    </Transition>
  </Teleport>
  <div class="start-cart">
    <div class="start-cart-title">
      <div class="start-cart-item">
        <p class="start-cart-title__teacher">
          <span class="teacher-btn" v-if="parseJwt()" v-ripple @click="
            copyEll(start.id, { content: $t('Course_card.number_copied') })
            ">{{ $t("Course_card.launch") }}: {{ start.id }}</span>
          <span class="teacher-btn" v-ripple @click="teacherActive = true">{{
            start.teacher
          }}</span>

          <span class="room">{{ start.cabinet }} {{ $t("Course_card.cabinet_short") }}</span>
          <span>{{ startCourseType(start.schedule) }}</span>
        </p>
        <p v-if="start.discount > 0 && settings.sales.view" class="start-cart-title-discount">
          {{ start.discount }}%
        </p>
        <p class="start-cart-title__branch">
          {{ branchForId(start.branch) }}
        </p>
      </div>
      <div>
        <p class="start-cart-title-seats">
          {{ start.seats }} {{ $t("launch_modal.seat_count") }}
        </p>
      </div>
    </div>
    <Transition name="slide-card" mode="out-in">
      <div class="start-cart-box" v-if="!innerCrmContent">
        <template v-if="!settings.newStartCard.view">
          <div class="start-cart-date">
            <p class="start-cart-date__date start-date-title" :class="{ active: nearestDate }">
              <span class="start-day-active" v-if="start.active"></span>{{ startDateFormat(start.date, start.day) }}
              <span :title="$t('Course_card.paid_count')" style="
                  background: #cd0000;
                  padding: 2px 4px;
                  border-radius: 4px;
                  color: white;
                " v-if="getAllCountPaid() > 0">
                {{ getAllCountPaid() }}</span>
              <span :title="$t('Course_card.paid_count')" style="
                  background: blue;
                  padding: 2px 4px;
                  border-radius: 4px;
                  color: white;
                " v-if="getAllCountReservation() > 0">
                {{ getAllCountReservation() }}</span>
            </p>
            <p class="start-cart-date__time">
              <span class="start-cart-date__time-span" v-for="(time, index) in startData.schedule" :key="time.id">
                <span>
                  {{ timeFormat(time.start_lesson) }}
                  <span v-if="time.discount > 0 && settings.sales.view" class="start-cart-title-discount">{{ time.discount }}%</span>
                  <span v-if="time.paid" :title="$t('Course_card.paid_in_group')" style="
                      background: green;
                      font-size: 11px;
                      color: white;
                      padding: 2px 4px;
                      border-radius: 5px;
                    ">{{ time.paid }}</span>

                </span>
                {{ index != start.schedule.length - 1 ? "|" : "" }}
              </span>
            </p>
          </div>
          <div class="start-cart-date" v-for="open_lesson in start.open_lesson" :key="open_lesson">
            <p class="start-cart-date__date">
              <span v-if="open_lesson.active" class="start-cart-date__date-active"></span>
              {{ openLessonDateFormat(open_lesson.date) }}
              <span v-if="open_lesson.cabinet?.length > 0 &&
                start.cabinet != open_lesson.cabinet
                " class="start-cart-date__date-cab">{{ open_lesson.cabinet }}
              </span>
            </p>
            <p class="start-cart-date__time">
              <span class="start-cart-date__time" v-for="(time, index) in open_lesson.open_lesson_time" :key="time.id">
                <span class="openless" :title="time.is_master_class && settings.mkOpenLess.view
                  ? $t('Course_card.format_open_lesson')
                  : ''
                  " :class="{
    mk: time.is_master_class && settings.mkOpenLess.view,
  }">
                  {{ time.is_master_class ? "МК " : "" }}

                  <span><span v-if="time.active" class="start-cart-date__time-active"></span>{{ timeFormat(time.time) }}
                  </span>
                  <span class="not-filld" v-if="settings.viewCountLid.view">
                    <span class="visited-all" v-if="time.visited != null || time.visited != undefined" :title="$t('Course_card.visited_all')">{{ time.visited }}
                    </span>
                    <span class="visited-offline" v-if="time.visited_offline != null ||
                      time.visited_offline != undefined
                      " :title="$t('Course_card.visited_offline')">{{ time.visited_offline }}
                    </span>
                    <span class="visited-online" v-if="time.visited_online != null ||
                      time.visited_online != undefined
                      " :title="$t('Course_card.visited_online')">
                      {{ time.visited_online }}
                    </span>
                  </span>
                </span>
                {{
                  index != open_lesson.open_lesson_time.length - 1 ? "|" : ""
                }}
              </span>
            </p>
          </div>
        </template>
        <template v-else>
          <div class="new-start">
            <div class="new-start__schedule">
              <div class="new-start__header">
                <h3 class="new-start__title new-start__title_schedule">
                  {{ startDateFormat(start.date, start.day).toUpperCase() }}
                </h3>
                <p class="new-start__count new-start__count_red" title="Общее записавшихся" v-if="getAllCountPaid() > 0 && getAllCountReservation() == 0">
                  {{ getAllCountPaid() }}
                </p>
                <p class="new-start__count new-start__count_blue" title="Общее броней" v-else-if="getAllCountReservation() > 0 && getAllCountPaid() == 0">
                  {{ getAllCountReservation() }}
                </p>
                <div style="width: 100%; display: flex; gap: 5px; justify-content: center;" v-else>
                  <p class="new-start__count new-start__count_red" title="Общее записавшихся" v-if="getAllCountPaid() > 0">
                    {{ getAllCountPaid() }}
                  </p>
                  <p class="new-start__count new-start__count_blue" title="Общее броней" v-if="getAllCountReservation() > 0">
                    {{ getAllCountReservation() }}
                  </p>
                </div>
              </div>
              <div class="new-start__body">
                <div class="new-start__body-time info" @click="copyEll(contentCopy(start, time).trim())" v-ripple:grey v-for="time in startData.schedule" :key="time.id">
                  <div class="new-start__body-time_left">
                    {{ timeFormat(time.start_lesson) }}
                  </div>
                  <div class="new-start__body-time_right">
                    <div class="new-start__count new-start__count_red" :title="$t('launch_modal.discount')" v-if="time.discount > 0 && settings.sales.view">
                      {{ time.discount }}%
                    </div>
                    <div class="new-start__count new-start__count_green" v-if="time.paid">
                      {{ time.paid }}
                    </div>
                    <div class="new-start__count new-start__count_blue" v-if="time.reservation">
                      {{ time.reservation }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="new-start__open" v-for="open_lesson in start.open_lesson" :key="open_lesson">
              <div class="new-start__header">
                <h3 class="new-start__title new-start__title_open">
                  {{ openLessonDateFormat(open_lesson.date).toUpperCase() }}
                </h3>
                <p class="new-start__count new-start__count_green" v-if="open_lesson.cabinet?.length > 0 &&
                  start.cabinet != open_lesson.cabinet
                  ">
                  {{ open_lesson.cabinet }}
                  {{ $t("Course_card.cabinet_short") }}
                </p>
              </div>
              <div class="new-start__body" :class="{
                'new-start__body_grid': !settings.viewCountLid.view,
                'new-start__body_old':
                  !settings.viewCountLid.view &&
                  open_lesson.open_lesson_time.length % 2 > 0,
              }">
                <div class="new-start__body-time" v-for="time in open_lesson.open_lesson_time" :key="time.id">
                  <div class="new-start__body-time_left">
                    {{ timeFormat(time.time) }}
                  </div>
                  <div class="new-start__body-time_center">
                    <p class="new-start__count new-start__count_green" :title="$t('Course_card.format_open_lesson_long')" v-if="time.is_master_class">
                      МК
                    </p>
                  </div>
                  <div class="new-start__body-time_right" v-if="settings.viewCountLid.view">
                    <template v-if="!viewBooking">
                      <div class="new-start__count new-start__count_blue" v-if="time.visited != null || time.visited != undefined" :title="$t('Course_card.visited_all')">
                        {{ time.visited }}
                      </div>
                      <div class="new-start__count new-start__count_orange" v-if="time.visited_offline != null ||
                        time.visited_offline != undefined
                        " :title="$t('Course_card.visited_offline')">
                        {{ time.visited_offline }}
                      </div>
                      <div class="new-start__count new-start__count_green" v-if="time.visited_online != null ||
                        time.visited_online != undefined
                        " :title="$t('Course_card.visited_online')">
                        {{ time.visited_online }}
                      </div>
                    </template>
                    <template v-else>
                      <div class="new-start__count new-start__count_green" v-if="time.booking != null ||
                        time.booking != undefined
                        " :title="$t('Course_card.bookingOpenLess')">
                        {{ time.booking }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <DayWeak :day="start.day" class="position" :class="{ no_position: !settings.approximateDay.view }" :color="color" :mini="true" />
        <div class="load-and-view" v-if="settings.approximateDay.view" :style="{ background: color }">
          <Transition name="slide-up" mode="out-in">
            <div v-if="lesson_data_generate.length" :style="{ color: colorText }" class="finished-date" @click="showInfo = true" v-ripple>
              <img src="@/assets/images/info.svg" :style="{
                filter: colorText == 'black' ? 'invert(1)' : 'invert(0)',
              }" alt="" />
              <div class="finished-date__content">
                {{ $t("Course_card.approximate_over_date") }}:
                <p>{{ lesson_data_string }}</p>
                <span :style="{ color: colorText }">{{
                  $t("Course_card.more")
                }}</span>
              </div>
            </div>
            <div v-else class="finished-date load" v-ripple>
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="finished-date__content" :style="{ color: colorText }">
                {{ $t("launch_modal.calculating_date") + "..." }}
                <p>{{ $t("launch_modal.calculating_duration") }}</p>
              </div>
            </div>
          </Transition>
        </div>

        <div class="btn-list-config" v-if="testView &&
          user.admin &&
          !acceptRemove &&
          !acceptArchive &&
          !start.archive
          ">
          <button v-ripple v-if="!requestVisidet(start.open_lesson)" class="link-icon red" type="button" @click="acceptRemoveCard">
            <img src="@/assets/images/delet.svg" alt="" />
            {{ $t("launch_modal.delete") }}
          </button>
          <button v-ripple class="link-icon blue" type="button" @click="updateCard(start)">
            <img src="@/assets/images/edit.svg" alt="" />
            {{ $t("Course_card.edit") }}
          </button>
          <button v-ripple class="link-icon green" type="button" @click="acceptMoveArchive">
            <img src="@/assets/images/archiv_card.svg" alt="" />
            {{ $t("Course_card.archive") }}
          </button>
        </div>
      </div>
      <!-- </Transition> -->
      <div class="start-cart-box-inner" v-else>
        <div class="start-cart-box-inner-item" v-for="time in start.schedule" :key="time.id">
          <div class="crm-info-copy" v-ripple:grey :style="{ background: color, color: colorText }" @click="copyEll(contentCopy(start, time).trim())">
            <p v-for="(item, key) in contentCopy(start, time, true)" :key="key">
              {{ item }}
            </p>

            <div class="copy-info">
              <img :style="{
                filter: colorText == 'black' ? 'invert(1)' : 'invert(0)',
              }" src="@/assets/images/copy.svg" alt="" />
            </div>
          </div>
          <p class="start-cart-box-inner-txt">
            {{ $t("Course_card.enroll") }} {{ timeFormat(time.start_lesson) }}
          </p>
          <p class="start-cart-box-inner-time" v-if="time.offline">
            {{ courseShortName.toUpperCase()
            }}{{ start.language == "ru" ? "рус" : "уз" }}
            {{ dateFormat(start.date) }} {{ timeFormat(time.start_lesson) }}-{{
              timeFormat(time.end_lesson)
            }}
            {{ $t("Course_card.full_time") }}
          </p>
          <p class="start-cart-box-inner-time" v-if="time.online">
            {{ courseShortName.toUpperCase()
            }}{{ start.language == "ru" ? "рус" : "уз" }}
            {{ dateFormat(start.date) }} {{ timeFormat(time.start_lesson) }}-{{
              timeFormat(time.end_lesson)
            }}
            {{ $t("launch_modal.online") }}
          </p>
        </div>
      </div>
    </Transition>
    <button v-ripple v-if="settings.crmBtn.view && testView" class="link-icon black" @click="innerCrmContent = !innerCrmContent">
      <img src="@/assets/images/crm.svg" alt="" />
      {{ !innerCrmContent ? "crm" : $t("Course_card.close") }}
    </button>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TeacherModal from "@/components/TeacherModal";
import DayWeak from "@/components/DayWeak.vue";
export default {
  props: {
    start: Object,
    courseShortName: String,
    courseName: String,
    lang: String,
    color: String,
    lessonCount: Number,
    viewBooking: Boolean,
    testView: {
      type: Boolean,
      default: true,
    },
  },
  components: { TeacherModal, DayWeak },
  data() {
    return {
      showInfo: false,
      lesson_data_generate: [],
      lesson_data_string: "",
      day_less: [],
      active_holidays: [],
      innerCrmContent: false,
      teacherActive: false,
      colorText: "white",
      month: [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ],
      weekDayShort: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вск"],
      weekDayLong: [
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота",
        "Воскресенье",
      ],
      acceptRemove: false,
      acceptArchive: false,
      salesView: true,
    };
  },
  mounted() {
    this.weekDayLong = [
      this.$t("master-class_page.monday"),
      this.$t("master-class_page.tuesday"),
      this.$t("master-class_page.wednesday"),
      this.$t("master-class_page.thursday"),
      this.$t("master-class_page.friday"),
      this.$t("master-class_page.saturday"),
      this.$t("master-class_page.sunday"),
    ];
    this.weekDayShort = [
      this.$t("Course_card.monday_short"),
      this.$t("Course_card.tuesday_short"),
      this.$t("Course_card.wednesday_short"),
      this.$t("Course_card.thursday_short"),
      this.$t("Course_card.friday_short"),
      this.$t("Course_card.saturday_short"),
      this.$t("Course_card.sunday_short"),
    ];
    this.month = [
      this.$tc("Course_card.january", 2),
      this.$tc("Course_card.february", 2),
      this.$tc("Course_card.march", 2),
      this.$tc("Course_card.april", 2),
      this.$tc("Course_card.may", 2),
      this.$tc("Course_card.june", 2),
      this.$tc("Course_card.july", 2),
      this.$tc("Course_card.august", 2),
      this.$tc("Course_card.september", 2),
      this.$tc("Course_card.october", 2),
      this.$tc("Course_card.november", 2),
      this.$tc("Course_card.december", 2),
    ];
    this.showLessonsGenerate(this.start);
    this.colorText = this.getContrastYIQ(this.color);
  },
  methods: {
    ...mapActions({
      removeLaunch: "removeLaunch",
      moveToArchive: "moveToArchive",
    }),
    parseJwt() {
      const token = localStorage.token;
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const atobs = window
        .atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("");
      const jsonPayload = decodeURIComponent(atobs);
      const payload = JSON.parse(jsonPayload);
      return payload.user_id === 1;
    },
    getContrastYIQ(hexcolor) {
      const r = parseInt(hexcolor.substring(1, 3), 16);
      const g = parseInt(hexcolor.substring(3, 5), 16);
      const b = parseInt(hexcolor.substring(5, 7), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "black" : "white";
    },

    getAllCountPaid() {
      const amount = this.startData.schedule.reduce((a, c) => {
        let paid = 0;
        if (typeof c.paid == "number") {
          paid = c.paid;
        }
        return a + paid;
      }, 0);
      return amount > 0 ? amount : "";
    },
    getAllCountReservation() {
      const amount = this.startData.schedule.reduce((a, c) => {
        let reservation = 0;
        if (typeof c.reservation == "number") {
          reservation = c.reservation;
        }
        return a + reservation;
      }, 0);
      return amount > 0 ? amount : "";
    },

    timeString(data) {
      return `${data < 10 ? `0${data}` : data}`;
    },
    timeFormat(time) {
      return time.slice(0, 5);
    },
    contentCopy(start, time, text = false) {
      const info = [];
      info.push(
        `${start.is_internship ? $t("launch_modal.intership") : ""} ${this.courseName
        } ${this.lang}`
      );
      info.push(`${start.teacher}`);
      info.push(
        `${this.startDateFormat(
          start.date,
          start.day,
          `${this.timeFormat(time.start_lesson)}`,
          true
        ).toUpperCase()}`
      );
      info.push(`${this.branchForId(start.branch)}`);

      if (text) return info;
      else return info.join("\n");
    },

    strDate(date) {
      return date < 10 ? `0${date}` : date;
    },
    dateFormat(date) {
      const newDate = new Date(date);
      return `${newDate.getDate()}.${newDate.getMonth() + 1
        }.${newDate.getFullYear()}`;
    },
    openLessonDateFormat(date) {
      const newDate = new Date(date);

      const daysText =
        this.weekDayShort[newDate.getDay() - 1 >= 0 ? newDate.getDay() - 1 : 6];

      const text = `${newDate.getDate()} ${this.month[newDate.getMonth()]
        } ${daysText}`;

      return text;
    },
    acceptRemoveCard() {
      this.acceptRemove = true;
    },
    acceptMoveArchive() {
      this.acceptArchive = true;
    },
    cancelCard() {
      this.acceptRemove = false;
      this.acceptArchive = false;
    },
    removeCard(id) {
      this.removeLaunch(id);
      this.acceptRemove = false;
      this.acceptArchive = false;
    },
    moveArchive(id) {
      this.moveToArchive({ id: id });
      this.acceptRemove = false;
      this.acceptArchive = false;
      this.$store.dispatch("addSnack", {
        title: this.$t("snackbar.archive_title"),
        content: this.$t("Course_card.archive_been_added"),
        time: 10000,
        actions: [
          {
            name: "ОТКРЫТЬ АРХИВ",
            onTap: () => {
              this.$router.push("/archive");
            },
          },
        ],
      });
    },
    updateCard(data) {
      this.$emit("updateCard", data);
    },
    branchForId(id) {
      let branch = "";
      this.branch_list.forEach((element) => {
        if (element.id == id) {
          branch = element.name;
        }
      });
      return branch;
    },
    startCourseType(schedule) {
      const offline = schedule[0].offline;
      const online = schedule[0].online;
      if (offline && online) {
        return this.$t("Course_card.hybrid");
      } else if (offline) {
        return this.$t("Course_card.full_time");
      } else if (online) {
        return this.$t("launch_modal.online");
      } else {
        return "";
      }
    },
    requestVisidet(open_less) {
      let bool = false;
      open_less?.forEach((e) => {
        e.open_lesson_time?.forEach((a) => {
          if (a.visited > 0) {
            bool = true;
          }
        });
      });

      return bool;
    },
    closeTeacher() {
      this.teacherActive = false;
    },
  },
  computed: {
    ...mapState({
      branch_list: (state) => state.branches.branches_list,
      user: (state) => state.auth.user,
      settings: (state) => state.settings.settings,
      nearestDateView: (state) => state.launches.nearestDateView,
      startData: function (state) {
        const state_paid = state.paid.paid_list;
        if (state_paid.length > 0) {
          const datas = JSON.stringify(state_paid);
          const dataj = JSON.parse(datas);
          const find_schedule = dataj.filter((a) => a.card_id == this.start.id);
          this.start.schedule.map((a) => {
            for (const key in find_schedule) {
              const item = find_schedule[key];
              if (item.start_lesson == a.start_lesson) {
                a.paid = item.paid;
                a.reservation = item.reservation;
                break;
              }
            }
            return a;
          });
        }
        const state_booking = state.booking.booking_list
        if (state_booking.length > 0) {
          const datas = JSON.stringify(state_booking);
          const dataj = JSON.parse(datas);
          const find_schedule = dataj.filter((a) => a.card_id == this.start.id);
          this.start.open_lesson.map((a) => {

            for (const key in find_schedule) {
              const item = find_schedule[key];
              if (item.date == a.date) {
                for (const b in a.open_lesson_time) {
                  if (a.open_lesson_time[b].time == item.time) {
                    a.open_lesson_time[b].booking = item.registered
                  }
                }
              }
            }

            return a;
          })
        }
        return this.start;
      },
      calculateBlock() {
        if (this.countLess / this.block <= 1) return 0;
        else if (this.countLess / this.block < 5) return 1;
        else return 2;
      },
    }),

    nearestDate() {
      const startDate = new Date(this.start.date);
      const currentDate = Date.now();
      if (
        this.nearestDateView &&
        (startDate - currentDate) / 1000 < 7 * 24 * 60 * 60 &&
        (startDate - currentDate) / 1000 > -24 * 60 * 60
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";

.new-start {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-family: "Roboto";
  color: black;

  &>div {
    padding: 10px 15px;
    border-radius: 15px;
  }

  &__header {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  &__count {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    height: 25px;
    min-width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d10b10;
    color: white;
    border-radius: 25px;
  }

  &__schedule {
    background: #ffecec;
    border: 1px solid #ffb6b6;
  }

  &__open {
    background: #f1f4fa;
    border: 1px solid #b3cdff;
  }

  &__count {
    font-size: 12px;
    line-height: 12px;
    color: white;
    padding: 0 5px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    min-width: 20px;

    &_red {
      background: #db3f43;
    }

    &_green {
      background: #1d9d25;
    }

    &_blue {
      background: #002ca9;
    }

    &_orange {
      background: #f37500;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    $parent: &;

    &_grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      text-align: center;

      #{$parent}-time {
        justify-content: center;
      }

      &#{$parent}_old {
        #{$parent}-time {
          &:last-of-type {
            grid-column: span 2;
          }
        }
      }
    }

    &-time {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 5px;
      padding: 4.5px 20px;
      background: white;
      border-radius: 20px;
      transition: 200ms;

      &.info:hover {
        background: rgb(233, 233, 233);
      }

      &_left {
        font-weight: 600;
        font-size: 14px;
      }

      &_right {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }

      &_center {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;

    &_schedule {
      color: #d10b10;
    }

    &_open {
      color: #002ca9;
    }
  }
}

.dialog-btns {
  display: flex;
  padding: 30px 10px 10px;
  gap: 10px;
  justify-content: center;
}

.btn-list-config {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.start-day-active {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  animation: start 3s linear infinite;
  margin-right: 5px;
}

.start-cart {
  @include adaptivWidthLg(294, 160);
  width: 100%;
  background: #fff;
  transition: 200ms;
  border-radius: 20px;
  border: 1px solid #f0f0f0;
  position: relative;
  box-shadow: 0 0 15px -5px rgba($color: #000000, $alpha: 0.1);

  &:hover {
    box-shadow: 0 0 15px -5px rgba($color: #000000, $alpha: 0.5);
  }

  text-align: center;
  @include adaptivMarginlg(5, 5, 0, 10, 2, 2, 0, 5);
  @include adaptivPaddinglg(5, 5, 10, 20, 5, 5, 5, 10);

  @media (max-width: 768px) {
    @include adaptivWidthmd(320, 140);
    max-width: 99%;
    @include adaptivPaddingmd(5, 5, 15, 20, 5, 5, 7, 10);
    @include adaptivMarginmd(5, 5, 5, 9, 2, 2, 5, 2);
  }

  &.active {
    width: 30%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-date {
    margin-bottom: 5px;

    &__date {
      margin-bottom: 3px;
      color: #0029bc;

      &-active {
        width: 15px;
        height: 15px;
        display: inline-block;
        border-radius: 20px;
        animation: 3s openless linear infinite;
      }

      .coutn {
        color: white;
        font-size: 12px;
        padding: 2px 3px;
        background: #0029bc;
        border-radius: 3px;
      }

      @include adaptiv-fontlg(18, 12);
      font-family: "Inter";
      font-weight: 600;

      @media (max-width: 768px) {
        @include adaptiv-fontmd(22, 15);
      }

      &-cab {
        background: green;
        color: #fff;
        border-radius: 4px;
        @include adaptiv-fontlg(12, 10);
        padding: 1px 5px;
      }

      &.start-date-title {
        color: #cd0000;
        @include adaptiv-fontlg(18, 14);
        font-family: "Inter";
        font-weight: 600;
        text-transform: uppercase;

        @media (max-width: 768px) {
          @include adaptiv-fontmd(22, 15);
        }

        &.active {
          border: 1px solid red;
          width: max-content;
          margin: 0 auto;
          padding: 0 5px;
          border-radius: 4px;
          animation: nearStart 2000ms infinite;
        }
      }
    }

    &__time {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      color: black;

      &-active {
        width: 15px;
        height: 15px;
        display: inline-block;
        animation: time 3s linear infinite;
        border-radius: 20px;
      }

      &-span {
        color: black;
      }

      .openless {
        color: black;

        &.mk {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px 5px 2px;
          background: green;
          color: white;
          border-radius: 10px;
          animation: mk 3s infinite;
          line-height: 12px;
          cursor: help;
        }

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .not-filld {
          background: transparent;
          padding: 0;

          span {
            padding: 2px 3px;
            border-radius: 5px;
            font-weight: bold;
            color: black;
            font-size: 12px;
          }

          span.visited-all {
            background: #0029bc;
            color: white;
          }

          span.visited-offline {
            background: rgba(128, 109, 0, 0.24);
          }

          span.visited-online {
            background: rgba(0, 128, 0, 0.24);
          }
        }
      }

      &.count {
        gap: 5px;
      }

      flex-wrap: wrap;
      @include adaptiv-fontlg(14, 10);
      font-family: "Inter";
      font-weight: 500;

      @media (max-width: 768px) {
        @include adaptiv-fontmd(20, 13);
      }

      span {
        display: flex;
        align-items: center;
        margin: 2px;
        padding: 1px;
      }
    }
  }

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    @include adaptivMarginlg(0, 0, 0, 10, 0, 0, 0, 5);

    @media (max-width: 768px) {
      @include adaptivMarginmd(0, 0, 0, 10, 0, 0, 0, 5);
    }

    &__teacher {
      @include adaptiv-fontlg(10, 6);
      font-family: "Inter";
      font-weight: 600;
      color: #303030;
      text-transform: uppercase;
      text-align: start;

      @media (max-width: 768px) {
        @include adaptiv-fontmd(15, 8);
      }

      span {
        margin: 0 2px;
      }

      .room {
        text-transform: lowercase;
        background: green;
        color: #fff;
        border-radius: 4px;
        @include adaptiv-fontlg(12, 10);
        padding: 1px 5px;
      }
    }

    &-discount {
      @include adaptiv-fontlg(10, 6);
      font-family: "Inter";
      font-weight: 600;
      color: #fff;
      width: 30px;
      background: red;
      border-radius: 4px;
      justify-content: center;
    }

    &-seats {
      @include adaptiv-fontlg(13, 6);
      font-family: "Inter";
      font-weight: 600;
      color: #303030;
    }

    &__branch {
      @include adaptiv-fontlg(10, 7);
      font-family: "Inter";
      font-weight: 600;
      color: #303030;
      text-transform: uppercase;

      @media (max-width: 768px) {
        @include adaptiv-fontmd(14, 8);
      }
    }
  }

  .delete {
    @include adaptiv-fontlg(14, 12);
    font-family: "Inter";
    font-weight: 400;
    color: red;
    margin-top: 20px;
  }

  .start-btn {
    @include adaptivWidthLg(120, 60);
    width: 100%;
    @include adaptivHeightlg(30, 20);
    @include adaptiv-fontlg(14, 8);
    @include adaptivMarginlg(5, 5, 5, 5, 2, 2, 2, 2);

    @media (max-width: 768px) {
      @include adaptivWidthmd(170, 120);
      @include adaptivHeightmd(50, 30);
      @include adaptiv-fontmd(22, 13);
      color: #303030;
    }

    &:hover {
      background: #303030;
      color: #fff;
      transition: 0.5s;
      border-radius: 4px;
    }
  }

  &-crm {
    @include adaptivWidthLg(120, 60);
    width: 100%;
    @include adaptivHeightlg(30, 20);
    @include adaptiv-fontlg(14, 8);
    @include adaptivMarginlg(5, 5, 5, 5, 2, 2, 2, 2);
    font-family: "Inter";
    text-transform: uppercase;
    color: #fff;
    background: #303030;
    border: 1px solid #303030;
    border-radius: 4px;
    margin-top: 5px;
  }

  &-box {
    &-inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: start;
      padding: 10px;

      &-item {
        margin: 5px 0;
      }

      &-txt {
        @include adaptiv-fontlg(13, 7);
        font-family: "Inter";
        font-weight: 600;
        color: red;
      }

      &-time {
        @include adaptiv-fontlg(13, 7);
        font-family: "Inter";
        font-weight: 600;
        color: #303030;
      }
    }
  }
}

.teacher-btn {
  cursor: pointer;
  padding: 5px;
  display: block;
  width: max-content;
  font-size: 12px;
  border-radius: 4px;
  margin-bottom: 5px !important;
  user-select: none;

  &:hover {
    background: rgb(212, 212, 212);
  }
}

.crm-info-copy {
  color: #000;
  font-size: 12px;
  font-weight: bold;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
  position: relative;

  .copy-info {
    position: absolute;
    right: 5px;
    bottom: 5px;

    img {
      width: 18px;
      filter: invert(1);
    }
  }
}

.info-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 10px;

  .finished-date {
    align-items: center;
  }
}

.finished-date {
  padding: 10px;
  color: white;
  border-radius: 10px;
  text-align: left;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  font-size: 14px;

  &.center {
    align-items: center;
    max-width: 500px;
    margin: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    span {
      align-self: flex-end;
      background: transparent;
      border: none;
      outline: none;
      border-radius: 100px;
      padding: 10px 12px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #fff;
      transition: 200ms;

      &:hover {
        background: rgba($color: #ffffff, $alpha: 0.1);
      }
    }
  }

  p {
    font-weight: bold;
  }
}

.date__list {
  display: inline-flex;
  position: sticky;
  top: 0;
  white-space: nowrap;
  overflow: auto;
  padding: 5px 15px;
  width: 100%;
  background: #232e3c;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.315);
  color: rgba(255, 255, 255, 0.356);
  font-size: 13px;

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba($color: #ffffff, $alpha: 0.1);
    }
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($color: #ffffff, $alpha: 0);
    border-radius: 10px;
  }

  &-item {
    display: inline-flex;
    color: rgb(180, 180, 180);
    transition: all 200ms;
    align-items: center;
    gap: 10px;
    position: relative;
    justify-content: center;
    flex-direction: column;
    padding: 10px;

    &.lost {
      background: #17212b;
      border-radius: 10px;
      cursor: default;
      user-select: none;

      &:hover {
        span:not(.lost) {
          text-decoration: none;
        }
      }

      span:not(.lost) {
        text-decoration: line-through;
        transition: 200ms;
      }

      .lost {
        color: red;
        text-decoration: none !important;
      }
    }

    &.old {
      border-right: 3px solid white;
    }

    span {
      &:last-child {
        color: white;
      }
    }
  }
}

.content-info {
  p {
    padding: 5px 10px;

    &:first-child {
      text-align: center;
      padding: 20px;
    }
  }
}

.active__holidays {
  padding: 16px;

  .disclaimer {
    background: rgb(139, 0, 0);
    padding: 15px;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    align-items: flex-start;
  }

  h2 {
    font-size: 18px;
    text-align: center;
    color: white;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
    color: white;
  }

  &-item {
    display: flex;
    gap: 5px;
    border: 1px solid white;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);

    span {
      justify-content: stretch;
      text-align: center;
      width: 50%;
      padding: 10px;
    }
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 3px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes nearStart {
  0% {
    background: #fff;
  }

  50% {
    background: red;
    color: #fff;
  }

  100% {
    background: #fff;
  }
}

@keyframes mk {
  0% {
    background: #fff;
    color: black;
  }

  50% {
    background: green;
    color: #fff;
  }

  100% {
    background: #fff;
    color: black;
  }
}

@keyframes openless {
  0% {
    background: #0029bc;
  }

  50% {
    background: transparent;
  }

  100% {
    background: #0029bc;
  }
}

@keyframes start {
  0% {
    background: red;
  }

  50% {
    background: transparent;
  }

  100% {
    background: red;
  }
}

@keyframes time {
  0% {
    background: green;
  }

  50% {
    background: transparent;
  }

  100% {
    background: green;
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px) scale(0.9);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px) scale(0.9);
}

.calendar {
  &__item {
    min-width: 20px;
    height: 35px;
    margin-right: 0;
  }

  &__list {
    text-transform: uppercase;
    width: 100%;
    justify-content: center;
    padding: 10px;
    border-radius: 16px;
    width: max-content;
    margin: auto;

    &.position {
      margin: 20px auto 0;
      padding: 10px 0 20px;
      border-radius: 16px 16px 0 0;
      margin: 10px auto -10px;
      font-size: 12px;
      width: 100%;
    }

    &.no_position {
      margin: 10px auto;
      border-radius: 16px;
      padding: 10px 0;
    }
  }
}

.load-and-view {
  overflow: hidden;
  width: 100%;
  height: max-content;
  border-radius: 10px;
  margin-bottom: 10px;
}
</style>
<style lang="scss">
.calendar {
  &__item {
    min-width: 20px;
    height: 35px;
    margin-right: 0;
  }
}
</style>
