<template>
  <div class="settings__tabs" ref="tabs">
    <div class="settings__tabs-header extra-title">
      <div v-ripple v-for="(item, key) in tabs_name" :key="item" :class="{ active: key == tab_active }" @click="(tab_active = key), (lang_drop = false)">
        {{ item.name }}
      </div>
    </div>
    <template v-for="(item, key) in tabs_name" :key="key">
      <TransitionGroup name="slide-fade-set" mode="out-in">
        <template v-if="key == tab_active">
          <div class="settings__window-item" v-for="(setting, key, index) in item.data" :key="index" v-ripple @click="updateSetting(key, tab_active)">
            <p class="settings__window-info">
              <span class="settings__window-name">{{ setting.name }}</span>
              <span class="settings__window-desc">{{ setting.desc }}</span>
            </p>

            <label class="settings__window-view">
              <input class="settings__window-view_input" type="checkbox" :checked="setting.view" @click="updateSetting(key, tab_active)" />
              <span class="settings__window-view_span"></span>
            </label>
          </div>
        </template>
      </TransitionGroup>
    </template>
    <div class="settings__window-item lang" v-ripple @click="lang_drop = !lang_drop">
      <p class="settings__window-info">
        <span class="settings__window-name">
          {{ $t("settings_modal.switch_lang") }}</span>
      </p>
    </div>
    <ul class="langs" v-if="lang_drop">
      <li class="langs__item" v-for="item in $store.state.languages.languages" @click="setLanguage(item.short_name)" v-ripple>
        {{ item.name }}
      </li>
    </ul>
    <div class="settings__window-item" v-ripple @click="defaultSettings">
      <p class="settings__window-info">
        <span class="settings__window-name">{{
          $t("settings_modal.generals__defaults__title")
        }}</span>
        <span class="settings__window-desc">{{
          $t("settings_modal.settings_modal__generals__defaults__desc")
        }}</span>
      </p>
    </div>
    <p class="settings__window-text">
      {{ $t("settings_modal.generals__footer__text") }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      lang_drop: false,
      version: localStorage.versionApp,
      tab_active: 0,
      tabs_name: [
        {
          name: this.$t("settings_modal.generals__title"),
          local: "settings",
        },
        {
          name: this.$t("settings_modal.notifications__title"),
          local: "noty",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      noty: (state) => state.noty.noty,
    }),
  },
  mounted() {
    for (const key in this.tabs_name) {
      const local = this.tabs_name[key].local;
      this.tabs_name[key].data = this.$store.getters[`get${local}`];
    }
    this.$refs.tabs.closest(".settings__window").style.height = "90vh";
  },
  methods: {
    updateSetting(key, index) {
      const item = this.tabs_name[index].data[key];
      const local = this.tabs_name[index].local;
      this.$store.dispatch(`update${this.tabs_name[index].local}`, {
        key,
        value: !item.view,
      });
      if (this.noty.notySettings.view) {
        this.$store.dispatch("addSnack", {
          title: this.$t("settings_modal.notifications__settings__title"),
          content: `${item.name} - ${!item.view
              ? this.$t("snackbar.turned_off")
              : this.$t("snackbar.turned_on")
            }`,
          time: 10000,
          icon: "settings",
          actions: [
            {
              name: !item.view
                ? this.$t("snackbar.turned_on")
                : this.$t("snackbar.turn_off"),
              onTap: () => {
                this.$store.dispatch(`update${this.tabs_name[index].local}`, {
                  key,
                  value: !item.view,
                });
                this.tabs_name[index].data = this.$store.getters[`get${local}`];
              },
            },
          ],
        });
      }
    },
    defaultSettings() {
      let state = true;
      if (this.noty.notySettings.view) {
        this.$store.dispatch("addSnack", {
          title: this.$t("settings_modal.notifications__settings__title"),
          content: this.$t("snackbar.defaults_text"),
          time: 6000,
          icon: "settings",
          actions: [
            {
              name: this.$t("snackbar.cancel_action"),
              onTap: () => {
                state = false;
              },
            },
          ],
        });
      }

      setTimeout(() => {
        if (state) {
          localStorage.removeItem("settings");
          location.reload();
        }
      }, 7000);
    },
    async setLanguage(data) {
      const response = await this.$store.dispatch("getLanguageAPI", data);
      const currentLang = await this.$store.state.languages.languages;
      const langContent = await this.$store.state.languages.languageCategories;

      this.$i18n.setLocaleMessage(currentLang[0].short_name, langContent);
      this.$i18n.locale = currentLang[0].short_name;

      this.load = true;
      await this.$store.dispatch("loadSettings");
    },
  },
};
</script>

<style lang="scss">
* {
  list-style: none;
  font-family: "Inter";
}

.settings {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);

  &__tabs {
    &-header {
      display: inline-flex;
      margin-bottom: 10px;
      min-width: 100%;

      div {
        padding: 15px 14px;
        color: rgba(255, 255, 255, 0.699);
        transition: 200ms;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;

        &::before {
          content: "";
          height: 0px;
          background: transparent;
          position: absolute;
          display: block;
          bottom: 0;
          width: 0%;
          border-radius: 4px 4px 0 0;
          transition: 200ms;
        }

        &.active {
          color: white;

          &::before {
            background: #fff;
            height: 4px;
            width: 80%;
          }
        }
      }
    }
  }

  &__window {
    transition: all 0.3s ease-out;
    max-width: 400px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    background: #17212b;
    border-radius: 10px;
    color: white;
    position: relative;

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: rgba($color: #ffffff, $alpha: 0.1);
      }
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba($color: #ffffff, $alpha: 0);
      border-radius: 10px;

      &:hover {
        background: rgba($color: #ffffff, $alpha: 0.2);
      }
    }

    &-text {
      padding: 16px;
      background: #232e3c;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.315);
      color: rgba(255, 255, 255, 0.356);
      font-size: 13px;
      cursor: default;
      user-select: none;
    }

    &-close {
      background: transparent;
      cursor: pointer;
      border: none;
      outline: none;
      min-width: 40px;
      height: 40px;
      padding: 0 10px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 40px;
      transition: 200ms;

      &:hover {
        background: rgba($color: #ffffff, $alpha: 0.1);
      }
    }

    &-footer {
      text-align: center;
      padding: 16px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.356);
      user-select: none;
      cursor: default;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }

    &-title {
      padding: 10px 16px;
      font-size: 20px;
      font-weight: normal;
      user-select: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      background: #17212b;
      box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: 0.4);
    }

    &-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;
      padding: 16px 16px;
      cursor: pointer;
      transition: 200ms;
      user-select: none;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }

    &-name {
      color: rgba(255, 255, 255, 0.884);
      font-size: 15px;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &-desc {
      color: rgba(255, 255, 255, 0.473);
      font-size: 14px;
    }

    &-view {
      margin: 0 !important;

      &_input {
        height: 0 !important;
        width: 0 !important;
        display: none;
      }

      &_span {
        width: 40px;
        height: 26px;
        border-radius: 50px;
        background: #6c7883;
        display: flex;
        align-items: center;
        transition: 200ms;
        border: 2px solid;
        border-color: #39414b;
        cursor: pointer;

        &::before {
          content: "";
          color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 14px;
          height: 14px;
          border: 3px solid #6c7883;
          border-radius: 50%;
          background: #17212b;
          transition: 200ms;
          margin-left: 2px;
        }
      }

      &_input:checked~&_span {
        background: #5288c1;
        border-color: #5288c1;

        &::before {
          color: #a2b2c4;
          font-size: 10px;
          margin-left: 14px;
          border-color: #17212b;
        }
      }
    }
  }

  .lang {
    position: relative;
  }

  .langs {
    position: absolute;
    transform: translateY(-5px);
    z-index: 99;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #232e3c;

    &__item {
      cursor: pointer;
      width: 100%;
      padding: 16px;

      &:hover {
        background: rgba($color: #ffffff, $alpha: 0.1);
      }
    }
  }
}
</style>
