import Teachers from "@/api/teachers";
const teachers = {
  state: {
    teachers_list: [],
  },
  getters: {
  },
  mutations: {
    setTeachers(state, data) {
      state.teachers_list = data;
    },
  },
  actions: {
    async getTeachers(context) {
      const response = await Teachers.getTeachersList();
      if (response.status == 200) {
        context.commit("setTeachers", response.data);
      }
    },
    
  },
  modules: {},
};

export default teachers;
