<template>
  <div
    class="settings"
    @click="this.$emit('close')"
    @keyup.esc="this.$emit('close')"
    tabindex="0"
  >
    <div
      class="settings__window"
      :class="{ full_window: full }"
      :style="{ 'max-width': width + (full ? '' : 'px') }"
      ref="modal"
      @click.stop
    >
      <div class="settings__window-content-bg">
        <h2 class="settings__window-title">
          <button
            v-ripple
            @click="this.$emit('close')"
            class="settings__window-close"
          >
            <img src="@/assets/images/arrow.svg" alt="" />
            <span class="tooltiptext">{{ $t("navigation.press_esc") }}</span>
          </button>
          <span class="title-alt">
            <slot name="title"></slot>
          </span>
          <button
            v-ripple
            @click="this.$emit('close')"
            class="settings__window-close"
          >
            <img src="@/assets/images/close.svg" alt="" />
            <span class="tooltiptext">{{ $t("navigation.press_esc") }}</span>
          </button>
        </h2>
        <slot name="body"></slot>
        <slot></slot>
        <p v-ripple @dblclick="show = true" class="settings__window-footer">
          {{ $t("settings_modal.modal_footer") }} v{{ version }} | © PROWEB
          2010-{{ new Date().getFullYear() }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 400,
    },
    full: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Label",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      version: localStorage.versionApp,
      show: false,
    };
  },
  created() {
    document.querySelector("body").style.overflow = "hidden";
  },
  mounted() {
    const settings = [...document.querySelectorAll(".settings")];
    settings[settings.length - 1].focus();
  },
  unmounted() {
    const settings = [...document.querySelectorAll(".settings")];
    if (settings.length == 1) {
      document.querySelector("body").style.overflow = "auto";
    }
    if (settings.length > 1) {
      settings[settings.length - 2].focus();
    }
  },
};
</script>

<style lang="scss">
* {
  list-style: none;
  font-family: "Inter";
}

.settings {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);

  ul.list_info {
    li {
      padding: 16px;
      //   background: #232e3c;
      //   box-shadow: 0 0 5px rgba(0, 0, 0, 0.315);
      color: rgba(255, 255, 255, 0.356);
      font-size: 13px;
      cursor: default;
      user-select: none;
      color: #ccc;
      margin-bottom: 10px;
      display: flex;
      gap: 10px;
      align-items: center;

      &:hover {
        background: rgba($color: #ffffff, $alpha: 0.1);
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      img {
        float: left;
        margin-right: 10px;
      }
    }
  }

  &__window {
    transition: all 0.3s ease-out;
    max-width: 400px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    background: #17212b;
    border-radius: 10px;
    color: white;
    &-content-bg {
      background: #17212b;
    }

    &.full_window {
      max-width: 100%;
      max-height: 100vh;
      height: 100vh;
      border-radius: 0px;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: rgba($color: #ffffff, $alpha: 0.1);
      }
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba($color: #ffffff, $alpha: 0);
      border-radius: 10px;

      &:hover {
        background: rgba($color: #ffffff, $alpha: 0.2);
      }
    }

    .wrapper {
      background: transparent;
      padding: 16px;
    }

    &-text {
      padding: 16px;
      background: #232e3c;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.315);
      color: rgba(255, 255, 255, 0.356);
      font-size: 13px;
      cursor: default;
      user-select: none;
    }

    &-close {
      background: transparent;
      cursor: pointer;
      border: none;
      outline: none;
      width: 40px;
      height: 40px;
      padding: 0 10px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      transition: 200ms;
      position: relative;

      &:hover {
        background: rgba($color: #ffffff, $alpha: 0.1);

        .tooltiptext {
          visibility: visible;
          opacity: 1;
        }
      }

      .tooltiptext {
        visibility: hidden;
        width: max-content;
        max-width: 200px;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        text-align: center;
        padding: 5px 10px;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        bottom: -150%;
        right: 0;
        transition: 300ms;
        opacity: 0;
      }
    }

    &-footer {
      text-align: center;
      padding: 16px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.356);
      user-select: none;
      cursor: default;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }

    &-title {
      padding: 10px 16px;
      font-size: 20px;
      font-weight: normal;
      user-select: none;
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      z-index: 998;
      background: inherit;
      box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: 0.4);
      gap: 10px;

      .title-alt {
        flex-grow: 1;
      }
    }

    &-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;
      padding: 16px 16px;
      cursor: pointer;
      transition: 200ms;
      user-select: none;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }

    &-name {
      color: rgba(255, 255, 255, 0.884);
      font-size: 15px;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &-desc {
      color: rgba(255, 255, 255, 0.473);
      font-size: 14px;
    }

    &-view {
      &_input {
        visibility: hidden;
      }

      &_span {
        width: 40px;
        height: 26px;
        border-radius: 50px;
        background: #6c7883;
        display: flex;
        align-items: center;
        transition: 200ms;
        border: 2px solid;
        border-color: #39414b;
        cursor: pointer;

        &::before {
          content: "";
          color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 14px;
          height: 14px;
          border: 3px solid #6c7883;
          border-radius: 50%;
          background: #17212b;
          transition: 200ms;
          margin-left: 2px;
        }
      }

      &_input:checked ~ &_span {
        background: #5288c1;
        border-color: #5288c1;

        &::before {
          color: #a2b2c4;
          font-size: 10px;
          margin-left: 14px;
          border-color: #17212b;
        }
      }
    }
  }
}

.extra-title {
  background: #17212b;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.4);
  z-index: 998;
  position: sticky;
  top: 60px;
}
</style>
