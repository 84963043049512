<template>
  <div class="masterclass">
    <div class="masterclass_navigation" v-if="user.admin">
      <button class="link-icon white" type="button" @click="openModal">
        <img src="@/assets/images/add_card.svg" alt="" />
        {{ $t("navigation.add_card") }}
      </button>
    </div>
    <div class="masterclass_content">
      <div class="wrapper">
        <MCTheme
          :masterClasses="masterClasses"
          @updateCard="updateCard"
        ></MCTheme>
      </div>
    </div>

    <Teleport to="#dialog">
      <Transition name="slide-fade">
        <Dialog v-if="modalOpened" :width="630" @close="closeModal">
          <template v-slot:title v-if="updateModal">{{
            $t("master-class_page.edit_title")
          }}</template>
          <template v-slot:title v-else>
            {{ $t("master-class_page.modal__create_title") }}
          </template>
          <template v-slot:body>
            <ModalFormMC
              :updateModal="updateModal"
              :updateCardData="updateCardData"
            />
          </template>
        </Dialog>
      </Transition>
    </Teleport>
    <!-- >>>>>>> ITD-17-bag-fix-double-less -->
  </div>
</template>

<script>
import ModalFormMC from "@/components/MC/ModalFormMC";
import { mapActions, mapState } from "vuex";
import MCTheme from "../components/MC/MCTheme.vue";
export default {
  components: {
    MCTheme,
    ModalFormMC,
  },
  data() {
    return {
      modalOpened: false,
      updateModal: false,
      updateCardData: {},
    };
  },
  methods: {
    ...mapActions({
      getMasterClasses: "getMasterClasses",
    }),
    closeModal() {
      this.modalOpened = false;
      this.updateCardData = {};
      this.updateModal = false;
    },
    openModal() {
      this.modalOpened = true;
    },
    updateCard(data) {
      this.updateCardData = data;
      this.modalOpened = true;
      this.updateModal = true;
    },
  },
  computed: {
    ...mapState({
      masterClasses: (state) => state.master_classes.master_classes,
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    this.getMasterClasses();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.masterclass {
  &_navigation {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    background: #292929;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 768px) {
      justify-content: center;
      margin-top: 20px;
    }
    .add-btn {
      @include adaptivWidthLg(265, 230);
      width: 100%;
      @include adaptivHeightlg(50, 40);
      transition: 1s;
      @include adaptiv-fontlg(15, 12);
      @include adaptiv-lineHeightlg(32, 20);
      font-family: "Inter";
      font-weight: 500;
      margin-left: 20px;
      text-transform: uppercase;
      border: none;
      border-radius: 10px;
      color: #303030;
      @include adaptivMarginlg(0, 37, 50, 0, 0, 10, 0, 0);
      @media (max-width: 768px) {
        @include adaptivWidthmd(250, 150);
        @include adaptiv-fontmd(10, 8);
        color: #303030;
        margin: 0 2px;
      }
      &:hover {
        background: #303030;
        color: #fff;
        transition: background-color 1s;
        border: 1px solid #f2f2f2;
      }
    }
  }
}
</style>
