import Api from ".";

class Banner extends Api {
    async getTeachersList (){
        let response
        try {
            response = await this.client('banners/all/')
        } catch (error) {
            response = error.response
        }
        return response
    }
}
export default new Banner()