<template>
  <div class="teachers-list">
    <h3 class="teachers-list__title">{{ course.name }}</h3>
    <div class="teachers-list__body">
      <ListTileTeacher
        v-for="(key, index) in teachers"
        :key="index"
        :teacher="key"
        v-ripple
        @click="teacherActive = key.id"
      />
    </div>
  </div>
  <Teleport to="#dialog">
    <Transition name="slide-fade">
      <Dialog
        v-if="teacherActive != -1"
        :width="600"
        @close="teacherActive = -1"
      >
        <template v-slot:title>{{ $t("launch_modal.teacher") }}</template>
        <template v-slot:body>
          <TeacherModal :teacher="teacherActive" />
        </template>
      </Dialog>
    </Transition>
  </Teleport>
</template>

<script>
import { mapState } from "vuex";
import ListTileTeacher from "@/components/ListTileTeacher.vue";
import TeacherModal from "@/components/TeacherModal.vue";
export default {
  props: {
    course: Object,
  },
  components: {
    ListTileTeacher,
    TeacherModal,
  },
  data() {
    return {
      teacherActive: -1,
    };
  },
  computed: {
    ...mapState({
      teachers: function (state) {
        const teachers = JSON.parse(
          JSON.stringify(state.teachers.teachers_list)
        );
        const teacher = [];
        for (const key in teachers) {
          if (teachers[key].course == this.course.id && teachers[key].active) {
            teacher.push(teachers[key]);
          }
        }
        return teacher;
      },
    }),
  },
};
</script>

<style lang="scss">
.teachers-list {
  .teacher__header {
    cursor: pointer;
    transition: 200ms;
    &:hover {
      background: rgba($color: #ffffff, $alpha: 0.1);
    }
  }
  &__title {
    padding: 16px;
    text-align: center;
  }
}
</style>
