<template>
  <div class="archive-nav">
    <label class="archive-nav-label">
      {{ $t("launch_modal.course") }}:
      <select class="archive-nav-select" v-model="params.course_id">
        <option class="archive-nav-option" :value="null">
          {{ $t("archive.unchosed") }}
        </option>
        <option
          class="archive-nav-option"
          v-for="course in courses_list"
          :key="course.id"
          :value="course.id"
        >
          {{ course.name }}
        </option>
      </select>
    </label>
    <label class="archive-nav-label">
      {{ $t("launch_modal.language") }}:
      <select class="archive-nav-select" v-model="params.language">
        <option class="archive-nav-option" :value="null">
          {{ $t("archive.unchosed") }}
        </option>
        <option class="archive-nav-option" value="ru">
          {{ $t("archive.russian_lang") }}
        </option>
        <option class="archive-nav-option" value="uz">
          {{ $t("archive.uzbek_lang") }}
        </option>
      </select>
    </label>
    <label class="archive-nav-label">
      {{ $t("launch_modal.teacher") }}:
      <select class="archive-nav-select" v-model="params.teacher_id">
        <option class="archive-nav-option" :value="null">
          {{ $t("archive.unchosed") }}
        </option>
        0
        <option
          class="archive-nav-option"
          v-for="teacher in teacherFilteredList"
          :key="teacher"
          :value="teacher.id"
        >
          {{
            teacher.last_name +
            " " +
            teacher.first_name +
            " " +
            teacher.language
          }}
        </option>
      </select>
    </label>
    <label class="archive-nav-label">
      {{ $t("launch_modal.filial") }}:
      <select class="archive-nav-select" v-model="params.branch_id">
        <option class="archive-nav-option" :value="null">
          {{ $t("archive.unchosed") }}
        </option>
        <option
          class="archive-nav-option"
          v-for="br in branch_list"
          :key="br.id"
          :value="br.id"
        >
          {{ br.name }}
        </option>
      </select>
    </label>
    <label class="archive-nav-label"
      >{{ $t("archive.from") }}:
      <input
        class="archive-nav-input"
        v-model="params.date_gte"
        type="date"
        name="date_gte"
    /></label>
    <label class="archive-nav-label"
      >{{ $t("archive.to") }}:
      <input
        class="archive-nav-input"
        v-model="params.date_lte"
        type="date"
        name="date_lte"
    /></label>
    <button class="archive-nav-btn" @click="getArchive">
      {{ $t("archive.show") }}
    </button>
  </div>
  <div class="wrapper" v-if="start_courses != null">
    <CourseColumn
      :course="start_course"
      v-for="start_course in start_courses"
      :key="start_course.id"
      :currentLanguage="currentLanguage"
    />
  </div>
</template>
<script>
import CourseColumn from "@/components/launches/CourseColumn";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    CourseColumn,
  },
  data() {
    return {
      params: {
        course_id: null,
        language: null,
        teacher_id: null,
        branch_id: null,
        date_gte: new Date(new Date() - 30 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0],
        date_lte: new Date().toISOString().split("T")[0],
      },
      currentLanguage: "all",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      branch_list: (state) => state.branches.branches_list,
      teachers_list: (state) => state.teachers.teachers_list,
      courses_list: (state) => state.courses.courses_list,
      start_courses: (state) => state.launches_archive.start_courses,
      store_params: (state) => state.launches_archive.params,
    }),
    teacherFilteredList() {
      const result = this.teachers_list.filter(
        (data) =>
          (!this.params.course_id && !this.params.language) ||
          (!this.params.language && data.course == this.params.course_id) ||
          (!this.params.course_id && data.language == this.params.language) ||
          (data.language == this.params.language &&
            data.course == this.params.course_id)
      );
      return result;
    },
  },
  methods: {
    ...mapActions({
      getBranches: "getBranches",
      getTeachers: "getTeachers",
      getCourses: "getCourses",
      getArchiveLaunches: "getArchiveLaunches",
    }),
    getArchive() {
      if (this.params.language == null) {
        this.currentLanguage = "all";
      } else {
        this.currentLanguage = this.params.language;
      }
      this.getArchiveLaunches({ params: this.params });
    },
  },
  mounted() {
    this.getBranches();
    this.getTeachers();
    this.getCourses();
    if (this.store_params) {
      this.params = this.store_params.params;
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/grid/grid";

.navigation {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #292929;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
}

.courses {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #292929;
  @include adaptivPaddinglg(20, 20, 20, 20, 10, 10, 25, 10);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;

  @media (max-width: 768px) {
    position: static;
  }

  &-btn {
    @include adaptivWidthLg(230, 180);
    width: 100%;
    @include adaptiv-fontlg(15, 10);
    font-family: "Inter";
    font-weight: 500;
    color: #fff;
    margin: 1px;
    border: 1px solid #303030;
    @include adaptivPaddinglg(8, 8, 8, 8, 2, 2, 5, 5);
    border-radius: 4px;

    @media (max-width: 768px) {
      @include adaptivWidthmd(150, 100);
      @include adaptivPaddingmd(0, 0, 15, 15, 0, 0, 10, 10);
      @include adaptiv-fontmd(10, 7);
    }

    &:hover {
      border: 1px solid #fff;
      transition: background-color 0.5s;
    }

    &.active {
      border: 1px solid #fff;
      background: #303030 !important;
    }
  }
}

.add-btn {
  @include adaptivWidthLg(265, 230);
  width: 100%;
  @include adaptivHeightlg(50, 40);
  transition: 1s;
  @include adaptiv-fontlg(15, 12);
  @include adaptiv-lineHeightlg(32, 20);
  font-family: "Inter";
  font-weight: 500;
  margin-left: 20px;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  color: #303030;
  @include adaptivMarginlg(0, 20, 0, 0, 0, 10, 0, 0);

  @media (max-width: 768px) {
    @include adaptivWidthmd(250, 150);
    @include adaptiv-fontmd(10, 8);
    color: #303030;
    margin: 0 2px;
  }

  &:hover {
    background: #303030;
    color: #fff;
    transition: background-color 1s;
    border: 1px solid #f2f2f2;
  }
}

.wrapper {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: #292929;
  @include adaptivPaddinglg(0, 0, 20, 70, 0, 0, 10, 35);

  @media (max-width: 768px) {
    flex-direction: column;
    display: flex;
    align-items: center;
  }
}
.archive-nav {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @include adaptivMarginlg(0, 0, 50, 50, 0, 0, 25, 25);
  &-label {
    width: 250px;
    @include adaptivMarginlg(10, 10, 0, 0, 8, 8, 0, 0);
    @include adaptiv-fontlg(15, 10);
    font-family: "Inter";
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
  }
  &-option {
    background: #303030;
  }
  &-select,
  &-input,
  &-btn {
    width: 250px;
    height: 40px;
    border-radius: 5px;
    @include adaptiv-fontlg(15, 10);
    font-family: "Inter";
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    @include adaptivPaddinglg(5, 5, 5, 5, 3, 3, 3, 3);
    margin-top: 2px;
  }
  &-select {
    background: transparent;
    border: 1px solid #fff;
  }
  &-input,
  &-btn {
    background: transparent;
    border: 1px solid #fff;
    @include adaptivPaddinglg(5, 5, 5, 5, 3, 3, 3, 3);
  }
  &-btn {
    @include adaptiv-fontlg(15, 10);
    font-family: "Inter";
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      background: #fff;
      color: #303030;
      transition: 0.5s;
    }
  }
}
</style>
