import Api from ".";

class Teachers extends Api {
    async getTeachersList (){
        let response
        try {
            response = await this.client('teachers/all/')
        } catch (error) {
                window.location = 'https://api.proweb.uz/logout'
            response = error.response
        }
        return response
    }
}
export default new Teachers()