import Courses from "@/api/courses";
const teachers = {
  state: {
    courses_list: [],
  },
  getters: {
  },
  mutations: {
    setCourses(state, data) {
      state.courses_list = data;
    },
    
  },
  actions: {
    async getCourses(context) {
      const response = await Courses.getCoursesList();
      if (response.status == 200) {
        context.commit("setCourses", response.data.result);
        localStorage.courses = JSON.stringify(response.data.result)
      }
    },
    
  },
  modules: {},
};

export default teachers;
