import Api from ".";

class Booking extends Api {
  async getBookingList() {
    let response;
    try {
      const res = await fetch("https://analytics.proweb.uz/api/v1/count_registered_for_open_lesson/");
      response = await res.json();
    } catch (error) {
      response = error.response;
    }
    return response;
  }
}
export default new Booking();
