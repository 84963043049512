import Auth from '@/api/auth'
 const auth = {
    state: {
        user: {},
        authorized: false
    },
    mutations: {
        setAuth(state, boolean){
            state.authorized = boolean
        },
        setUserData(state, userData){
            state.user = userData
        }
    },
    actions: {
        async login(context, userData){
            const loginResponse = await Auth.login(userData)
            if (loginResponse.status == 200) {
                context.commit('setAuth', true)
                context.commit('setUserData', loginResponse.userData)
                
            }
            console.log(loginResponse.user_id);
            return loginResponse
        },
        async isAuthorized(context){
            if (context.state.authorized) {
                return true
            }
            else {
                const authResponse = await Auth.isAuthorized()
                if (!authResponse) {
                    return false
                }
                if (authResponse.status == 200) {
                    context.commit('setAuth', true)
                    context.commit('setUserData', authResponse.userData)
                    return true
                }
                else {
                    return false
                }
            }
        },
        logout(context){
            Auth.logout()
            context.commit('setAuth', false)
            context.commit('setUserData', {})
            return true
        }
        
    },
    modules: {
    }
  }
  
  export default auth