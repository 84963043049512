import Paid from "@/api/paid";
const paid = {
  state: {
    paid_list: [],
  },
  getters: {

  },
  mutations: {
    setPaid(state, data) {
      state.paid_list = data;
    },
  },
  actions: {
    async getPaid(context) {
      const response = await Paid.getPaidList();
      context.commit("setPaid", response);
    },
  },
};

export default paid;
