<template>
  <div class="ads">
    <div class="ads__list">
      <button class="link-icon green" @click="open = true">
        Создать баннер
      </button>
      <AdsItem :banner="key" v-for="(key, index) in banners" :key="index" />
    </div>
  </div>
  <Teleport to="#dialog">
    <Transition name="slide-fade">
      <Dialog v-if="open" :width="600" @close="open = false">
        <template v-slot:title> Создать баннер </template>
        <template v-slot:body>
          <AdsModal />
        </template>
      </Dialog>
    </Transition>
  </Teleport>
</template>

<script>
import AdsItem from "@/components/AdsItem";
import { mapState, mapActions } from "vuex";
import AdsModal from "@/components/AdsModal";
export default {
  components: {
    AdsItem,
    AdsModal,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    ...mapState({
      banners: (state) => state.banner.banners_list,
    }),
  },
  methods: {
    ...mapActions({
      getBanners: "getBanners",
    }),
  },
  mounted() {
    this.getBanners();
  },
};
</script>

<style lang="scss">
.ads {
  max-width: 600px;
  width: 100%;
  margin: 20px auto;
  &__list {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>