import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import FontAwesomeIcon from "@/fontawesome-icons";
import Dialog from "@/components/Dialog.vue";
import Ripple from "@/ripple";
import Mixin from '@/mixin';

const i18n = createI18n({
});

createApp(App)
  .directive("ripple", Ripple)
  .use(store)
  .use(i18n)
  .use(router)
  .mixin(Mixin)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("Dialog", Dialog)
  .mount("#app");