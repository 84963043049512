import {Api, ErrorRequest} from '../../api/fetch' 

const languages = {
  state: {
    languages: [],
    languageCategories: [],
    currentLanguage: null,
    languageVersion: null,
  },
  mutations: {
    setLanguages(state, data) {
      state.languages = data
    },
    setLanguageCategories(state, data) {
      state.languageCategories = data
    }
  },
  getters: {
    getCurrentLanguage(state){
      return state.languages
    },
    getLanguage(state) {
      return state.languageCategories
    }
  },
  actions: {
    async getLanguageAPI({commit}, short) {
      const api = new Api() 
      let languages_list = null
      let firstLang = null
      let selectedLang = null
      let lang = null

      if(!localStorage.uuid) api.generateUuid()

      const response = await api.domain('https://langpro.proweb.uz/api/v1/').get('languages/f92a972ac936ead7776234976e7f2d54/languages/', false)
      commit("setLanguages", response.results)
      if(response instanceof ErrorRequest) {
      }else{
        if(response.results.length > 0) {
          languages_list = response.results
          firstLang = languages_list.find(item => item.short_name == short)
          localStorage.activeLang = short
          let activeLang = localStorage.activeLang ?? firstLang.short_name
          
          const db = await api.select(activeLang ? activeLang : firstLang)
          const seachedLang = languages_list.find(item => activeLang == item.short_name)

          if(seachedLang == undefined) {
            const boolDB = db == undefined
            activeLang = firstLang.short_name
            localStorage.activeLang = firstLang.short_name
            seachedLang = firstLang
          }else{
            selectedLang = seachedLang
          }


          if (db == undefined || db.version != selectedLang.version) {
            const boolDB = db == undefined
            if(boolDB && !activeLang) {
              localStorage.activeLang = firstLang.short_name
              activeLang = localStorage.activeLang
            }
            let request = `${activeLang}/${selectedLang.version}`
            const language = await api.domain('https://langpro.proweb.uz/api/v1/').get(`languages/f92a972ac936ead7776234976e7f2d54/${request}`, false)
            if(language instanceof ErrorRequest) {
              if (!boolDB) {
                setLocaleMessage(langActive, db.result.categories)
                locale.value = langActive
              }
            }else{
              if(language.results) {
                if(language.results.categories) {
                  lang = language.results.categories
                  const deleteEll = await api.delete(activeLang.length > 0 ? activeLang : firstLang.short_name)
                  if (deleteEll == undefined) {
                    api.insert({ id: language.results.short_name, version: language.results.version, result: language.results.categories })
                    // setLocaleMessage(langActive, language.results.categories)
                    // locale.value = langActive
                    commit("setLanguageCategories", language.results.categories)
                  }
                }
              }
            }
          }else{
            commit("setLanguageCategories", db.result)
            // const language = await api.domain('https://langpro.proweb.uz/api/v1/').get(`languages/f92a972ac936ead7776234976e7f2d54/${short}/1`, false)
          }
        }
      }
    },
  },
}

export default languages