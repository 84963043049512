import Launches from "@/api/launches";
const teachers = {
  state: {
    branches_list: [],
  },
  getters: {

  },
  mutations: {
    setBranches(state, data) {
      state.branches_list = data;
    },
    
  },
  actions: {
    async getBranches(context) {
      const branchResponse = await Launches.getBranches();
      if (branchResponse.status == 200) {
        context.commit("setBranches", branchResponse.data.result);
      }
    },
    
  },
  modules: {},
};

export default teachers;
