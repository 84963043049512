<template>
  <template v-if="load">
    <Navigation v-if="isAuth" />
    <router-view />
    <Snackbars />
  </template>
  <div id="dialog"></div>
  <Teleport to="#dialog">
    <Transition name="slide-fade">
      <Dialog v-if="show" @close="close">
        <template v-slot:title>{{ $t("navigation.update_title") }} v{{ version }}</template>
        <template v-slot:body>
          <Update />
        </template>
      </Dialog>
    </Transition>
  </Teleport>
</template>
<script>
import { mapState } from "vuex";
import Navigation from "@/components/Navigation";
import Snackbars from "@/components/Snackbar/Snackbars";
import Update from "@/components/Update";


export default {
  components: {
    Navigation,
    Update,
    Snackbars,
  },
  data() {
    return {
      default_lang: null,
      show: false,
      version: 0,
      load: false,
    };
  },
  created() {
    this.$store.dispatch("loadSettings");
    this.$store.dispatch("loadnoty");
  },
  computed: {
    ...mapState({
      isAuth: (state) => state.auth.authorized,
    }),
  },
  methods: {
    close() {
      this.show = false;
    },
    open() {
      this.show = true;
    },
    async setLanguage(data) {
      const response = await this.$store.dispatch("getLanguageAPI", data);
      const currentLang = await this.$store.state.languages.languages;
      const langContent = await this.$store.state.languages.languageCategories;

      this.$i18n.setLocaleMessage(currentLang[0].short_name, langContent);
      this.$i18n.locale = currentLang[0].short_name;

      this.load = true;
    },

    async getDb() {
      const base = {
        newStartCard: {
          name: db.result.settings_modal.generals__card_updated_design__title,
          desc: db.result.settings_modal.generals__card_updated_design__desc,
          view: false,
        },
        viewEmptyCourse: {
          name: db.result.settings_modal.generals__show_empty_courses__title,
          desc: db.result.settings_modal.generals__show_empty_courses__desc,
          view: true,
        },
        viewCountLid: {
          name: db.result.settings_modal.generals__show_leads_count__title,
          desc: db.result.settings_modal.generals__show_leads_count__desc,
          view: true,
        },
        multiSelectCourse: {
          name: db.result.settings_modal.generals__courses_multiselect__title,
          desc: db.result.settings_modal.generals__courses_multiselect__desc,
          view: false,
        },
        sales: {
          name: db.result.settings_modal.generals__show_sales__title,
          desc: db.result.settings_modal.generals__show_sales__desc,
          view: true,
        },
        crmBtn: {
          name: db.result.settings_modal.generals__show_crm_btn__title,
          desc: db.result.settings_modal.generals__show_crm_btn__desc,
          view: true,
        },
        langCourse: {
          name: db.result.settings_modal.generals__show_empty_directions__title,
          desc: db.result.settings_modal
            .settings_modal__generals__show_empty_directions__desc,
          view: false,
        },
        mkOpenLess: {
          name: db.result.settings_modal.generals__mk_open_less__title,
          desc: db.result.settings_modal
            .settings_modal__generals__mk_open_less__desc,
          view: false,
        },
        approximateDay: {
          name: db.result.settings_modal
            .settings_modal__generals__approximate_day__title,
          desc: db.result.settings_modal
            .settings_modal__settings_modal__generals__approximate_day__desc,
          view: true,
        },
      };
      this.$store.commit("setBase", base);
    },
  },
  async mounted() {
    if (this.$store.state.courses.courses_list.length == 0) {
      await this.$store.dispatch("getCourses");
    }
    if (localStorage.activeLang) {
      this.default_lang = localStorage.activeLang;
    } else {
      this.default_lang = "ru";
    }
    this.setLanguage(this.default_lang);
    const newVersion = 2.8;
    let version = localStorage.versionApp;
    if (newVersion != version) {
      this.show = true;
      this.version = newVersion;
      document.querySelector("body").style.overflow = "hidden";
    }
    localStorage.versionApp = newVersion;
  },
};
</script>

<style lang="scss">
body {
  background: #292929;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.new {
  position: relative;
  overflow: inherit !important;
  background: rgba(255, 255, 255, 0.05);

  &::before {
    content: "new";
    position: absolute;
    right: -5px;
    top: -5px;
    padding: 2px 5px;
    font-size: 10px;
    text-transform: uppercase;
    border-radius: 100px;
    z-index: 1;
    background: green;
  }
}

.slide-card-leave-to {
  opacity: 0;
  transform: translateY(-20px) scale(0.9) rotateX(90deg);
}

.slide-card-enter-from {
  opacity: 0;
  transform: translateY(20px) rotateX(90deg) scale(0.9);
}

// .slide-fade-teacher-enter-active {
//   transition-delay: 500ms;

// }
.slide-fade-teacher-enter-from {
  transition-delay: 2000ms;
}

.slide-fade-teacher-enter-from,
.slide-fade-teacher-leave-to {
  opacity: 0;
  height: 0;
}

.slide-fade-teacher-enter-from {
  transform: translateX(100vw);
}

.slide-fade-teacher-leave-to {
  transform: translateX(-100vw);
}

.slide-fade-set-enter-from {
  transition-delay: 2000ms;
}

.slide-fade-set-enter-from,
.slide-fade-set-leave-to {
  opacity: 0;
}

.slide-fade-set-enter-from {
  transform: translateY(100px) scale(0.8);
}

.slide-fade-set-leave-to {
  transform: translateY(-100px) scale(0.8);
}
</style>
