<template>
  <div class="snackbars__item">
    <h3 class="snackbars__item-title" v-if="data.title">
      <span>
        <img :src="require(`@/assets/images/${data.icon}.svg`)" v-if="data.icon" alt="" />
        {{ data.title }}
      </span>
      <button v-ripple @click="closeSnacks()" class="settings__window-close">
        <img src="@/assets/images/close.svg" alt="" />
      </button>
    </h3>
    <p class="snackbars__item-content" v-html="data.content"></p>
    <div class="snackbars__item-extra" v-if="data.extra">
      <img class="snackbars__item-img" :src="data.extra.img" v-if="data.extra.img" alt="">
      <p class="snackbars__item-text" :class="{no_img: !data.extra.img}" v-if="data.extra.text" v-html="data.extra.text"></p>
    </div>
    <div class="snackbars__item-actions" v-if="data.actions">
      <button
        v-ripple:grey
        class="snackbars__item-btn"
        v-for="(action, index) in data.actions"
        :key="index"
        @click="closeSnacks(action.onTap)"
      >
        {{ action.name }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    closeSnacks(onTap) {
      if (onTap) {
        onTap();
      }
      this.$store.dispatch("removeSnack", this.data.id);
    },
  },
};
</script>

<style>
</style>