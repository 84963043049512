<template>
  <div class="modal" @click.stop>
    <form @submit.prevent="formSubmit">
      <div class="masterClass">
        <div class="masterClass_block">
          <label>
            {{ $t("master-class_page.modal__topic") }}:
            <input type="text" v-model="form.theme" />
          </label>
          <label>
            {{ $t("master-class_page.modal__speaker") }}:
            <input type="text" v-model="form.speaker" />
          </label>
          <label>
            {{ $t("launch_modal.date") }}:
            <input type="date" v-model="form.date" />
          </label>
          <label>
            {{ $t("launch_modal.time") }}:
            <input type="time" v-model="form.time" />
          </label>
        </div>
        <div class="masterClass_block">
          <label>
            {{ $t("master-class_page.auditory") }}:
            <input type="text" v-model="form.place" />
          </label>
          <label>
            {{ $t("master-class_page.spot_count") }}:
            <input type="number" v-model="form.capacity" />
          </label>
          <label>
            {{ $t("launch_modal.language") }}:
            <select v-model="form.language">
              <option value="ru">{{ $t("archive.russian_lang") }}</option>
              <option value="uz">{{ $t("archive.uzbek_lang") }}</option>
            </select>
          </label>
        </div>
      </div>
      <button class="create link-icon blue" type="submit" v-if="!updateModal">
        <img src="@/assets/images/save.svg" alt="" />
        {{ $t("master-class_page.modal__create") }}
      </button>
      <button class="save link-icon blue" type="submit" v-if="updateModal">
        <img src="@/assets/images/save.svg" alt="" />
        {{ $t("launch_modal.save") }}
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  props: {
    updateModal: Boolean,
    master: Object,
    updateCardData: Object,
  },
  data() {
    return {
      form: {
        capacity: 30,
        date: new Date().toISOString().split("T")[0],
        language: "ru",
        place: "",
        speaker: "",
        theme: "",
        time: "10:00",
      },
    };
  },
  computed: {
    ...mapGetters({
      coursesList: "coursesList",
    }),
    ...mapState({
      branch_list: (state) => state.launches.branch,
      teachers_list: (state) => state.teachers.teachers_list,
    }),
  },
  methods: {
    ...mapActions({
      addMasterClass: "addMasterClass",
      updateMasterClass: "updateMasterClass",
    }),
    formSubmit() {
      if (this.updateModal) {
        this.updateMasterClass(this.form).then((response) => {
          if (response.status == 200) {
            this.closeModal();
          }
        });
      } else {
        this.addMasterClass(this.form).then((response) => {
          if (response.status == 200) {
            this.closeModal();
          }
        });
      }
    },

    closeModal() {
      this.$emit("close");
    },
  },
  mounted() {
    if (this.updateModal) {
      this.form = JSON.parse(JSON.stringify(this.updateCardData));
    }
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/grid/grid";
.modal {
  form {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include adaptivPaddinglg(15, 15, 15, 15, 10, 10, 10, 10);
  }
}
.masterClass {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  &_block {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @include adaptiv-fontlg(12, 7);
    @include adaptiv-lineHeightlg(32, 20);
    font-family: "Inter";
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    .day,
    .lesson {
      display: flex;
      align-items: center;
    }
    .time {
      margin: 20px 5px 0;
      &-block {
        display: flex;
        align-items: center;
      }
    }
    &-title {
      margin-top: 20px;
    }
  }
}
select,
input {
  width: 200px;
  margin: 0 10px;
  border: 1px solid #5288c1;
  background: #232e3c;
  color: white;
  padding: 5px;
  border-radius: 10px;
  &:disabled {
    background: rgb(65, 65, 65);
  }
  &.auto {
    width: auto;
  }
}

.day-select,
.day-input,
.day-label,
.time-select,
.time-input,
.time-label {
  margin: 0 3px;
}
.lesson-label {
  display: flex;
  justify-content: flex-start;
}

.day-checkbox,
.time-checkbox,
.checkbox,
.lesson-checkbox {
  margin: 0 2px;
  width: auto;
}
label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px;
}

.open-lesson {
  border: 1px solid #c1c1c1;
  margin-bottom: 10px;
  padding: 10px;
}
</style>
