import functions from '@/functions.js'
import translate from '../../translate.json'
import { Api } from '../../api/fetch'

let settingsKeys = {
  newStartCard: {
    name: translate.settings_modal.generals__card_updated_design__title,
    desc: translate.settings_modal.generals__card_updated_design__desc,
    view: false
  },
  viewEmptyCourse: {
    name: translate.settings_modal.generals__show_empty_courses__title,
    desc: translate.settings_modal.generals__show_empty_courses__desc,
    view: true
  },
  viewCountLid: {
    name: translate.settings_modal.generals__show_leads_count__title,
    desc: translate.settings_modal.generals__show_leads_count__desc,
    view: true
  },
  multiSelectCourse: {
    name: translate.settings_modal.generals__courses_multiselect__title,
    desc: translate.settings_modal.generals__courses_multiselect__desc,
    view: false
  },
  sales: {
    name: translate.settings_modal.generals__show_sales__title,
    desc: translate.settings_modal.generals__show_sales__desc,
    view: true,
  },
  crmBtn: {
    name: translate.settings_modal.generals__show_crm_btn__title,
    desc: translate.settings_modal.generals__show_crm_btn__desc,
    view: true,
  },
  langCourse: {
    name: translate.settings_modal.generals__show_empty_directions__title,
    desc: translate.settings_modal.settings_modal__generals__show_empty_directions__desc,
    view: false,
  },
  mkOpenLess: {
    name: translate.settings_modal.generals__mk_open_less__title,
    desc: translate.settings_modal.settings_modal__generals__mk_open_less__desc,
    view: false,
  },
  approximateDay: {
    name: translate.settings_modal.settings_modal__generals__approximate_day__title,
    desc: translate.settings_modal.settings_modal__settings_modal__generals__approximate_day__desc,
    view: true,
  },
}
const state = {
  settings: {},
  base: {}
}

const mutations = {
  loadSettings(state, data) {
    state.settings = data
  },
  setBase(state, data) {
    state.base = data
  }
}
const actions = {
  async loadSettings({ commit }, reset = false) {
    if(reset) {
      localStorage.removeItem('settings')
      commit('loadSettings', {})
    }
    const api = new Api()
    let activeLang = localStorage.activeLang
    let db = await api.select(activeLang)
    const settings = localStorage.settings ?? '{}'
    const settingsObject = JSON.parse(settings)
    
    if(db) {
      settingsKeys = {
        newStartCard: {
          name: db.result.settings_modal.generals__card_updated_design__title,
          desc: db.result.settings_modal.generals__card_updated_design__desc,
          view: false
        },
        viewEmptyCourse: {
          name: db.result.settings_modal.generals__show_empty_courses__title,
          desc: db.result.settings_modal.generals__show_empty_courses__desc,
          view: true
        },
        viewCountLid: {
          name: db.result.settings_modal.generals__show_leads_count__title,
          desc: db.result.settings_modal.generals__show_leads_count__desc,
          view: true
        },
        multiSelectCourse: {
          name: db.result.settings_modal.generals__courses_multiselect__title,
          desc: db.result.settings_modal.generals__courses_multiselect__desc,
          view: false
        },
        sales: {
          name: db.result.settings_modal.generals__show_sales__title,
          desc: db.result.settings_modal.generals__show_sales__desc,
          view: true,
        },
        crmBtn: {
          name: db.result.settings_modal.generals__show_crm_btn__title,
          desc: db.result.settings_modal.generals__show_crm_btn__desc,
          view: true,
        },
        langCourse: {
          name: db.result.settings_modal.generals__show_empty_directions__title,
          desc: db.result.settings_modal.settings_modal__generals__show_empty_directions__desc,
          view: false,
        },
        mkOpenLess: {
          name: db.result.settings_modal.generals__mk_open_less__title,
          desc: db.result.settings_modal.settings_modal__generals__mk_open_less__desc,
          view: false,
        },
        approximateDay: {
          name: db.result.settings_modal.settings_modal__generals__approximate_day__title,
          desc: db.result.settings_modal.settings_modal__settings_modal__generals__approximate_day__desc,
          view: true,
        },
      }
    }
    
    
    for(const key in settingsKeys) {
      const isExists = functions.getSettingKey(settingsObject, key, false)
      if (isExists != null) {
        settingsObject[key] = {
          name: settingsKeys[key].name,
          desc: settingsKeys[key].desc,
          view: settingsObject[key].view,
        } 
      }else {
        settingsObject[key] = settingsKeys[key]
      }
    }
    functions.setStorage(settingsObject, 'settings')
    
    commit('loadSettings', settingsObject)
  },
  updatesettings({ commit, state }, data) {
    const settings = state.settings
    settings[data.key].view = !!data.value
    functions.setStorage(settings,  'settings')
    commit('loadSettings', settings)
  }
}
const getters = {
  getsettings(state) {
  return state.settings
  },
}
  
export default {
  state,
  mutations,
  actions,
  getters
}