<template>
  <div class="create__banner">
    <Transition name="scale">
      <div class="ads__list-config" v-if="image">
        <div class="ads__list-language">
          <span :class="{ active: lang == 'ru' }" @click="lang = 'ru'">RU</span>
          <span :class="{ active: lang == 'uz' }" @click="lang = 'uz'">UZ</span>
        </div>
        <div class="ads__list-itemelement">
          <div class="ads__list-item">
            <img class="ads__list-banner" :src="image" alt="" />
            <div class="ads__list-body">
              <div class="ads__list-content">
                <div class="ads__list-lang" :class="{ dark: is_darkmode }">
                  <h2>{{ this[`title_${lang}`] }}</h2>
                  <p>{{ this[`content_${lang}`] }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <div class="create__banner-block">
      <h2 class="create__banner-title">Фон баннера</h2>
      <div class="create__banner-input">
        <p class="create__banner-inputtitle">Картинка</p>
        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          @change="onFileChange"
        />
      </div>
    </div>
    <div class="create__banner-block">
      <h2 class="create__banner-title">На русском</h2>
      <div class="create__banner-input">
        <p class="create__banner-inputtitle">Заголовок</p>
        <input type="text" placeholder="Заголовок" v-model="title_ru" />
      </div>
      <div class="create__banner-input">
        <p class="create__banner-inputtitle">Подзаголовок</p>
        <input type="text" placeholder="Заголовок" v-model="content_ru" />
      </div>
    </div>
    <div class="create__banner-block">
      <h2 class="create__banner-title">На узбекском</h2>
      <div class="create__banner-input">
        <p class="create__banner-inputtitle">Заголовок</p>
        <input type="text" placeholder="Заголовок" v-model="title_uz" />
      </div>
      <div class="create__banner-input">
        <p class="create__banner-inputtitle">Подзаголовок</p>
        <input type="text" placeholder="Заголовок" v-model="content_uz" />
      </div>
    </div>
    <div class="create__banner-block">
      <h2 class="create__banner-title">Дата и время начало</h2>
      <div class="create__banner-input">
        <p class="create__banner-inputtitle">Дата</p>
        <input type="date" v-model="start_date" />
      </div>
      <div class="create__banner-input">
        <p class="create__banner-inputtitle">Время</p>
        <input type="time" v-model="start_time" />
      </div>
    </div>
    <div class="create__banner-block">
      <h2 class="create__banner-title">Дата и время окончания</h2>
      <div class="create__banner-input">
        <p class="create__banner-inputtitle">Дата</p>
        <input type="date" v-model="end_date" />
      </div>
      <div class="create__banner-input">
        <p class="create__banner-inputtitle">Время</p>
        <input type="time" v-model="end_time" />
      </div>
    </div>
    <div class="settings__window-item" @click="is_active = !is_active">
      <p class="settings__window-info">
        <span class="settings__window-name">Активировать</span>
        <span class="settings__window-desc"
          >При активации все остальные активные банеры будут отключены</span
        >
      </p>

      <label class="settings__window-view">
        <input
          class="settings__window-view_input"
          type="checkbox"
          v-model="is_active"
          :checked="is_active"
          @click="is_active =!is_active"
        />
        <span class="settings__window-view_span"></span>
      </label>
    </div>
    <div class="settings__window-item" @click="is_darkmode = !is_darkmode">
      <p class="settings__window-info">
        <span class="settings__window-name">Тёмная тема банера</span>
        <span class="settings__window-desc">
          Если Ваш банер на темном фоне то включи данный галочку для того что бы
          текст стал белым
        </span>
      </p>
      <label class="settings__window-view">
        <input
          class="settings__window-view_input"
          type="checkbox"
          :checked="is_darkmode"
          @click="is_darkmode= !is_darkmode"
        />
        <span class="settings__window-view_span"></span>
      </label>
    </div>
  </div>
</template>

<script>
import functions from "@/functions";
export default {
  components: {},
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      title_ru: "",
      title_uz: "",
      content_ru: "",
      content_uz: "",
      is_active: true,
      is_darkmode: false,
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",
      lang: "ru",
      image: null,
    };
  },

  mounted() {
    if (this.item) {
      this.title_ru = this.item.title_ru;
      this.title_uz = this.item.title_uz;
      this.content_ru = this.item.content_ru;
      this.content_uz = this.item.content_uz;
      this.is_active = this.item.is_active;
      this.is_darkmode = this.item.is_darkmode;
      this.image = "https://api.proweb.uz" + this.item.image;
      const start_date = new Date(this.item.start_date * 1000);
      const end_date = new Date(this.item.end_date * 1000);
      this.start_date = `${start_date.getFullYear()}-${functions.setNumForDate(
        start_date.getMonth()
      )}-${functions.setNumForDate(start_date.getDate())}`;
      this.start_time = `${functions.setNumForDate(
        start_date.getHours()
      )}:${functions.setNumForDate(start_date.getMinutes())}`;
      this.end_date = `${end_date.getFullYear()}-${functions.setNumForDate(
        end_date.getMonth()
      )}-${functions.setNumForDate(end_date.getDate())}`;
      this.end_time = `${functions.setNumForDate(
        end_date.getHours()
      )}:${functions.setNumForDate(end_date.getMinutes())}`;
    }
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.image = URL.createObjectURL(file);
    },
  },
};
</script>

<style lang="scss">
input {
  width: 200px;
  border: 1px solid #5288c1;
  background: #232e3c;
  color: white;
  padding: 5px;
  border-radius: 10px;
  appearance: auto;
  &:disabled {
    background: rgb(65, 65, 65);
  }
  &.auto {
    width: auto;
  }
}
.create {
  &__banner {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0;
    .ads__list-language {
      flex-direction: row;
    }
    .ads__list-item {
      cursor: default;
      &:hover {
        opacity: 1;
        transform: scale(1);
      }
    }

    .ads__list-config {
      padding: 10px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &-block {
      padding: 10px;
      background: #232e3c;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    &-title {
      font-size: 20px;
      margin-bottom: 10px;
      color: #8cbeff;
    }
    &-input {
      display: flex;
      align-items: center;
      gap: 5px;
      &title {
        width: 30%;
      }
    }
  }
}
.scale-enter-active,
.scale-leave-active {
  transition: all 200ms ease-out;
  max-height: 200px;
  overflow: hidden;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>