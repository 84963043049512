import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
// import Home from "@/views/Home.vue";
import Launches from "@/views/Launches.vue";
import LeadError from "@/views/LeadError.vue";
import LaunchesArchive from "@/views/LaunchesArchive.vue";
import MasterClasses from "@/views/MasterClasses.vue";
import Login from "@/views/Login.vue";
import Teachers from "@/views/Teachers.vue";
import Ads from "@/views/Ads.vue";
import PusherApi from "@/api/pusher";

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/logout",
    redirect: () => {
      store.dispatch('logout')
      return '/login'
    }
  },
  {
    path: "/",
    name: "Launches",
    component: Launches,
  },
  {
    path: "/lead-error",
    name: "LeadError",
    component: LeadError,
  },
  {
    path: "/archive",
    name: "LaunchesArchive",
    component: LaunchesArchive,
  },
  {
    path: "/ads",
    name: "Ads",
    component: Ads,
  },
  {
    path: "/master-classes",
    name: "MasterClasses",
    component: MasterClasses,
  },
  {
    path: "/teachers",
    name: "Teachers",
    component: Teachers,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuth = await store.dispatch("isAuthorized");
  PusherApi.unsubscribe()
  if ((to.path == "/login" && !isAuth) || (to.path != "/login" && isAuth)) {
    next();
  } else if (!isAuth) {
    next("/login");
  } else {
    next("/");
  }
});

export default router;
