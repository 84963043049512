import Api from ".";

class Courses extends Api {
    async getCoursesList (){
        let response
        try {
            response = await this.client('courses/all/')
        } catch (error) {
            response = error.response
        }
        return response
    }
}
export default new Courses()