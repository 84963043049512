<template>
  <div class="mc__theme">
    <h3 class="mc__theme-title">
      {{ $t("master-class_page.master-classes_title") }}
    </h3>
    <div class="mc__theme-wrapper">
      <div class="mc__theme-wrapper-block">
        <h4 class="mc__theme-wrapper-block-title">RUS</h4>
        <div class="mc__theme-wrapper-block-card">
          <MCStartCard
            v-for="master in languageFilter(masterClasses, 'ru')"
            :key="master"
            :master="master"
            @updateCard="updateCard"
          ></MCStartCard>
        </div>
      </div>
      <div class="mc__theme-wrapper-block">
        <h4 class="mc__theme-wrapper-block-title">UZB</h4>
        <div class="mc__theme-wrapper-block-card">
          <MCStartCard
            v-for="master in languageFilter(masterClasses, 'uz')"
            :key="master"
            :master="master"
            @updateCard="updateCard"
          ></MCStartCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MCStartCard from "../MC/MCStartCard.vue";
export default {
  props: {
    masterClasses: Array,
  },
  methods: {
    languageFilter(array, language) {
      const result = array.filter((data) => data.language == language);
      return result;
    },
    updateCard(data) {
      this.$emit("updateCard", data);
    },
  },
  components: { MCStartCard },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.mc__theme {
  flex-grow: 1;
  width: 32%;
  background: #fff;
  @include adaptivMarginlg(10, 10, 20, 0, 5, 5, 10, 10);
  border-radius: 10px 10px 10px 10px;
  &.active {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  &-title {
    text-align: center;
    @include adaptiv-fontlg(25, 15);
    font-family: "Inter";
    font-weight: 600;
    color: #fff;
    @include adaptivHeightlg(70, 40);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0 0;
    text-transform: uppercase;
    background: green;
    @media (max-width: 768px) {
      @include adaptiv-fontmd(22, 12);
      @include adaptivHeightmd(60, 30);
    }
  }
  &-wrapper {
    display: flex;
    justify-content: center;
    &-block {
      flex: 1;
      &-title {
        text-align: center;
        margin-bottom: 5px;
        @include adaptiv-fontlg(20, 10);
        font-family: "Inter";
        font-weight: 400;
        color: #303030;
        @include adaptivMarginlg(0, 0, 5, 5, 0, 0, 3, 3);
        @media (max-width: 768px) {
          @include adaptiv-fontmd(20, 10);
        }
      }
      &-card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
    }
  }
}
</style>
