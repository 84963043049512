<template>
  <div class="modal" ref="modalEl" @click.stop>
    <!-- <button class="close-card" type="button" @click="closeModal">✕</button> -->
    <form @submit.prevent="formSubmit">
      <div class="start-course">
        <div class="start-course_block">
          <label>
            {{ $t("launch_modal.course") }}:
            <select v-model="form.course">
              <option v-for="course in coursesList" :key="course.id" :value="course.id">
                {{ course.name }}
              </option>
            </select>
          </label>
          <label v-ripple @click="teacherModal = true">
            {{ $t("launch_modal.teacher") }}
            <Teleport to="#dialog">
              <Transition name="slide-fade">
                <Dialog v-if="teacherModal" :width="600" @close="teacherModal = false">
                  <template v-slot:title>
                    {{ $t("launch_modal.choose_teacher") }}
                  </template>
                  <template v-slot:body>
                    <div class="teacher-list-select" v-for="(course, name) in courses_teacher" :key="course">
                      <div class="teacher-list-select__item">
                        <h2 class="teacher-list-select__title">{{ name }}</h2>
                        <ListTileTeacher v-for="(teacher, i) in course" :style="{ background: teacher.color + '45' }" :class="{ disbled: !teacher.active }" :key="i" :radius="50" :course="teacher.short_name" :teacher="teacher" v-ripple @click="
                          form.teacher_id = teacher.id;
                        changeTeacher();
                        " />
                      </div>
                    </div>
                  </template>
                </Dialog>
              </Transition>
            </Teleport>
            <span class="teacher-modal-view">
              <span :style="{ background: color, color: inherit }" v-if="form.teacher_id == null">
                {{ $t("launch_modal.teacher_unchosen") }}
              </span>
              <span :style="{ background: color, color: inherit }" v-else>
                {{
                  teacherData.last_name +
                  " " +
                  teacherData.first_name +
                  " " +
                  courseNameById(teacherData.course) +
                  " " +
                  teacherData.language
                }}
              </span>
            </span>
            <!-- <select v-model="form.teacher_id" @change="changeTeacher">
              <option :value="null">Не выбрано</option>
              <template v-for="teacher in teachers_list" :key="teacher">
                <option :value="teacher.id" :disabled="!teacher.active">
                  {{
                    teacher.last_name +
                    " " +
                    teacher.first_name +
                    " " +
                    courseNameById(teacher.course) +
                    " " +
                    teacher.language
                  }}
                </option>
              </template>
            </select> -->
          </label>
          <label>
            Дата:
            <input type="date" v-model="form.date" />
          </label>
        </div>
        <div class="start-course_block">
          <label>
            {{ $t("launch_modal.language") }}:
            <select v-model="form.language">
              <option value="ru">{{ $t("launch_modal.russian") }}</option>
              <option value="uz">{{ $t("launch_modal.uzbek") }}</option>
            </select>
          </label>
          <label>
            {{ $t("launch_modal.teacher_name") }}:
            <input type="text" v-model="form.teacher" />
          </label>
          <label>
            {{ $t("launch_modal.discount") }}:
            <input type="number" v-model="form.discount" />
          </label>
        </div>
        <div class="start-course_block">
          <label>
            {{ $t("launch_modal.filial") }}:
            <select v-model="form.branch">
              <option v-for="br in branch_list" :key="br.id" :value="br.id">
                {{ br.name }}
              </option>
            </select>
          </label>
          <div class="fsrear-st">
            <label class="fsraer" @click="form.branch_show = !form.branch_show">{{ $t("launch_modal.show_filial") }}:</label>
            <label class="settings__window-view">
              <input class="settings__window-view_input" type="checkbox" v-model="form.branch_show" />
              <span class="settings__window-view_span"></span>
            </label>
          </div>

          <label>
            {{ $t("launch_modal.cabinet") }}:
            <input type="text" v-model="form.cabinet" />
          </label>
          <label>
            {{ $t("launch_modal.seat_count") }}:
            <input type="number" v-model="form.seats" />
          </label>
        </div>
      </div>
      <div class="start-course_block">
        <div class="inherit">
          <div class="day calendar">
            <h2>{{ $t("launch_modal.days") }}</h2>
            <DayWeak :day="daysWeak" :color="color" @change="setDayLess" />
          </div>
        </div>
        <div class="day">
          <!-- <label class="day-label">
            Дни недели:

            <select class="day-select auto" v-for="d in form.day" v-model="d.day" :key="d.day">
              <option class="day-option" v-for="(day, index) in weekDayLong" :key="index" :value="index">
                {{ day }}
              </option>
            </select>
          </label>
          <div class="btns">
            <button v-if="form.day.length < 7" class="link-icon green" type="button" @click="addWeekDay()">
              <img src="@/assets/images/add.svg" alt="" />
              Добавить
            </button>
            <button v-if="form.day.length > 1" class="link-icon red" type="button" @click="removeWeekDay()">
              <img src="@/assets/images/delet.svg" alt="" />
              Удалить
            </button>
          </div> -->
          <div class="fsrear-st">
            <label class="fsraer" @click="form.double_lesson = !form.double_lesson">{{ $t("launch_modal.two_lessons") }}:</label>
            <label class="settings__window-view">
              <input class="settings__window-view_input" type="checkbox" @change="updateAllEndLessonTime" v-model="form.double_lesson" />
              <span class="settings__window-view_span"></span>
            </label>
          </div>
        </div>
        <div class="info-block">
          <div class="finished-date" :style="{ background: color, color: inherit }" @click="modalGenerate" v-ripple>
            <img src="@/assets/images/info.svg" :style="{
              filter: inherit == 'black' ? 'invert(1)' : 'invert(0)',
            }" alt="" />
            <div class="finished-date__content">
              {{ $t("launch_modal.preliminary_date") }}
            </div>
          </div>
          <div class="finished-date" :style="{ background: color, color: inherit }">
            {{ $t("launch_modal.lesson_duration") }}:
            <b :style="{ color: inherit }">{{ timeLess }} {{ $t("launch_modal.minute") }} |
              {{ declination(timeLess / 60, [" час", " часа", " часов"]) }}</b>
          </div>
          <div class="finished-date full" :style="{ background: color, color: inherit }" @click="showView = true" v-ripple>
            {{ $t("launch_modal.preliminary_show") }}
          </div>
        </div>
        <Teleport to="#dialog">
          <Transition name="slide-fade">
            <Dialog v-if="showView" :width="450" @close="showView = false">
              <template v-slot:title>
                {{ $t("launch_modal.preliminary_show_modal") }}
              </template>
              <template v-slot:body>
                <div class="test-view">
                  <StartCard :start="form" :courseShortName="courseShortName" :courseName="courseName" :lessonCount="lessonCount" lang="УЗБ" :testView="false" :color="color" />
                </div>
              </template>
            </Dialog>
          </Transition>
        </Teleport>
        <Teleport to="#dialog">
          <Transition name="slide-fade">
            <Dialog v-if="showModalDate" :width="lesson_data_generate.length > 0 ? 1100 : 540" @close="showModalDate = false">
              <template v-slot:title>
                {{
                  lesson_data_generate.length > 0
                  ? $t("launch_modal.preliminary_info")
                  : $t("launch_modal.calculating") + "..."
                }}</template>
              <template v-slot:body style="overflow: hidden">
                <Transition name="slide-up" mode="out-in">
                  <div v-if="lesson_data_generate.length > 0">
                    <h3>
                      {{ $t("launch_modal.expected_release_date") }}
                    </h3>
                    <div class="content-info-modify">
                      <div class="content-info-modify__course">
                        <h4>{{ $t("launch_modal.course_launch") }}</h4>
                        <p>
                          {{ $t("launch_modal.course_modal") }}:
                          {{ courseName }}
                        </p>
                        <p>
                          {{ $t("Course_card.modal__lesson_day") }}:
                          <span>{{ getDayLess(form.day) }}</span>
                        </p>
                        <p>
                          {{ $t("launch_modal.start_date") }}:
                          <span>{{
                            startDateFormat(
                              lesson_data_generate[0].toString(),
                              [{ day: getActiveDay(lesson_data_generate[0]) }],
                              "",
                              false,
                              true
                            ).toUpperCase()
                          }}</span>
                        </p>
                        <p>
                          {{ $t("launch_modal.preliminary_release_date") }}:
                          {{ lesson_data_string }}
                        </p>
                        <p>
                          {{ $t("launch_modal.teacher") }}:
                          {{ form.teacher.length > 0 ? form.teacher : "-" }}
                        </p>
                        <p>
                          {{ $t("launch_modal.two_lessons") }}:
                          {{
                            form.double_lesson
                            ? $t("Course_card.delete__modal_accept")
                            : $t("Course_card.delete__modal_decline")
                          }}
                        </p>
                        <p>
                          {{ $t("launch_modal.holidays_count") }}:
                          {{ active_holidays.length }}
                        </p>
                      </div>
                      <div class="content-info-modify__course">
                        <h4>{{ $t("launch_modal.about_course") }}</h4>
                        <p>
                          {{ $t("launch_modal.intership") }}:
                          {{
                            form.is_internship
                            ? $t("Course_card.delete__modal_accept")
                            : $t("Course_card.delete__modal_decline")
                          }}
                        </p>
                        <p>
                          {{ $t("Course_card.modal__course_count") }}:
                          <!-- {{
                            declination(countLess, [
                              " урок",
                              " урока",
                              " уроков",
                            ])
                          }} -->
                          {{
                            $tc("Course_card.modal__lesson", {
                              count: countLess,
                            })
                          }}
                        </p>
                        <p>
                          {{ $t("Course_card.modal__duration") }}:
                          <!-- {{
                            declination(countLess / block, [
                              " блок",
                              " блока",
                              " блоков",
                            ])
                          }} -->{{ countLess / block }}
                          {{ $tc("Course_card.modal__block", calculateBlock) }}
                        </p>
                        <p>
                          {{ $t("launch_modal.lessons_count") }}:
                          <!-- {{
                            declination(form.is_internship ? 12 : 8, [
                              " урок",
                              " урока",
                              " уроков",
                            ])
                          }} -->
                          {{
                            $tc("Course_card.modal__lesson", {
                              count: form.is_internship ? 12 : 8,
                            })
                          }}
                        </p>
                        <p>
                          {{ $t("launch_modal.lesson_duration") }}:
                          {{ timeLess }} {{ $t("launch_modal.minute") }} |

                          {{ timeLess / 60 }}
                          {{ $tc("launch_modal.hour", calculateHour) }}
                        </p>
                      </div>
                    </div>
                    <div class="content-info">
                      <p>
                        {{ $t("Course_card.modal__approximate_lesson_date") }}:
                      </p>
                    </div>
                    <div class="date__list" ref="scroll_container" @mousewheel="scrollX">
                      <div class="date__list-item" v-for="(item_date, index) in lesson_data_generate" :key="index" :class="{
                        old: numberSetting(
                          form.double_lesson,
                          index,
                          form.is_internship
                        ).class,
                        lost: numberSetting(
                          form.double_lesson,
                          index,
                          form.is_internship,
                          item_date.toString()
                        ).lost,
                      }">
                        <span>{{
                          numberSetting(
                            form.double_lesson,
                            index,
                            form.is_internship
                          ).block
                        }}
                          {{ $tc("Course_card.modal__block", 0) }}</span>
                        <span>{{
                          numberSetting(
                            form.double_lesson,
                            index,
                            form.is_internship
                          ).less
                        }}
                          {{ $tc("Course_card.modal__lesson", 0) }}</span>
                        <span>{{
                          startDateFormat(
                            item_date.toString(),
                            [{ day: getActiveDay(item_date) }],
                            "",
                            false,
                            true
                          ).toUpperCase()
                        }}</span>
                        <span class="lost" v-if="numberSetting(
                              form.double_lesson,
                              index,
                              form.is_internship,
                              item_date.toString()
                            ).lost
                            ">{{ $t("launch_modal.finished_lesson") }}</span>
                      </div>
                    </div>
                    <div class="active__holidays" v-if="active_holidays.length">
                      <h2>
                        {{ $t("launch_modal.canceled_lessons") }}:
                        {{ active_holidays.length }}
                      </h2>
                      <div class="active__holidays-content">
                        <div class="active__holidays-item" v-for="item_holiday in active_holidays" :key="item_holiday">
                          <span>{{
                            startDateFormat(
                              item_holiday.day,
                              [{ day: getActiveDay(item_holiday.day) }],
                              "",
                              false,
                              true
                            ).toUpperCase()
                          }}</span><span>{{ item_holiday.title }}</span>
                        </div>
                      </div>
                      <div class="disclaimer">
                        <img src="@/assets/images/info.svg" alt="" />
                        *{{ $t("launch_modal.holiday_alert") }}
                      </div>
                    </div>
                  </div>
                  <div class="finished-date load no center" v-else>
                    <div class="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="finished-date__content">
                      {{ $t("launch_modal.calculating_date") + "..." }}
                      <p>{{ $t("launch_modal.calculating_duration") }}</p>
                    </div>
                  </div>
                </Transition>
              </template>
            </Dialog>
          </Transition>
        </Teleport>
        <div class="time" v-if="form.course != 8 && form.course != 20">
          <b>{{ $t("launch_modal.course_type") }}:</b>
          <div class="fsrear-st">
            <label class="fsraer" @click="form.is_internship = !form.is_internship">{{ $t("launch_modal.intership") }}:</label>
            <label class="settings__window-view">
              <input class="settings__window-view_input" type="checkbox" v-model="form.is_internship" />
              <span class="settings__window-view_span"></span>
            </label>
          </div>
        </div>
        <div class="time">
          <b>{{ $t("launch_modal.time") }}:</b>
          <div class="time-block" v-for="(time, index) in form.schedule" :key="time">
            <div class="time-block-info" :style="{ background: color, color: inherit }">
              <h2 class="time-block-info" :style="{ background: color, color: inherit }">
                {{ timeLess }} {{ $t("launch_modal.minute") }} |
                {{ declination(timeLess / 60, [" час", " часа", " часов"]) }}
              </h2>
              <div class="time-block-info__time">
                <label class="time-label" :style="{ color: inherit }">
                  {{ $t("launch_modal.start") }}:
                  <input class="time-input auto" @change="updateEndlessonTime(index)" type="time" v-model="time.start_lesson" />
                </label>
                <label class="time-label" :style="{ color: inherit }">
                  {{ $t("launch_modal.over") }}:
                  <input type="time" class="auto" v-model="time.end_lesson" />
                </label>
              </div>
            </div>
            <label class="time-label">
              {{ $t("launch_modal.discount") }}:
              <input class="time-input auto" type="number" v-model="time.discount" />
            </label>
            <div class="fsrear-st">
              <label class="fsraer" @click="time.offline = !time.offline">{{ $t("launch_modal.offline") }}:</label>
              <label class="settings__window-view">
                <input class="settings__window-view_input" type="checkbox" v-model="time.offline" />
                <span class="settings__window-view_span"></span>
              </label>
            </div>
            <div class="fsrear-st">
              <label class="fsraer" @click="time.online = !time.online">{{ $t("launch_modal.online") }}:</label>
              <label class="settings__window-view">
                <input class="settings__window-view_input" type="checkbox" v-model="time.online" />
                <span class="settings__window-view_span"></span>
              </label>
            </div>
            <div class="btns">
              <button class="link-icon green" type="button" @click="addStartTime(index)">
                <img src="@/assets/images/add.svg" alt="" />
                {{ $t("launch_modal.add") }}
              </button>
              <button class="link-icon red" type="button" @click="removeStartTime(index)">
                <img src="@/assets/images/delet.svg" alt="" />
                {{ $t("launch_modal.delete") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="start-course_block">
        <p class="start-course_block-title">
          <b>{{ $t("launch_modal.open_lessons") }}</b>
        </p>
        <div class="open-lesson" v-for="(lesson, index) in form.open_lesson" :key="lesson">
          <label class="lesson-label">
            {{ $t("launch_modal.cabinet") }}:
            <input type="text" class="auto" v-model="lesson.cabinet" />
          </label>
          <label class="lesson-label">
            {{ $t("launch_modal.date") }}:
            <input type="date" class="auto" v-model="lesson.date" :disabled="requestVisidet(lesson.open_lesson_time)" />
          </label>
          <div>
            <b>{{ $t("launch_modal.time") }}:</b>
            <div class="lesson" v-for="(time, indexTime) in lesson.open_lesson_time" :key="time">
              <label>
                {{ $t("launch_modal.start") }}:
                <input class="lesson-input auto" type="time" v-model="time.time" :disabled="time.visited != null && time.visited >= 0" />
              </label>

              <div class="fsrear-st">
                <label class="fsraer" @click="
                  time.offline =
                  time.visited != null && time.visited >= 0
                    ? time.offline
                    : !time.offline
                  ">{{ $t("launch_modal.offline") }}
                </label>
                <label class="settings__window-view">
                  <input class="settings__window-view_input" type="checkbox" v-model="time.offline" :disabled="time.visited != null && time.visited >= 0" />
                  <span class="settings__window-view_span"></span>
                </label>
              </div>

              <div class="fsrear-st">
                <label class="fsraer" @click="
                  time.online =
                  time.visited != null && time.visited >= 0
                    ? time.online
                    : !time.online
                  ">{{ $t("launch_modal.online") }}</label>
                <label class="settings__window-view">
                  <input class="settings__window-view_input" type="checkbox" v-model="time.online" :disabled="time.visited != null && time.visited >= 0" />
                  <span class="settings__window-view_span"></span>
                </label>
              </div>
              <div class="fsrear-st">
                <label class="fsraer" @click="
                  time.is_master_class =
                  time.visited != null && time.visited >= 0
                    ? time.is_master_class
                    : !time.is_master_class
                  ">{{ $t("launch_modal.master-class-short") }}:</label>
                <label class="settings__window-view">
                  <input class="settings__window-view_input" type="checkbox" v-model="time.is_master_class" :disabled="time.visited != null && time.visited >= 0" />
                  <span class="settings__window-view_span"></span>
                </label>
              </div>
              <span class="lesson-visited" title="Кол-во посетивших открытый урок">{{ time.visited }}</span>
              <div class="btns">
                <button v-if="lesson.open_lesson_time.length < 11" class="link-icon green" type="button" @click="addOpenlessonTime(index, indexTime)">
                  <img src="@/assets/images/add.svg" alt="" />
                  {{ $t("launch_modal.add") }}
                </button>
                <button v-if="(time.visited === null || time.visited == undefined) &&
                  time.visited !== 0
                  " class="link-icon red" type="button" @click="removeOpenlessonTime(index, indexTime)">
                  <img src="@/assets/images/delet.svg" alt="" />
                  {{ $t("launch_modal.delete") }}
                </button>
              </div>
            </div>
          </div>
          <button v-if="!requestVisidet(lesson.open_lesson_time)" class="link-icon red" type="button" @click="removeOpenLesson(index)">
            <img src="@/assets/images/delet.svg" alt="" />
            {{ $t("launch_modal.delete_open_lesson") }}
          </button>
        </div>
        <button class="link-icon blue" style="margin-bottom: 10px" type="button" @click="addOpenLesson()">
          <img src="@/assets/images/oy.svg" alt="" />
          {{ $t("launch_modal.add_open_lesson") }}
        </button>
      </div>
      <div class="footer-el">
        <button class="create link-icon green" type="submit" v-if="!updateModal">
          <img src="@/assets/images/add.svg" alt="" />
          {{ $t("launch_modal.add") }}
        </button>
        <button class="create link-icon blue" type="submit" v-if="updateModal">
          <img src="@/assets/images/save.svg" alt="" />
          {{ $t("launch_modal.save") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import StartCard from "@/components/launches/StartCard.vue";
import DayWeak from "@/components/DayWeak.vue";
import ListTileTeacher from "@/components/ListTileTeacher.vue";
export default {
  props: {
    updateModal: Boolean,
    updateCardData: Object,
  },
  components: {
    StartCard,
    DayWeak,
    ListTileTeacher,
  },
  watch: {
    form: {
      handler() {
        const course = this.coursesList.find((a) => a.id == this.form.course);
        this.lessonCount = course.lesson_count;
        this.courseName = course.name;
        this.courseShortName = course.short_name;
        this.courseColor = course.color;
        this.color = course.color;
        this.color55 = course.color + "8c";
        this.inherit = this.getContrastYIQ(course.color);
        // this.changeGBColorModal()
        let time = 90;
        if (this.form.is_internship && this.form.course != 8) {
          this.timeLess = time * 2;
        } else if (this.form.course == 8 || this.form.course == 20) {
          this.timeLess = time / 1.5;
          this.form.is_internship = false;
        } else {
          this.timeLess = time;
        }
        if (this.form.double_lesson) {
          this.timeLess *= 2;
        }
        this.updateAllEndLessonTime();
        this.form.day = this.form.day.sort((a, b) => a.day - b.day);
        this.daysWeak = this.form.day;
        this.teacherData = this.teachers_list.find(
          (a) => a.id == this.form.teacher_id
        );
      },
      deep: true,
    },
  },
  data() {
    return {
      showModalDate: false,
      lesson_data_generate: [],
      lesson_data_string: "",
      day_less: [],
      active_holidays: [],
      countLess: 0,
      block: 0,
      timeLess: 90,
      showView: false,
      actvieClass: [],
      daysWeak: [],
      teacherModal: false,
      teacherData: {},
      color: "#53acff",
      color55: "#53acff",
      inherit: "white",
      form: {
        course: 1,
        language: "ru",
        branch: 1,
        branch_show: false,
        date: new Date().toISOString().split("T")[0],
        discount: 0,
        double_lesson: false,
        teacher: "",
        teacher_id: null,
        seats: 0,
        is_internship: false,
        day: [
          {
            day: 0,
          },
        ],
        schedule: [
          {
            start_lesson: "10:00",
            end_lesson: "11:30",
            offline: true,
            online: true,
            discount: 0,
          },
        ],
        open_lesson: [],
      },
      month: [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ],
      weekDayShort: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вск"],
      weekDayLong: [
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота",
        "Воскресенье",
      ],
    };
  },
  mounted() {
    this.weekDayLong = [
      this.$t("master-class_page.monday"),
      this.$t("master-class_page.tuesday"),
      this.$t("master-class_page.wednesday"),
      this.$t("master-class_page.thursday"),
      this.$t("master-class_page.friday"),
      this.$t("master-class_page.saturday"),
      this.$t("master-class_page.sunday"),
    ];
    this.weekDayShort = [
      this.$t("Course_card.monday_short"),
      this.$t("Course_card.tuesday_short"),
      this.$t("Course_card.wednesday_short"),
      this.$t("Course_card.thursday_short"),
      this.$t("Course_card.friday_short"),
      this.$t("Course_card.saturday_short"),
      this.$t("Course_card.sunday_short"),
    ];
    this.month = [
      this.$tc("Course_card.january", 2),
      this.$tc("Course_card.february", 2),
      this.$tc("Course_card.march", 2),
      this.$tc("Course_card.april", 2),
      this.$tc("Course_card.may", 2),
      this.$tc("Course_card.june", 2),
      this.$tc("Course_card.july", 2),
      this.$tc("Course_card.august", 2),
      this.$tc("Course_card.september", 2),
      this.$tc("Course_card.october", 2),
      this.$tc("Course_card.november", 2),
      this.$tc("Course_card.december", 2),
    ];
    let day = new Date().getDay();
    if (day == 0) {
      day = 6;
    } else {
      day--;
    }
    this.form.day[0].day = day;
    this.daysWeak = this.form.day;
    if (this.updateModal) {
      this.form = JSON.parse(JSON.stringify(this.updateCardData));
    }
    const course = this.coursesList.find((a) => a.id == this.form.course);
    this.lessonCount = course.lesson_count;
    this.courseName = course.name;
    this.color = course.color;
    this.color55 = course.color + "8c";
    this.inherit = this.getContrastYIQ(course.color);
    // this.changeGBColorModal()
  },
  computed: {
    ...mapGetters({
      coursesList: "coursesList",
    }),
    ...mapState({
      branch_list: (state) => state.branches.branches_list,
      teachers_list: (state) => state.teachers.teachers_list,
      courses_teacher: function (state) {
        const teachers = JSON.parse(
          JSON.stringify(state.teachers.teachers_list)
        );
        const courses = JSON.parse(JSON.stringify(state.launches.launches));

        const courses_teachers = {};
        courses.forEach((element) => {
          teachers.forEach((teach) => {
            if (!courses_teachers[element.name]) {
              courses_teachers[element.name] = [];
            }
            if (teach.course == element.id) {
              if (teach.active) {
                const { color, name, short_name } = element;
                courses_teachers[element.name].push({
                  ...teach,
                  color,
                  name,
                  short_name,
                });
              }
            }
          });
        });
        for (const key in courses_teachers) {
          if (courses_teachers[key].length == 0) {
            delete courses_teachers[key];
          }
        }
        this.activeCourse = Object.keys(courses_teachers)[0];
        return courses_teachers;
      },
      // courses_teacher: function (state) {
      //   const teachersJSON = JSON.parse(
      //     JSON.stringify(state.teachers.teachers_list)
      //   );
      //   const courses = JSON.parse(JSON.stringify(state.launches.launches));
      //   const teachers = teachersJSON.sort((a, b) => a.course - b.course)
      //   const courses_teachers = [];
      //   teachers.forEach(teach => {
      //     const course_id = teach.course
      //     const { color, name, short_name } = courses.find(a => a.id == course_id)
      //     if(teach.active) {
      //       courses_teachers.push({
      //       ...teach,
      //       color,
      //       name,
      //       short_name
      //     })
      //     }
      //   })
      //   return courses_teachers;
      // },
    }),
    calculateHour() {
      if (this.timeLess / 60 <= 1) return 0;
      else if (this.timeLess / 60 < 5) return 1;
      else return 2;
    },
    calculateBlock() {
      if (this.countLess / this.block <= 1) return 0;
      else if (this.countLess / this.block < 5) return 1;
      else return 2;
    },
  },
  methods: {
    ...mapActions({
      addLaunch: "addLaunch",
      updateLaunch: "updateLaunch",
    }),
    changeGBColorModal() {
      const parent = this.$refs.modalEl.closest(".settings__window-content-bg");
      parent.style.transition = "500ms";
      parent.style.background = this.color + "21";
      const title = parent.querySelector(".settings__window-title");
      const text = this.getContrastYIQ(this.color);
      title.style.transition = "500ms";
      title.style.background = this.color;
      title.style.color = text;

      const img = [...title.querySelectorAll("img")];
      img.forEach((a) => {
        a.style.transition = "500ms";
        a.style.filter = text == "black" ? "invert(1)" : "invert(0)";
      });
    },
    getContrastYIQ(hexcolor) {
      const r = parseInt(hexcolor.substring(1, 3), 16);
      const g = parseInt(hexcolor.substring(3, 5), 16);
      const b = parseInt(hexcolor.substring(5, 7), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "black" : "white";
    },
    setDayLess(day) {
      const search = this.form.day.find((a) => a.day === day);
      if (search != undefined) {
        if (this.form.day.length > 1) {
          this.form.day = this.form.day.filter((a) => a.day != day);
        }
      } else {
        this.form.day.push({ day });
      }
    },
    modalGenerate() {
      this.showModalDate = true;
      this.showLessonsGenerate(this.form);
    },
    requestVisidet(open_less) {
      let bool = false;

      open_less.forEach((e) => {
        if (e.visited > 0) {
          bool = true;
        }
      });

      return bool;
    },
    formSubmit() {
      if (this.updateModal) {
        this.updateLaunch(this.form).then((response) => {
          if (response.status == 200) {
            this.closeModal();
            this.$store.dispatch("addSnack", {
              title: this.$t("snackbar.add_launch"),
              content: this.$t("snackbar.successfully_added"),
              time: 10000,
            });
          }
        });
      } else {
        this.addLaunch(this.form).then((response) => {
          if (response.status == 200) {
            this.closeModal();
            this.$store.dispatch("addSnack", {
              title: this.$t("snackbar.edit_launch"),
              content: this.$t("snackbar.successfully_updated"),
              time: 10000,
            });
          }
        });
      }
    },
    addWeekDay() {
      if (this.form.day.length > 6) {
        return;
      }
      this.form.day.push({ ...this.form.day[this.form.day.length - 1] });
    },
    removeWeekDay() {
      if (this.form.day.length < 2) {
        return;
      }
      this.form.day.splice(this.form.day.length - 1, 1);
    },
    addStartTime(index) {
      if (this.form.schedule.length > 10) {
        return;
      }
      this.form.schedule = [
        ...this.form.schedule.slice(0, index).map((obj) => {
          return { ...obj };
        }),
        { ...this.form.schedule[index] },
        ...this.form.schedule
          .slice(index, this.form.schedule.length)
          .map((obj) => {
            return { ...obj };
          }),
      ];
    },
    removeStartTime(index) {
      if (this.form.schedule.length < 2) {
        return;
      }
      this.form.schedule.splice(index, 1);
    },
    addOpenLesson() {
      if (this.form.open_lesson.length > 4) {
        return;
      }
      if (this.form.open_lesson.length == 0) {
        this.form.open_lesson.push({
          date: new Date(+new Date(this.form.date) - 7 * 24 * 60 * 60 * 1000)
            .toISOString()
            .split("T")[0],
          open_lesson_time: [
            ...this.form.schedule.map((time) => {
              return {
                time: time.start_lesson,
                offline: time.offline,
                online: time.online,
                is_master_class: time.is_master_class,
              };
            }),
          ],
          cabinet: this.form.cabinet,
        });
      } else {
        const lesson = this.form.open_lesson[this.form.open_lesson.length - 1];
        this.form.open_lesson.push({
          date: new Date(+new Date(lesson.date) - 7 * 24 * 60 * 60 * 1000)
            .toISOString()
            .split("T")[0],
          open_lesson_time: [
            ...lesson.open_lesson_time.map((time) => {
              return {
                time: time.time,
                offline: time.offline,
                online: time.online,
                is_master_class: time.is_master_class,
              };
            }),
          ],
          cabinet: lesson.cabinet,
        });
      }
    },
    removeOpenLesson(index) {
      this.form.open_lesson.splice(index, 1);
    },
    addOpenlessonTime(lessonIndex, index) {
      if (this.form.open_lesson[lessonIndex].open_lesson_time.length > 10) {
        return;
      }
      const new_time =
        this.form.open_lesson[lessonIndex].open_lesson_time[index];
      this.form.open_lesson[lessonIndex].open_lesson_time = [
        ...this.form.open_lesson[lessonIndex].open_lesson_time
          .slice(0, index + 1)
          .map((obj) => {
            return obj;
          }),
        {
          time: new_time.time,
          offline: new_time.offline,
          online: new_time.online,
          is_master_class: false,
          visited: null,
          visited_online: null,
        },
        ...this.form.open_lesson[lessonIndex].open_lesson_time
          .slice(
            index + 1,
            this.form.open_lesson[lessonIndex].open_lesson_time.length
          )
          .map((obj) => {
            return obj;
          }),
      ];
    },
    removeOpenlessonTime(lessonIndex, index) {
      if (this.form.open_lesson[lessonIndex].open_lesson_time.length < 2) {
        return;
      }
      this.form.open_lesson[lessonIndex].open_lesson_time.splice(index, 1);
    },
    endLessonTimeCount(currentTime, duration) {
      let time =
        +currentTime.split(":")[0] * 60 +
        +currentTime.split(":")[1] +
        +duration;
      let hour = Math.floor(time / 60);
      hour = hour > 23 ? hour - 24 : hour;

      let minute = time % 60;

      return `${hour < 10 ? "0" + hour : hour}:${minute < 10 ? "0" + minute : minute
        }`;
    },
    updateEndlessonTime(index) {
      const duration = this.timeLess;
      this.form.schedule[index].end_lesson = this.endLessonTimeCount(
        this.form.schedule[index].start_lesson,
        duration
      );
    },
    updateAllEndLessonTime() {
      for (let index = 0; index < this.form.schedule.length; index++) {
        this.updateEndlessonTime(index);
      }
    },
    changeTeacher() {
      this.teacherModal = false;
      if (this.form.teacher_id) {
        for (let i = 0; i < this.teachers_list.length; i++) {
          const teacher = this.teachers_list[i];
          if (this.form.teacher_id == teacher.id) {
            this.form.teacher = teacher.first_name;
            break;
          }
        }
      } else {
        this.form.teacher = "";
      }
    },
    courseNameById(id) {
      let courseName = "";
      for (let i = 0; i < this.coursesList.length; i++) {
        const course = this.coursesList[i];
        if (id == course.id) {
          courseName = course.short_name;
          break;
        }
      }
      return courseName;
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/grid/grid";

.footer-el {
  position: sticky;
  bottom: 0px;
  padding: 10px;
  background: #17212b;
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  margin: 0 -15px;
}

b {
  color: white;
}

label {
  color: white;
}

.modal {
  color: white;

  .close-card {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    transition: 1s;
    @include adaptiv-fontlg(28, 15);
    font-family: "Inter";
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    border: none;
    background: transparent;
    color: white;
  }

  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.377);
  }

  form {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include adaptivPaddinglg(15, 15, 15, 15, 10, 10, 10, 10);
  }
}

.start-course {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;

  &_block {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @include adaptiv-fontlg(12, 7);
    @include adaptiv-lineHeightlg(32, 20);
    font-family: "Inter";
    font-weight: 400;
    color: #303030;
    text-transform: uppercase;

    .day,
    .lesson {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .internship {
      label {
        justify-content: start;
      }
    }

    .time {
      margin: 20px 5px 0;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &-block {
        display: flex;
        align-items: center;
      }
    }

    &-title {
      margin-top: 20px;
    }
  }
}

select,
input {
  width: 200px;
  margin: 0 10px;
  border: 1px solid #5288c1;
  background: #232e3c;
  color: white;
  padding: 5px;
  border-radius: 10px;

  &:disabled {
    background: rgb(65, 65, 65);
  }

  &.auto {
    width: auto;
  }
}

.day-select,
.day-input,
.day-label,
.time-select,
.time-input,
.time-label {
  margin: 0 3px;
}

.lesson-label {
  display: flex;
  justify-content: flex-start;
}

.day-btn,
.time-btn,
.lesson-btn {
  width: 100px;
  height: 25px;
  margin: 0 2px;
  transition: 1s;

  &:hover {
    background: #303030;
    color: #fff;
    transition: background-color 1s;
    border-radius: 4px;
  }
}

.save,
.add {
  width: 200px;
  height: 30px;
  margin: 5px 0;
  transition: 1s;

  &:hover {
    background: #303030;
    color: #fff;
    transition: background-color 1s;
    border-radius: 4px;
  }
}

.day-checkbox,
.time-checkbox,
.checkbox,
.lesson-checkbox {
  margin: 0 2px;
  width: auto;
}

label {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 5px;
}

.open-lesson {
  margin-bottom: 10px;
  padding: 10px;
  background: #232e3c;
  margin: 10px -15px 10px;
}

.lesson-visited {
  background: green;
  color: white;
  padding: 0px 10px;
  border-radius: 5px;
  margin-left: 10px;
  order: 1;
}

.fsraer {
  justify-content: start;
  display: inline;
}

.fsrear-st {
  display: flex;
  align-items: center;
}

.fsrear-st-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btns {
  display: flex;
  gap: 10px;
  margin: 10px 10px;
}

.content-info-modify {
  display: flex;
  gap: 20px;
  padding: 10px;
  margin: 10px;
  align-items: flex-start;

  &__course {
    background: #5288c1;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;

    h4 {
      font-size: 18px;
    }
  }
}

.time-block-info {
  background: #5288c1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  color: white;
  margin-right: 10px;

  h2 {
    font-size: 16px;
    font-weight: 400;
  }

  &__time {
    display: flex;
  }
}

.test-view {
  .footer-el {
    margin: 0;
  }

  .start-cart {
    border-radius: 10px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    color: black;
  }
}

.teacher-list-select {
  &__title {
    position: sticky;
    top: 60px;
    background: #17212b;
    padding: 16px;
    font-size: 20px;
    text-align: center;
    z-index: 1;
  }

  .teacher__header {
    padding: 10px;
    gap: 15px;

    &.disbled {
      background: grey;
      cursor: default !important;
    }
  }
}

.teacher-modal-view {
  span {
    display: inline-block;
    padding: 5px;
    margin-left: 5px;
    border-radius: 10px;
  }
}
</style>
<style lang="scss" scoped>
h3 {
  text-align: center;
  margin-top: 10px;
}

.finished-date {
  margin-bottom: 0;
  transition: 500ms;

  &.full {
    grid-column: span 2;
    justify-content: center;
  }
}

.day {
  &.calendar {
    color: white;
    display: flex;
    flex-direction: column;
    background: v-bind(color55);
    max-width: max-content;
    padding: 10px;
    transition: 500ms;
  }
}

.inherit {
  background: v-bind(inherit);
  width: max-content;
  border-radius: 16px;
  overflow: hidden;
  margin: 10px auto;
  transition: 500ms;
}

// .settings__window-view_input:checked ~ .settings__window-view_span {
//     background: v-bind(color);
//     border-color: v-bind(color);

//   }
// input, select {
//   border: 1px solid v-bind(color);
//   background: v-bind(color55);
//   color: v-bind(inherit);
// }
</style>
